<template>  
  <v-container>
    <v-card elevation="0" class="d-flex mb-2 justify-center" style="border-radius: 24px 24px 8px 8px;;">
      <div :class="`pa-4 px-6 d-flex justify-${queryClienteYPlanClienteIdExist ? 'space-between' : 'center'} align-center`" style="flex: 1; height: 64px;">
        <v-card-subtitle 
          class="pa-0 grey--text text--darken-3 font-weight-bold text-uppercase" 
          :style="($vuetify.breakpoint.xs ? 'font-size: 18px;' : 'font-size: 20px;')"
        >
          Ver detalles de planes de pago
        </v-card-subtitle>

        <v-card-subtitle 
          class="pa-0" 
          v-if="queryClienteYPlanClienteIdExist"
          :style="$vuetify.breakpoint.xs ? 'font-size: 16px;' : 'font-size: 18px;'"
        >
          <router-link id="linkReturnPlanCliente" color="primary" class=" font-weight-bold"
            :to="{ 
              path: `/clientes/${qClienteId}/planes`, 
              query: {viewPlanCliente: true, q: btoaEncodeData(toPlanCliente)} 
            }"
          >
            <v-icon color="primary" :size="20">mdi-arrow-left-bold</v-icon>
            Regresar al <br /> plan de cliente
          </router-link>
        </v-card-subtitle>
      </div>
    </v-card>

<!-- FILTROS REPORTE PLANES PAGO -->
    <v-card class="mt-5 mb-5" elevation="1">
      <v-row class="v-list-item">
        <v-col cols="12" md="5" sm="5" xs="12"> 
          <v-autocomplete
            :label="`Seleccionar cliente`"
            :items="cliente_select"
            :value="cliente.id"
            @change="handleCliente"
            :hint="(cliente.id && IS_DEV_USER) ? `Id: ${cliente.id}` : `${cliente.domicilio_de_cobro ? 'Domicilio de cobro: '+cliente.domicilio_de_cobro : ''}`"
            persistent-hint
          ></v-autocomplete>
        </v-col> 

        <v-col cols="12" md="7" sm="7" xs="12"> 
          <v-autocomplete
            ref="plan_cliente"
            :label="`Seleccionar plan de cliente`"
            :items="plan_cliente_select"
            :value="planCliente.id"
            @change="handlePlanesCliente"
            :hint="(planCliente.id && IS_DEV_USER) ? `Id: ${planCliente.id}` : `${planCliente.trimestres_pagados >= 0 ? 'Trimestres pagados: '+(planCliente.plan_completado ? planCliente.trimestres_pagados+' (plan completado)' : planCliente.trimestres_pagados) : ''}`"
            persistent-hint
          ></v-autocomplete>
        </v-col> 
      </v-row>
    </v-card>
<!-- FILTROS REPORTE PLANES PAGO -->

    <!-- :subtitle="!isEmptyObj(cliente) ? `${cliente.nombre} ${cliente.apellidos}` : ''" -->
    <DataTable
      id="planpagos-table"
      :headers="headers"
      :title="`Plan de pago`"
      :subtitle="`${
        !isEmptyObj(planCliente) 
          ? `${formatCurrency(planCliente.aseguradora_total)} (${planCliente.moneda})` 
          : ''
      }`"
      :items="planPagoTable.items"
      :loading="tableLoading"
      :searchEnabled="false"
      :showToolbar="true"
      :showExportExcel="true"
      :showSelect="true"
      :rightText="printPlanStatus"
      :forceItemsPerPage="20"
      :unselectAllCheckboxes="clearSelectedCheckboxes"
      @onRowClick="planPagoTable.editItem"
    >
      <template v-slot:showExportExcel="{ rowValues }">
        <a id="exportarExcel">
          <download-excel
            :header="`Plan de pagos ${cliente.nombre} ${cliente.apellidos}poliza ${planCliente.poliza}`"
            :name="`Plan de pagos ${cliente.nombre} ${cliente.apellidos}poliza ${planCliente.poliza}.xls`"
            :export-fields="plan_pago_export_fields"
            :data="rowValues"
            type="xls"
            :default-value="''"
          >
            Exportar a Excel
          </download-excel>
        </a>
      </template>

      <template slot="toolbarActions">

        <Dialog
          useToolbarStyle
          v-model="planPagoTable.dialog"
          :title="`Abonos registrados (${
            !planPagoTable.editedItem.trimestre_iniciado
              ? planCliente.forma_pago ? `${planCliente.poliza}, ${planCliente.moneda}, ${planCliente.forma_pago}${planCliente.domiciliado ? ', (Domiciliado)' : ''}` : '---'
              : `${planCliente.poliza}, ${planCliente.moneda}, ${planCliente.forma_pago}${planCliente.domiciliado ? ', (Domiciliado)' : ''}`
          })`"
          :subtitle="!isEmptyObj(cliente) ? `${cliente.num_cliente} - ${cliente.nombre} ${cliente.apellidos}` : ''"
          :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
          :actionsEnabled="false"
          max-width="1024px"
          @onCloseToolbarBtn="planPagoTable.dialog = false"
        >
          <template slot="content">
            <v-alert
              border="left"
              cols="12" sm="12" md="12" 
              class="mt-2"
              color="grey lighten-5"
            >
              <div class="d-flex align-center">
                <v-icon class="pr-2" color="primary">mdi-information</v-icon>
                <div>
                  NOTA: Sólo se podrán ver las acciones del abono más reciente que no haya sido cancelado.
                </div>
              </div>
            </v-alert>

            <DataTable
              id="abonos-table"
              class="mb-4"
              :title="`
                ${plazoToString(planCliente.forma_pago).capitalized} ${planPagoTable.editedItem.amortizacion}
                (${!planPagoTable.editedItem.trimestre_iniciado 
                  ? 'no iniciado' 
                  : planPagoTable.editedItem.trimestre_cerrado
                  ? 'cerrado'
                  : 'activo'})
              `"
              :subtitle="`Pago periódico: ${formatCurrency(planPagoTable.editedItem.pago_periodico_calculado)}`"
              :headers="headersPago"
              :items="planPagoTable.editedItem.abonos"
              :forceItemsPerPage="20"
              :loading="tableLoading"
              :searchEnabled="true"
              :sortBy="['id']"
              :sortDesc="[false, true]"
              :showToolbar="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
              :showSelect="true"
              :showEditarAction="false"
              :showDeleteAction="false"
              :showCancelMultipleAbonos="true"
              :parentDialogState="planPagoTable.dialog"
              @editItem="handleAbonoRow"
              @onSelectedRows="handleMultipleAbonoCancels"
            />
          </template>
        </Dialog> 

      </template>
    </DataTable>

    <Dialog
      useToolbarStyle
      overlay-opacity="0.7"
      v-model="abonoDialog"
      :title="`Acciones abono`"
      :subtitle="!isEmptyObj(cliente) ? `${cliente.nombre} ${cliente.apellidos}` : ''"
      :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      :showCloseIcon="true"
      :no-click-animation="false"
      
      @onCloseToolbarBtn="abonoDialog = false"
    >
      <template slot="content">
        <v-card-text class="mt-n1">
          <div>
            <div class="pt-1">
              Abono realizado el {{ `${abonoSelecc.fecha_abono ? $dayjs(abonoSelecc.fecha_abono).format('dddd, DD [d]e MMMM [d]el YYYY') : ''}` }}
            </div>

            <div class="pt-1 pb-5">
              {{ 
                abonado_con_saldo_a_favor
                  ? `Este abono se pagó con saldo a favor ($${+planPagoTable.editedItem.ultimo_abono_activo.saldo_a_favor_usado})`
                  : ``
              }}
            </div>

            <div 
              v-show="!editar_abono_check && !cancelar_abono_check && !pasar_al_dia_sig_check"
              class="subtitle-1"
            >
              Seleccione una acción:
            </div>
          </div>
          <v-divider />
          <!-- <v-alert
            border="left"
          >
            <div class="d-flex">
              <v-icon color="info" class="pr-2">mdi-information</v-icon>
              <div>
                Si se edita la cantidad, ésta se calculará contra el pago periódico: si se pone una cantidad menor, 
                la diferencia se acumulará para el siguiente abono. Si la cantidad es mayor, se acumulará para el saldo a favor
              </div>
            </div>
          </v-alert> -->
          
          <div>
<!-- EDITAR ABONO CHECKBOX -->
            <v-row>
              <v-col 
                v-show="!pasar_al_dia_sig_check && !cancelar_abono_check" 
                cols="12" sm="12" md="12" 
                :class="editar_abono_check ? 'mb-n2' : 'mb-n8'"
              > 
                <v-checkbox
                  v-model="editar_abono_check"
                  color="primary"
                  :label="`Editar cantidad`"
                  :hint="''"
                  :class="{ 'font-weight-bold': editar_abono_check }"
                  :style="{ maxWidth: 'max-content' }"
                  persistent-hint
                ></v-checkbox>

                <v-alert
                  v-show="!pasar_al_dia_sig_check && !cancelar_abono_check && editar_abono_check && !abonado_con_saldo_a_favor"
                  border="left"
                  cols="12" sm="12" md="12" 
                  class="ml-1 mt-n2"
                  color="grey lighten-5"
                >
                  <div class="d-flex">
                    <!-- <v-icon color="info" class="pr-2">mdi-information</v-icon> -->
                    <div>
                      {{
                        editar_abono_check 
                          ? `Si se edita la cantidad, ésta se calculará contra el pago periódico y el saldo 
                            en contra que se tenga del abono anterior: si se pone una cantidad menor, la 
                            diferencia se acumulará para el siguiente abono, en caso contrario 
                            se acumulará para el saldo a favor` 
                          : ``
                      }}
                    </div>
                  </div>
                </v-alert>

                <v-alert
                  v-show="!pasar_al_dia_sig_check && !cancelar_abono_check && editar_abono_check && abonado_con_saldo_a_favor"
                  border="left"
                  cols="12" sm="12" md="12" 
                  class="ml-1 mt-n2"
                  color="grey lighten-5"
                >
                  <div class="d-flex">
                    <!-- <v-icon color="info" class="pr-2">mdi-information</v-icon> -->
                    <div>
                      {{
                        editar_abono_check 
                          ? `Este abono se pagó con saldo a favor, en este caso la cantidad a editar se establece automáticamente 
                            en 0 y no se podrá modificar, con lo cual al aplicarse, el saldo a favor se reintegrará y se 
                            actualizara el saldo en contra correspondiente` 
                          : ``
                      }}
                    </div>
                  </div>
                </v-alert>

              </v-col>
            </v-row>

<!-- EDITAR ABONO (INPUTS) -->
            <v-row 
              v-show="!pasar_al_dia_sig_check && !cancelar_abono_check && editar_abono_check"
              :class="`${editar_abono_check ? 'mt-n5' : ''}`"
            >
              <v-col cols="12" sm="6" md="4"> 
                <VCurrencyField
                  label="Cantidad"
                  v-model="abonoSelecc.cantidad"
                  :hint="`
                    ${currency(planPagoTable.editedItem.pago_periodico_calculado)} (pago periódico)
                    + ${planPagoTable.editedItem.penultimo_abono_activo ? currency(planPagoTable.editedItem.penultimo_abono_activo.saldo_en_contra) : 0} (saldo en contra)
                    = $${currency(+planPagoTable.editedItem.pago_periodico_calculado + +(planPagoTable.editedItem.penultimo_abono_activo ? planPagoTable.editedItem.penultimo_abono_activo.saldo_en_contra : 0) )} (a pagar)
                  `"
                  :disabled="abonado_con_saldo_a_favor"
                  persistent-hint
                ></VCurrencyField>
              </v-col>

              <v-col cols="12" sm="6" md="4"> 
                <VTextField
                  :label="`Saldo restante (${plazoToString(planPagoTable.editedItem.forma_pago).lower} seleccionado)`"
                  prefix="$"
                  :value="`${formatCurrency(
                    currency(saldo_restante_trimestre) == 0 
                      ? 0 
                      : currency(saldo_restante_trimestre)
                    , {prefix: ''})}
                  `"
                  :hint="``"
                  persistent-hint
                  disabled
                ></VTextField>
              </v-col> 

              <v-col cols="12" sm="12" md="12"> 
                <v-divider />
              </v-col> 
            </v-row>

<!-- CANCELAR ABONO CHECKBOX -->
            <v-row>
              <v-col 
                v-show="!pasar_al_dia_sig_check && !editar_abono_check" 
                cols="12" sm="12" md="12" 
                :class="cancelar_abono_check ? '' : 'mb-n8'"
              > 
                <v-checkbox
                  v-model="cancelar_abono_check"
                  color="primary"
                  :label="`Cancelar abono`"
                  :hint="''"
                  :style="{ maxWidth: 'max-content' }"
                  :class="{ 'font-weight-bold': cancelar_abono_check }"
                  persistent-hint
                ></v-checkbox>

                <v-alert
                  v-show="!pasar_al_dia_sig_check && !editar_abono_check && cancelar_abono_check" 
                  border="left"
                  cols="12" sm="12" md="12" 
                  class="ml-1 mt-n2"
                  color="grey lighten-5"
                >
                  <div class="d-flex">
                    <!-- <v-icon color="info" class="pr-2">mdi-information</v-icon> -->
                    <div>
                      {{
                        cancelar_abono_check 
                          ? `Al aplicar esta acción se cancelará el abono seleccionado, también se revertirá el saldo a favor del mismo (si existe)`
                          : ``
                      }}
                    </div>
                  </div>
                </v-alert>

              </v-col>
            </v-row>

<!-- APLAZAR ABONO CHECKBOX -->
            <!-- <v-row>
              <v-col 
                v-show=" false /*!cancelar_abono_check && !editar_abono_check*/" 
                cols="12" sm="12" md="12" 
              > 
                <v-checkbox
                  v-model="pasar_al_dia_sig_check"
                  color="primary"
                  :label="`Aplazar abono al día siguiente`"
                  :hint="planCliente.forma_pago == FORMA_PAGO.DIARIO || $dayjs(abonoSelecc.fecha_abono).format('DD/MM/YYYY') != $dayjs().format('DD/MM/YYYY') 
                    ? `Opción disponible si el dia del abono coincide con el dia actual. No disponible en forma de pago Diario`
                    : ``"
                  :class="{ 'font-weight-bold': pasar_al_dia_sig_check }"
                  :style="{ maxWidth: 'max-content' }"
                  :disabled="planCliente.forma_pago == FORMA_PAGO.DIARIO || $dayjs(abonoSelecc.fecha_abono).format('DD/MM/YYYY') != $dayjs().format('DD/MM/YYYY')"
                  persistent-hint
                ></v-checkbox>

                <v-alert
                  v-show="!cancelar_abono_check && !editar_abono_check && pasar_al_dia_sig_check" 
                  border="left"
                  cols="12" sm="12" md="12" 
                  class="ml-1 mt-n2"
                  color="grey lighten-5"
                >
                  <div class="d-flex">
                    <div class="grey lighten-4">
                      {{
                        pasar_al_dia_sig_check
                          ? `Al aplicar esta acción se cancelará el abono y se moverá la fecha al día siguiente, también se revertirá el saldo a favor del mismo (si existe)`
                          : ``
                      }}
                    </div>
                  </div>
                </v-alert>

                <div v-show="!cancelar_abono_check && !editar_abono_check && pasar_al_dia_sig_check">
                  <b>Fecha próxima de pago (día sig.): </b>
                  {{ prox_fecha_abono ? $dayjs(prox_fecha_abono).format('dddd, DD [d]e MMMM [d]el YYYY') : '- - -' }}
                </div>

              </v-col>
            </v-row> -->
          </div>

        </v-card-text>
      </template>

      <template slot="actions">
        <Button
          v-show="true" 
          text 
          :disabled="(!editar_abono_check && !cancelar_abono_check && !pasar_al_dia_sig_check) || planPagoTable.disableActionBtn"
          @click="handleAbonoSave"
        >
          <span v-show="true">
            Aplicar
          </span>
        </Button>
      </template>
    </Dialog>

    <vue-element-loading 
      :active="fullPageLoading" 
      :text="vueElementLoadingText" 
      :text-style="{ color: '#333', fontSize: '20px' }" 
      :is-full-screen="true"
    />
    
  </v-container>
</template>

<script>
import Button from "@/components/ui/Button";
import VCurrencyField from "@/components/ui/VCurrencyField";
import DataTable from "@/components/ui/DataTable";
import Dialog from "@/components/ui/Dialog";
import useDataTable from "@/composables/useDataTable";
import { 
  currency, 
  formatCurrency, 
  isEmptyObj, 
  dateUtils, 
  plazoToString, 
  sortByProp, 
  btoaEncodeData,
  btoaDecodeData,
  activateFullPageLoading,
  updateFullPageLoadingText,
  disableFullPageLoading,
} from "@/utils/Functions";
import { APP_DEBUG_MODE as APP_DEBUG, FORMA_PAGO } from "@/utils/Constants";
import { state as storeState, actions, getters, STORE_NAMESPACES } from "@/store";
import FormValidations from "@/utils/FormValidations";
import { getCurrentInstance, ref, reactive, watch, computed, onMounted, onBeforeUnmount } from "vue";

export default {
  components: {
    Button,
    VCurrencyField,
    Dialog,
    DataTable,
  },
  setup(props) {
    const vm = getCurrentInstance();
    const { APP, ABONOS, CLIENTES, PLANES_CLIENTE, PLAN_PAGOS } = STORE_NAMESPACES;
    
    const IS_DEV_USER = getters[APP].getIsDevUser.value;
    const APP_DEBUG_MODE = APP_DEBUG || IS_DEV_USER;

    onMounted(() => {
      const autocompleteInput = vm.proxy.$refs.plan_cliente.$refs.input;

      function openOnFocus(e) {
        vm.proxy.$refs.plan_cliente.isMenuActive = true;
      }

      autocompleteInput.addEventListener('focus', openOnFocus, true);
    });

    onBeforeUnmount(() => {
      APP_DEBUG_MODE && console.log("[Reporte_PlanPagos]: ClientePlanYPagos + PlanesCliente + PlanPagos unmount => SET_DEFAULT()");

      actions[CLIENTES].SET_DEFAULT_PLAN_Y_PAGOS();
      actions[PLANES_CLIENTE].SET_DEFAULT();
      actions[PLAN_PAGOS].SET_DEFAULT();
    });

    const clearSelectedCheckboxes = ref(false);
    const forma_pago_header = ref("Trimestre");

    const plan_pago_export_fields = {
      [`${plazoToString(forma_pago_header.value).capitalized}`]: "amortizacion",
      ["Forma Pago"]: "forma_pago",
      ["Pago moneda contr."]: {
        field: "pago_moneda_contratada",
        callback: (value) => {
          return `${(value)}`;
        }
      },
      ["Tipo cambio"]: {
        field: "tipo_cambio",
        callback: (value) => {
          return `${(value)}`;
        }
      },
      ["Pago en pesos"]: {
        field: "pago_pesos",
        callback: (value) => {
          return `${(value)}`;
        }
      },
      ["Pendiente"]: {
        field: "saldo_pendiente",
        callback: (value) => {
          return `${(value)}`;
        }
      },
      ["Pago periodico"]: {
        field: "pago_periodico_calculado",
        callback: (value) => {
          return `${(value)}`;
        }
      },
      ["Abonado"]: {
        field: "abonado",
        callback: (value) => {
          return `${(value)}`;
        }
      },
      ["Saldo"]: {
        field: "saldo",
        callback: (value) => {
          return `${(value)}`;
        }
      },
    }
    
    const headers = [
      { text: plazoToString(forma_pago_header.value).capitalized, value: "amortizacion", align: "center" },
      // { text: "Moneda", value: "moneda" },
      { text: "Forma Pago", value: "forma_pago" },
      { text: "Pago moneda contr.", value: "pago_moneda_contratada" },
      { text: "Tipo cambio", value: "tipo_cambio" },
      { text: "Pago en pesos", value: "pago_pesos" },
      { text: "Pendiente", value: "saldo_pendiente" },
      { text: "Pago periodico", value: "pago_periodico_calculado" },
      { text: "Abonado", value: "abonado" },
      { text: "Saldo", value: "saldo" },
    ];
    
    const headersPago = [ // Headers para tabla personalizada, ver "abonos_plan_pagos" en DataTable.vue
      { text: "Id", value: "id", sortable: false },
      { text: "Forma de pago", value: "plan_forma_pago" },
      { text: "Cantidad abonada", value: "cantidad" },
      { text: "Saldo en contra", value: "saldo_en_contra" },
      { text: "Fecha del abono", value: "fecha_abono" },
      { text: "Cobrador asociado", value: "nombre_completo_cobrador" },
      { text: "Estatus", value: "abono_estatus" },
      { text: "Acciones", value: "action", sortable: false },
    ];

    const fullPageLoading = ref(false);
    const vueElementLoadingText = ref("Procesando...");

    const editar_abono_check = ref(false);
    const pasar_al_dia_sig_check = ref(false);
    const cancelar_abono_check = ref(false);
    const abonoSelecc = ref({});
    const abonoDialog = ref(false);
    const state = reactive({
      cliente: {},
      planCliente: {},
    });

    // Queries desde el apartado de un plan de cliente (al consultar)
    const qClienteId     = vm.proxy.$root.$route.query.qClienteId && btoaDecodeData(vm.proxy.$root.$route.query.qClienteId);
    const qPlanClienteId = vm.proxy.$root.$route.query.qPlanClienteId && btoaDecodeData(vm.proxy.$root.$route.query.qPlanClienteId);

    if (qClienteId && qPlanClienteId) {
      actions[PLANES_CLIENTE].FETCH(qClienteId, (planesClienteRes) => {
        if (!planesClienteRes.length) return;
        
        const planClienteSelected = getters[PLANES_CLIENTE].findById(qPlanClienteId).value;
        actions[PLAN_PAGOS].FETCH(planClienteSelected.id, planClienteSelected.suspendido, (planPagoRes) => {
          if (!planPagoRes.length) return;

          state.cliente           = getters[CLIENTES].findById(qClienteId).value;
          state.planCliente       = planClienteSelected;
          forma_pago_header.value = state.planCliente.forma_pago;

          vm.proxy.$root.$router.replace(`consulta_plan_pagos`);
        });
      });
    }

    const planPagoTable = useDataTable(getters[PLAN_PAGOS].getAll, {});
    
    const tableLoading = getters[APP].getLoading;
    const cliente_select = computed(() => {
      return getters[CLIENTES].getActivos.value.sort(sortByProp("num_cliente")).map(cliente => {
        return { text: `Cliente ${cliente.num_cliente}: ${cliente.nombre} ${cliente.apellidos}`, value: cliente.id }
      });
    });

    const planes_cliente_getter = getters[PLANES_CLIENTE].getAll;
    const plan_cliente_select = computed(() => {
      return planes_cliente_getter.value.sort(sortByProp("poliza")).map(plan => {
        return { 
          text: `${plan.poliza} (${plan.moneda}): Plan a ${plan.anios_aseguradora} ${plan.anios_aseguradora > 1 ? 'años' : 'año'} | ${plan.forma_pago}${plan.domiciliado ? ' (Domiciliado)' : ''}`, 
          value: plan.id
        };
      });
    });

    const abonado_con_saldo_a_favor = computed(() => {
      return +planPagoTable?.editedItem?.ultimo_abono_activo?.saldo_a_favor_usado > 0;
    })

    const saldo_restante_trimestre = computed(() => {
      const abonado_al_trimestre = currency((planPagoTable?.editedItem?.abonos || [])
        .filter(abono_trimestre => abono_trimestre.abono_estatus && abono_trimestre.id != +abonoSelecc.value.id)
        .reduce((acc, abono_trimestre) => acc + +abono_trimestre.cantidad, 0) );

      return currency(
        (+planPagoTable.editedItem.pago_pesos + +planPagoTable.editedItem.saldo_pendiente) 
        - abonado_al_trimestre
        - +abonoSelecc.value.cantidad
      );
    });

    // const prox_fecha_abono = computed(() => {
    //   let fecha_abono_dia_sig = vm.proxy.$root.$dayjs(planPagoTable?.editedItem?.ultimo_abono_activo?.fecha_abono).add(1, "day");
  
    //   if (dateUtils.esDomingo(fecha_abono_dia_sig)) {
    //     fecha_abono_dia_sig = vm.proxy.$root.$dayjs(fecha_abono_dia_sig).add(1, "day");
    //   }

    //   return fecha_abono_dia_sig;
    // });

    watch(() => abonoDialog.value, (abonoDialog) => {
      if (abonoDialog) {

      }
      else {
        editar_abono_check.value = false;
        pasar_al_dia_sig_check.value = false;
        cancelar_abono_check.value = false;
      }
    });

    watch(() => editar_abono_check.value, (value) => {
      if (value && abonado_con_saldo_a_favor.value) {
        abonoSelecc.value.cantidad = 0;
      }
    });

    function handleCliente(clienteId) {
      APP_DEBUG_MODE && console.log("[Reporte_PlanPagos]: PlanPagos onHandleCliente => SET_DEFAULT()");
      actions[PLAN_PAGOS].SET_DEFAULT();

      state.cliente = getters[CLIENTES].findById(clienteId).value;

      // Obtener los planes del cliente seleccionado
      actions[PLANES_CLIENTE].FETCH(clienteId, (planes_cliente) => {
        if (!isEmptyObj(state.cliente)) {
          vm.proxy.$refs.plan_cliente.focus();
          vm.proxy.$refs.plan_cliente.reset();

          if (!planes_cliente.length && !storeState[APP].serverError) {
            vm.proxy.$root.$toast.info("El cliente seleccionado no cuenta con planes registrados o activos");
          }
        }
      });
    }

    function handlePlanesCliente(planClienteId) {
      state.planCliente = getters[PLANES_CLIENTE].findById(planClienteId).value;
      forma_pago_header.value = state.planCliente.forma_pago;
      clearSelectedCheckboxes.value = true;

      // Obtener el plan de pago del plan del cliente seleccionado
      actions[PLAN_PAGOS].FETCH(state.planCliente.id, state.planCliente.suspendido, () => {
        clearSelectedCheckboxes.value = false;
      });
    }

    const printPlanStatus = computed(() => {
      if (!isEmptyObj(state.planCliente)) {
        const fontStyle = "font-weight-bold text--darken-3";

        // Un plan dado de baja no se muestra en la lista de planes (de momento), asi que este bloque nunca toma efecto
        if (!state.planCliente.estatus) {
          return {
            text: "Plan dado de baja",
            color: `red--text ${fontStyle}`,
          };
        }

        // Un plan suspendido no se muestra en la lista de planes (de momento), asi que este bloque nunca toma efecto
        if (state.planCliente.suspendido) {
          return {
            text: "Plan suspendido",
            color: `amber--text ${fontStyle}`,
          };
        }

        if (!state.planCliente.plan_iniciado) {
          return {
            text: "No iniciado",
            color: `grey--text font-weight-bold`,
          };
        }
          
        if (state.planCliente.plan_completado) {
          return {
            text: "Completado",
            color: `green--text ${fontStyle}`,
          };
        }
        
        return {
          text: "Activo",
          color: `grey--text ${fontStyle}`,
        };
      }

      return "";
    });

    function handleAbonoRow(abono) {
      if (abono.es_editable) {
        abonoDialog.value = true;
        abonoSelecc.value = {...abono};
      }
      else {
        vm.proxy.$root.$toast.info("Error: no se puede cargar el abono seleccionado");
      }
    }

    function handleMultipleAbonoCancels(exportType, rowValues) {
      const cliente         = state.cliente;
      const abonosACancelar = rowValues.value.filter(abono => abono.abono_estatus);
      const idTrimSelecc    = planPagoTable.editedItem.id;
      const abonosActivosTrimSelecc = planPagoTable.editedItem.abonos_activos;
      const { plan_catalog_data, ...planCliente } = state.planCliente;

      const toastTimeout = { timeout: 5000 };
      const toast = {
        success: (text) => vm.proxy.$root.$toast['success'](text, toastTimeout),
        error: (text) => vm.proxy.$root.$toast['error'](text, toastTimeout),
      };

      if (planCliente.suspendido) {
        return toast.error('El plan de cliente necesita estar activo para poder cancelar abonos');
      }

      if (!abonosACancelar.length) {
        return toast.error('Seleccione un abono con estatus "Aplicado" para cancelar');
      }

      const abonosActivosDESC    = [...abonosActivosTrimSelecc].sort(sortByProp("id", true));
      const abonosACancelarDESC  = [...abonosACancelar].sort(sortByProp("id", true));
      const validateAbonoSecuencial = abonosACancelarDESC.every((abono, i) => abono.id == abonosActivosDESC[i].id);
      const ultimoAbonoEditable = abonosACancelarDESC[0].es_editable;

      if (abonosActivosDESC[0].id != abonosACancelarDESC[0].id || !validateAbonoSecuencial) {
        return toast.error('No es posible cancelar los abonos, la selección debe ser secuencial del abono más reciente al menos reciente');
      }
      
      if (!ultimoAbonoEditable) {
        return toast.error('Trimestre no válido para cancelar abonos');
      }

      activateFullPageLoading(vueElementLoadingText, fullPageLoading);

      const abonosACancelarIds = abonosACancelar.map(abono => abono.id);
      /* Obtener sólo los trimestres del plan de pago hasta el trimestre actual y no todos
      y mapear solo los props de la BD que pertenecen al plan de pagos en cada trimestre/amortizacion
      junto con los abonos activos */
      const planPago = getters[PLAN_PAGOS].getAll.value
        .filter(trimestre => +trimestre.id <= +idTrimSelecc)
        .map(trimestre => {
          const { abonos, ultimo_abono_activo, penultimo_abono_activo, ultimo_abono_activo_trimestre_anterior, ...trimestre_w_abonos_activos } = trimestre;
          return trimestre_w_abonos_activos;
        });

      const trimestreActual = planPago.find(trimestre => +trimestre.id == +idTrimSelecc);
      
      // Logs para debug con los datos originales antes de modificarse
      if (APP_DEBUG_MODE) {
        console.log('======================================');
        console.log('DATOS ORIGINALES ANTES DE MODIFICARSE');
        console.log('======================================');
        console.log('ids abonos a cancelar: ', abonosACancelarIds);
        console.log('plan cliente: ', {...planCliente});
        console.log('trimestre actual: ', {...trimestreActual});
        console.log('plan pago: ', planPago);
      }

      const data = {
        abonosACancelarIds: [...abonosACancelarIds],
        planCliente:        {...planCliente},
        trimestreActual:    {...trimestreActual},
        planPago:           [...planPago]
      }
      delete data.trimestreActual.abonos_activos;

      /* Seteo inicial de datos dependiendo del estado del trimestre y los
      abonos a cancelar */
      // NOTA: Los saldos a favor y los saldos en contra se re-calcularán en el server
      const esPrimerTrimestre = +trimestreActual.amortizacion == 1;
      const todosAbonosDelTrimestreCancelados = +abonosActivosTrimSelecc.length == +abonosACancelar.length;

      const abonado_al_trimestre = currency(abonosActivosTrimSelecc
        .filter((abono) => !abonosACancelarIds.includes(abono.id))
        .reduce((acc, abono) => acc + +abono.cantidad, 0) );

      const saldo_al_trimestre 
        = currency((+trimestreActual.pago_pesos + +trimestreActual.saldo_pendiente) 
        - abonado_al_trimestre);

      data.trimestreActual.abonado = abonado_al_trimestre;
      data.trimestreActual.saldo   = saldo_al_trimestre;
      data.planCliente.recibido_a_cuenta = abonado_al_trimestre;
      data.planCliente.abonos_pagados_migracion = +abonosActivosTrimSelecc
        .filter((abono) => !abonosACancelarIds.includes(abono.id))
        .length;

      if (planCliente.plan_iniciado == true && esPrimerTrimestre && todosAbonosDelTrimestreCancelados) {
        data.planCliente.plan_iniciado = false;
      }

      if (trimestreActual.trimestre_iniciado == true && todosAbonosDelTrimestreCancelados) {
        data.trimestreActual.trimestre_iniciado = false;
        data.trimestreActual.saldo_pendiente    = 0;
        data.trimestreActual.saldo = +trimestreActual.pago_pesos;
      }

      if (planCliente.plan_completado == true) {
        data.planCliente.plan_completado = false;
      }
      
      if (trimestreActual.trimestre_cerrado == true) {
        data.trimestreActual.trimestre_cerrado = false;
        data.planCliente.trimestres_pagados = +planCliente.trimestres_pagados > 0
          ? (+planCliente.trimestres_pagados - 1)
          : 0;
      }

      const formattedFechaAbono = vm.proxy.$root.$dayjs(abonosACancelar[0].fecha_abono_real || abonosACancelar[0].fecha_abono).format('YYYY-MM-DD');
      data.planCliente.fecha_prox_pago            = formattedFechaAbono;
      data.planCliente.fecha_prox_pago_tolerancia = formattedFechaAbono;

      // Logs para debug con los datos modificados pero antes de enviar los datos
      if (APP_DEBUG_MODE) {
        console.log('======================================');
        console.log('DATOS MODIFICADOS ANTES DE ENVIARSE');
        console.log('======================================');
        console.log('fecha prox. pago: ', formattedFechaAbono)
        console.log('plan cliente: ', {...data.planCliente});
        console.log('trimestre actual: ', {...data.trimestreActual});
        console.log('abonado al trimestre: ', abonado_al_trimestre);
        console.log('saldo al trimestre: ', saldo_al_trimestre);
        console.log('es primer trimestre: ', esPrimerTrimestre);
        console.log('todos los abonos de trimestre cancelados: ', todosAbonosDelTrimestreCancelados);
        console.log("DATA PAYLOAD: ", data);
        console.log('======================================');
      }
      
      /** ========================= */
      /** CANCELAR MULTIPLES ABONOS */
      /** ========================= */
      actions[ABONOS].CANCEL_MULTIPLE(data, (res) => {
        
        updateFullPageLoadingText(vueElementLoadingText);
        if (res.success) {
          rowValues.value = [];

          actions[PLANES_CLIENTE].FETCH(cliente.id, (planes_cliente) => {
            actions[PLAN_PAGOS].FETCH(planCliente.id, planCliente.suspendido, (planes_pago) => {
              planPagoTable.editItem(planes_pago[planPagoTable.editedIndex]);

              const index = planes_cliente.findIndex(plan => plan.id === planCliente.id);

              // Actualizar el estado del plan del cliente seleccionado con lo nuevo, en este caso del fetch de PLANES_CLIENTE
              state.planCliente = {...planCliente, ...planes_cliente[index]};
              disableFullPageLoading(fullPageLoading);
            });
          });

          toast.success(res.message);
        }
        else {
          toast.error(`Ha ocurrido un error: ${res.message}`);
          disableFullPageLoading(fullPageLoading);
        }
        
      }); // Termina CANCELAR MULTIPLES ABONOS
    }

    function handleAbonoSave() {
      activateFullPageLoading(vueElementLoadingText, fullPageLoading);
      // Proceder a cancelar/pasar al dia sig./actualizar el abono
      /* Casos a considerar
        1.- Editar:
          Tablas a actualizar: abonos, plan_cliente y plan_pagos[trimestre actual]
            - Abonos, se actualiza la cantidad y se calcula y actualiza el saldo en contra (si existe)
            - Plan Cliente, se actualiza el saldo a favor de la referencia del abono (si existe)
            - Plan Pagos, se actualiza el abonado y saldo (sumando/restando el pago_pesos y el saldo_pendiente menos la cantidad actual y la cantidad de los abonos anteriores
              sin contar abonos cancelados)
            ! Si el abono a editar es el ultimo del plan, se debe de validar que el saldo restante total no sea > 0
        2.- Cancelar: Similar a "Dia siguiente"
          Si canceló el ultimo abono del trimestre, volver a abrir el trimestre anterior, y si se canceló el ultimo pago del plan, volver 
          a marcar el plan como no completado (tablas a actualizar: abonos, plan_cliente y plan_pagos[trimestre actual])
            - Abonos, cambiar el abono_estatus a false
            - Plan Ciente, igual al apartado "Dia siguiente" pero sin actualizar la fecha_prox_pago
            - Plan Pagos, igual al apartado "Dia siguiente"
        3.- Dia siguiente: Lo principal es revertir los movimientos del abono insertado (el abono mismo y el calculo de saldos)
          Si se cerro el trimestre actual (el abono que se pasa al dia sig. es el ultimo del trimestre), volverlo a abrir, y si ya era el ultimo pago del plan, volver 
          a marcar el plan como no completado (tablas a actualizar: abonos, plan_cliente y plan_pagos[trimestre actual])
            - Abonos, cambiar el abono_estatus a false
            - Plan Cliente, se actualiza la fecha_prox_pago a un dia despues sin contar domingo, se quita el saldo a favor de la referencia original del abono si existe, 
              y solo se actualiza el estatus de plan_completado a false si se trataba del pago final del plan, si se trata del primer pago, se actualiza el estatus de 
              plan_iniciado a false
            - Plan Pagos, se actualiza el abonado y saldo (sumando/restando el pago_pesos y el saldo_pendiente menos la cantidad actual y la cantidad de los abonos 
              anteriores sin contar abonos cancelados) y solo se actualiza el estatus de trimestre_cerrado a false si se trataba ultimo pago del trimestre, si se 
              trata del primer pago, se actualiza el estatus de trimestre_iniciado a false
        ! Tomar en cuenta que se puede modificar los trimestres pagados, recibido a cuenta y los abonos pagados
          en el plan de cliente
      */
      const planCliente = state.planCliente;
      const abono = abonoSelecc.value;
      const { editedItem: trimestre_actual } = planPagoTable;
      const { 
        ultimo_abono_activo: ultimo_abono, 
        penultimo_abono_activo: penultimo_abono,
        ultimo_abono_activo_trimestre_anterior: ult_abono_activo_trim_anterior
      } = trimestre_actual;

      const abonado_al_trimestre = currency(trimestre_actual.abonos
        .filter(abono_trimestre => abono_trimestre.abono_estatus && abono_trimestre.id != ultimo_abono.id)
        .reduce((acc, abono_trimestre) => acc + +abono_trimestre.cantidad, 0) );

      const saldo_al_trimestre 
        = currency((+trimestre_actual.pago_pesos + +trimestre_actual.saldo_pendiente) 
        - abonado_al_trimestre);

      // Datos a enviar con algunos valores obligatorios/por default
      const data = {
        abono: {
          id: ultimo_abono.id,
        },
        trimestre_actual: {
          id: trimestre_actual.id,
          abonado: abonado_al_trimestre + +abono.cantidad,
          saldo: saldo_al_trimestre - +abono.cantidad,
        },
        plan_cliente: {
          id: planCliente.id,
          recibido_a_cuenta: abonado_al_trimestre + +abono.cantidad,
        }
      }

      /* 
        Bloque encargado de actualizar el estatus de iniciado o completado/cerrado del plan de cliente y
        el trimestre actual. Aqui tambien se actualizan el conteo de trimestres pagados y abonos pagados. 
        Solo disponible para las opciones de CANCELAR y APLAZAR 
      */ 
      if (!editar_abono_check.value) {
        const solo_un_abono_activo = trimestre_actual.abonos_activos.length == 1;
        const es_primer_trimestre  = +trimestre_actual.amortizacion == 1;

        data.abono.abono_estatus      = false;
        data.trimestre_actual.abonado = abonado_al_trimestre;
        data.trimestre_actual.saldo   = saldo_al_trimestre;
        data.plan_cliente.recibido_a_cuenta = abonado_al_trimestre;
        data.plan_cliente.abonos_pagados_migracion = +trimestre_actual.abonos_activos.length > 0
          ? (+trimestre_actual.abonos_activos.length - 1)
          : 0;

        /* Checar si esta decision pueda entrar en conflicto si se decide actualizar el plan de cliente
        inmediatamente despues, ver linea 1024 en PlanesClientes.vue */
        if (planCliente.plan_iniciado == true && es_primer_trimestre && solo_un_abono_activo) {
          APP_DEBUG_MODE && console.log("planCliente.plan_iniciado == true && es_primer_trimestre && solo_un_abono_activo");
          data.plan_cliente.plan_iniciado = false;
        }

        if (trimestre_actual.trimestre_iniciado == true && solo_un_abono_activo) {
          APP_DEBUG_MODE && console.log("trimestre_actual.trimestre_iniciado == true && solo_un_abono_activo");
          data.trimestre_actual.trimestre_iniciado = false;
          data.trimestre_actual.saldo_pendiente    = 0;
          // Se sobreescribe el saldo del trimestre actual = al pago periodico
          data.trimestre_actual.saldo = +trimestre_actual.pago_pesos;
        }

        if (planCliente.plan_completado == true) {
          APP_DEBUG_MODE && console.log("planCliente.plan_completado == true");
          data.plan_cliente.plan_completado = false;
        }
        
        if (trimestre_actual.trimestre_cerrado == true) {
          APP_DEBUG_MODE && console.log("trimestre_actual.trimestre_cerrado == true");
          data.trimestre_actual.trimestre_cerrado = false;
          data.plan_cliente.trimestres_pagados = +planCliente.trimestres_pagados > 0
            ? (+planCliente.trimestres_pagados - 1)
            : 0;
        }
      }

      const pago_periodico_calculado = penultimo_abono
        ? (+trimestre_actual.pago_periodico_calculado + +penultimo_abono.saldo_en_contra)
        : +trimestre_actual.pago_periodico_calculado;

    /** ============ */
    /** EDITAR ABONO */
    /** ============ */
      if (editar_abono_check.value) {
        let saldo_favor = 0;
        let saldo_contra = 0;

        if (+abono.cantidad >= pago_periodico_calculado) {
          APP_DEBUG_MODE && console.log("if => +abono.cantidad >= pago_periodico_calculado")
          // Se agrega el saldo a favor
          saldo_favor = (pago_periodico_calculado - +abono.cantidad); 

          if (+ultimo_abono.cantidad >= pago_periodico_calculado) {
            // Se agrega el saldo a favor, ajustado al ultimo abono
            saldo_favor = (+ultimo_abono.cantidad - +abono.cantidad);
            APP_DEBUG_MODE && console.log("if => +ultimo_abono.cantidad >= pago_periodico_calculado: ", `${+ultimo_abono.cantidad} - ${+abono.cantidad}`, saldo_favor)
          }
        }
        else {
          saldo_contra = (pago_periodico_calculado - +abono.cantidad); 

          if (+ultimo_abono.cantidad >= pago_periodico_calculado) {
            APP_DEBUG_MODE && console.log("else => +ultimo_abono.cantidad >= pago_periodico_calculado")
            // Se resta el saldo a favor
            saldo_favor = (+ultimo_abono.cantidad - pago_periodico_calculado);
          } 

          /* Al ser el sig. valor verdadero, la cantidad se fuerza en 0 y entonces se 
          debe reintegrar todo el saldo a favor */
          if (abonado_con_saldo_a_favor.value && +ultimo_abono.saldo_a_favor_usado == +ultimo_abono.cantidad) {
            APP_DEBUG_MODE && console.log("else => abonado_con_saldo_a_favor.value && +ultimo_abono.saldo_a_favor_usado == +ultimo_abono.cantidad")
            saldo_favor = (+ultimo_abono.saldo_a_favor_usado * -1);
          }
        }

        if (trimestre_actual.trimestre_cerrado == true) {
          data.plan_cliente.recibido_a_cuenta = 0;
        }

        APP_DEBUG_MODE && console.log("SALDO A FAVOR: ", +planCliente.saldo_a_favor, saldo_favor);
        APP_DEBUG_MODE && console.log(+planCliente.saldo_a_favor + saldo_favor);

        data.abono.cantidad             = +abono.cantidad;
        data.abono.saldo_en_contra      = saldo_contra;
        data.plan_cliente.saldo_a_favor = +planCliente.saldo_a_favor + saldo_favor;

        // Establecer el saldo en contra en el plan de cliente
        // REVIEW: ver que el saldo en contra se actualice correctamente en esta parte
        data.plan_cliente.saldo_en_contra = ult_abono_activo_trim_anterior
          ? +ult_abono_activo_trim_anterior.saldo_en_contra + saldo_contra
          : saldo_contra;
      }

    /** ============== */
    /** CANCELAR ABONO */
    /** ============== */
      else if (cancelar_abono_check.value) {
        let saldo_favor = 0;

        if (+ultimo_abono.cantidad >= pago_periodico_calculado) {
          // Se resta el saldo a favor
          saldo_favor = (+ultimo_abono.cantidad - pago_periodico_calculado);
        }

        /* Al ser el sig. valor verdadero, la cantidad se fuerza en 0 y entonces se 
        debe reintegrar todo el saldo a favor */
        if (abonado_con_saldo_a_favor.value && +ultimo_abono.saldo_a_favor_usado == +ultimo_abono.cantidad) {
          saldo_favor = (+ultimo_abono.saldo_a_favor_usado * -1);
        }

        // /* Si solo existe un unico abono y es el primer trimestre, o si solo existe un abono y el trimestre
        // anterior no tiene abonos por que se trata de una migracion (ej. se inició en el 5to trimestre, por tanto
        // los trimestres anteriores no tendran registros de abonos), entonces actualizar la fecha_prox_pago y la 
        // fecha_prox_pago_tolerancia para que sea igual a la fecha_inicial */
        // if (trimestre_actual.tomar_fecha_inicial_plan_cliente) {
        //   data.plan_cliente.fecha_prox_pago            = data.plan_cliente.fecha_inicial;
        //   data.plan_cliente.fecha_prox_pago_tolerancia = data.plan_cliente.fecha_inicial;
        // }
        // /* De lo contrario actualizar la fecha_prox_pago y la fecha_prox_pago_tolerancia para que sea igual a
        // la fecha del abono anterior, si es el unico abono se tomara el del trimestre anterior si es que existe,
        // y si no existe de eso ya se encarga la primera decision */
        // else if (trimestre_actual.fecha_prox_pago_post_cancelacion) {
        //   data.plan_cliente.fecha_prox_pago            = trimestre_actual.fecha_prox_pago_post_cancelacion;
        //   data.plan_cliente.fecha_prox_pago_tolerancia = trimestre_actual.fecha_prox_pago_post_cancelacion;
        // }

        data.plan_cliente.saldo_a_favor = +planCliente.saldo_a_favor + saldo_favor;

        // Actualizar las sig. propiedades del plan de cliente a la fecha del abono cancelado
        const formattedFechaAbono = vm.proxy.$root.$dayjs(abono.fecha_abono_real || abono.fecha_abono).format('YYYY-MM-DD');
        data.plan_cliente.fecha_prox_pago            = formattedFechaAbono;
        data.plan_cliente.fecha_prox_pago_tolerancia = formattedFechaAbono;

        // Establecer el saldo en contra en el plan de cliente
        // REVIEW: ver que el saldo en contra se actualice correctamente en esta parte
        if (trimestre_actual.abonos_activos.length == 1) {
          data.plan_cliente.saldo_en_contra = ult_abono_activo_trim_anterior
            ? (+planCliente.saldo_en_contra - +ultimo_abono.saldo_en_contra)
            : 0;
        }
        else {
          data.plan_cliente.saldo_en_contra = (+ultimo_abono.saldo_en_contra > +penultimo_abono.saldo_en_contra)
            ? +planCliente.saldo_en_contra - (+ultimo_abono.saldo_en_contra - +penultimo_abono.saldo_en_contra)
            : +planCliente.saldo_en_contra + (+penultimo_abono.saldo_en_contra - +ultimo_abono.saldo_en_contra);
        }
      }

    /** ============= */
    /** APLAZAR ABONO */
    /** ============= */
      // else if (pasar_al_dia_sig_check.value) {
      //   let saldo_favor = 0;
      //   let fecha_abono_dia_sig = vm.proxy.$root.$dayjs(ultimo_abono.fecha_abono).add(1, "day");

      //   if (dateUtils.esDomingo(fecha_abono_dia_sig)) {
      //     fecha_abono_dia_sig = vm.proxy.$root.$dayjs(fecha_abono_dia_sig).add(1, "day");
      //   }

      //   if (+ultimo_abono.cantidad >= pago_periodico_calculado) {
      //     // Se resta el saldo a favor
      //     saldo_favor = (+ultimo_abono.cantidad - pago_periodico_calculado);
      //   }

      //   /* Al ser el sig. valor verdadero, la cantidad se fuerza en 0 y entonces se 
      //   debe reintegrar todo el saldo a favor */
      //   if (abonado_con_saldo_a_favor.value && +ultimo_abono.saldo_a_favor_usado == +ultimo_abono.cantidad) {
      //     saldo_favor = (+ultimo_abono.saldo_a_favor_usado * -1);
      //   }

      //   data.plan_cliente.saldo_a_favor   = +planCliente.saldo_a_favor + saldo_favor;
      //   data.plan_cliente.fecha_prox_pago = vm.proxy.$root.$dayjs(fecha_abono_dia_sig).format("YYYY-MM-DD");
      //   data.plan_cliente.fecha_prox_pago_tolerancia = vm.proxy.$root.$dayjs(ultimo_abono.fecha_abono).format("YYYY-MM-DD");
      // }
      
    /** ====================== */
    /** SI NO SE ELIGIO ACCION */
    /** ====================== */
      else {
        return vm.proxy.$root.$toast.info("No se ha realizado ninguna acción");
      }

      // Logs para debug antes de actualizar el abono
      if (APP_DEBUG_MODE) {
        console.log('plan cliente: ', {...planCliente});
        console.log('abono seleccionado: ', {...abono});
        console.log('trimestre actual: ', {...trimestre_actual});
        console.log('abono seleccionado (referencia inmutable): ', {...ultimo_abono});
        console.log('penultimo abono activo (referencia inmutable): ', {...penultimo_abono});
        console.log('abonado al trimestre: ', abonado_al_trimestre);
        console.log('saldo al trimestre: ', saldo_al_trimestre);
        console.log("DATA PAYLOAD: ", data);
      }

      actions[CLIENTES].UPDATE_PAGO(data, (res) => {

        updateFullPageLoadingText(vueElementLoadingText);
        if (res.success) {
          // Actualizar el trimestre seleccionado junto con sus abonos
          actions[PLAN_PAGOS].FETCH(planCliente.id, planCliente.suspendido, (planes_pago) => {
            planPagoTable.editItem(planes_pago[planPagoTable.editedIndex]);

            // Actualizar el estado del plan del cliente seleccionado con lo nuevo modificado
            state.planCliente = {...planCliente, ...data.plan_cliente};

            // Y actualizar el módulo de store PLANES_CLIENTE, para que no haya inconsistencias si se está cambiando de plan de cliente
            const index = storeState[PLANES_CLIENTE].findIndex(plan => plan.id === state.planCliente.id);
            Object.assign(storeState[PLANES_CLIENTE][index], state.planCliente);

            disableFullPageLoading(fullPageLoading);
          });

          vm.proxy.$root.$toast.success(res.message);
        }
        else {
          vm.proxy.$root.$toast.error(`Ha ocurrido un error: ${res.message}`);
          disableFullPageLoading(fullPageLoading);
        }

        abonoDialog.value = false;
      });
    }

    return {
      clearSelectedCheckboxes,
      plan_pago_export_fields,
      abonado_con_saldo_a_favor,
      // prox_fecha_abono,
      saldo_restante_trimestre,
      editar_abono_check,
      pasar_al_dia_sig_check,
      cancelar_abono_check,
      abonoSelecc,
      abonoDialog,
      planPagoTable,
      headers,
      headersPago,
      tableLoading,
      cliente: computed(() => state.cliente),
      cliente_select,
      planCliente: computed(() => state.planCliente),
      toPlanCliente: computed(() => state.planCliente),
      plan_cliente_select,
      printPlanStatus,

      qClienteId,
      btoaEncodeData,
      queryClienteYPlanClienteIdExist: (qClienteId && qPlanClienteId),

      fullPageLoading,
      vueElementLoadingText,

      handleCliente,
      handlePlanesCliente,
      handleAbonoRow,
      handleAbonoSave,

      handleMultipleAbonoCancels,

      isEmptyObj,
      currency,
      formatCurrency,
      plazoToString,

      FORMA_PAGO,
      IS_DEV_USER,
    };
  }
};
</script>

<style lang="scss">

#planpagos-table {
  tbody tr {
    cursor: pointer;
  }
}

#linkReturnPlanCliente:hover { 
  text-decoration: underline;
}
  
</style>