<template>
  <div>

    <v-divider v-if="!title"></v-divider>
    
    <v-list-item :disabled="disabled" class=""> <!-- mb-3 -->
      <v-list-item-content>

        <v-list-item-title v-if="title" class="title">
          {{ title }}
        </v-list-item-title>
  
        <v-list-item-subtitle v-if="subtitle">
          {{ subtitle }}
        </v-list-item-subtitle>

      </v-list-item-content>
    </v-list-item>

  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    subtitle: {
      required: true,
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  }
}
</script>
