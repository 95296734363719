import { abonosAPI } from "@/utils/Axios";
import { $toast } from "@/main";
import { forceArrayResult } from "@/utils/Functions";
import { STORE_NAMESPACES } from "@/store/utils/constants";
import { APP_DEBUG_MODE } from "@/utils/Constants";

const { DEV_ADMIN } = STORE_NAMESPACES;

//======================================================
// ACTIONS
//======================================================
const actionModule = (state) => ({
  [DEV_ADMIN]: {

    SET_DEFAULT: () => {
      state[DEV_ADMIN] = [];
    },
    
    /* Obtiene una tabla determinada especificada de la base de datos para consulta
    sin tener que entrar directamente al servidor de produccion */
    FETCH_TABLE: (tableName, callback = null) => {
      abonosAPI
        .get(`dev_admin_dbtable/${tableName}`)
        .then(res => {
          APP_DEBUG_MODE && console.log(`FETCHED TABLE - DEV_ADMIN (${tableName}): `, res);
          
          /* Si la respuesta es un arreglo se devuelve la respuesta, en caso contrario quiere decir 
          que ocurrio algun error, pues se regresa un objeto con las propiedades "success" y "message" 
          en lugar de un arreglo con los datos, por tanto si se da este caso se establece un arreglo 
          vacio por default al guardar en el store y así evitar errores en consola */
          const result = forceArrayResult(res);

          state[DEV_ADMIN] = result;
          callback && callback(result);
        });
    },

    DELETE: (tableName, data, callback = null) => {
      const index = state[DEV_ADMIN].findIndex(reg => reg.id === data.id);

      const deleteReqBodyPlan = { data: { id: data.id } };
      abonosAPI
        .delete(`dev_admin_delete_bd_reg/${tableName}`, deleteReqBodyPlan)
        .then(res => {
          if (!res.success) {
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("DELETED - TABLE ROW: ", res.deleted_table_row);
          APP_DEBUG_MODE && console.log("FROM TABLE: ", tableName);
          
          state[DEV_ADMIN].splice(index, 1);
          $toast.success(res.message);

          callback && callback();
        });
    },

    // Actualiza la columna de saldos en contra de todos los planes de clientes
    UPDATE_SALDOS_EN_CONTRA: (callback = null) => {
      abonosAPI
        .patch(`dev_admin_update_saldos_en_contra`)
        .then(res => {
          if (!res.success) {
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("PLANES CLIENTE AFECTADOS: ", res.updated_planes_clientes);
          $toast[res.toast_type](res.message);

          callback && callback();
        });
    },

    INSERT_MULTIPLES_ABONOS_EN_CERO_PAGO_DIARIO: (fechaAbono, idCobrador, callback = null) => {
      abonosAPI
        .post(`dev_admin_insert_multiples_abonos_en_cero_pago_diario`, { fechaAbono, idCobrador })
        .then(res => {
          if (!res.success) {
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          $toast[res.toast_type](res.message);
          callback && callback();
        });
    },

    CANCELAR_MULTIPLES_ABONOS_EN_CERO_PAGO_DIARIO: (fechaAbono, idCobrador, callback = null) => {
      abonosAPI
        .post(`dev_admin_cancel_multiples_abonos_en_cero_pago_diario`, { fechaAbono, idCobrador })
        .then(res => {
          if (!res.success) {
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          $toast[res.toast_type](res.message);
          callback && callback();
        });
    },

  }
});

//======================================================
// GETTERS
//======================================================
const getterModule = (state, computed) => ({
  [DEV_ADMIN]: {
    
    getAll: computed(() => state[DEV_ADMIN]),

  },
});

export const devAdminActions = actionModule;
export const devAdminGetters = getterModule;