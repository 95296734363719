<template>
  <!-- Menu bar -->
  <v-app-bar 
    color="blue-grey" 
    :dense="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
    :hide-on-scroll="false"
    clipped-right 
    clipped-left 
    dark 
    app 
  >
    <v-app-bar-nav-icon
      class="ml-n2"
      @click.stop="$emit('drawer-toggle')"
    ></v-app-bar-nav-icon>

    <v-divider class="mr-4 ml-2" inset vertical></v-divider>

    <div>
      <v-toolbar-title class="mb-n1">Addendo</v-toolbar-title>
      <div class="subtitle-2">Control de abonos</div>
    </div>

    <v-spacer></v-spacer>

    <!-- <v-toolbar-items>
      <Button text>Link 1</Button>
      <Button text>Link 2</Button>
      <Button text>Link 3</Button>

      <v-menu
        transition="slide-y-transition"
        :offset-x="false"
        :offset-y="true"
        bottom
      >
        <template v-slot:activator="{ on }">
          <Button text v-on="on" >
            <div class="mr-1">Slide Y Transition</div>
            <v-icon >mdi-menu-down</v-icon>
          </Button>
        </template>
        <v-list>
          <v-list-item to="/about">
            <v-list-item-title>Elem 1</v-list-item-title>
          </v-list-item>

          <v-list-item to="/about">
            <v-list-item-title>Elem 2</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items> -->

    <div class="mr-2 text-no-wrap text-right">
      <div class="mb-n1 subtitle-1">{{ logged_user.nombre }} {{ logged_user.apellidos }}</div>
      
      <div class="subtitle-2">
        {{ logged_user.privilegio == 0 ? "Developer" : "" }}
        {{ logged_user.privilegio == 1 ? "SuperAdmin" : "" }}
        {{ logged_user.privilegio == 2 ? "Admin" : "" }}
        {{ logged_user.privilegio == 3 ? "Cobrador" : "" }}
      </div>
    </div>

    <v-divider class="mx-2" inset vertical></v-divider>

    <v-menu
      transition="slide-y-transition"
      :offset-x="false"
      :offset-y="true"
      bottom
    >
      <template v-slot:activator="{ on }">
        <Button class="mr-n2" icon v-on="on">
          <v-icon>mdi-account</v-icon>
        </Button>
      </template>

      <v-list>
        <v-list-item
          v-for="(headerItem, i) in headerItems" 
          :key="`${headerItem.text}_${i}`" 
          :to="headerItem.route"
          @click="headerItem.onClick"
        >

          <v-icon class="mr-3" v-text="headerItem.icon"></v-icon>

          <v-list-item-content>
            <v-list-item-title v-text="headerItem.text"></v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>
    </v-menu>

    <v-divider v-show="false" class="mr-4 ml-2" inset vertical></v-divider>

    <!-- <Button v-show="false" v-tooltip.left-end="'Invertir tema de color'" icon @click="invertThemeColor">
      <v-icon>mdi-invert-colors</v-icon>
    </Button> -->

    <!-- <v-divider class="mx-4" inset vertical></v-divider>
    <v-app-bar-nav-icon @click.stop="drawerRight = !drawerRight"></v-app-bar-nav-icon> -->
  </v-app-bar>
</template>

<script>
import { getCurrentInstance, onMounted } from 'vue';
import Button from "@/components/ui/Button";
import { logout, setStorageItem, getStorageItem } from "@/utils/Functions";
import { state, getters, STORE_NAMESPACES } from "@/store";

export default {
  components: {
    Button,
  },
  setup(props) {
    // const vm = getCurrentInstance();

    const { APP, USERS } = STORE_NAMESPACES;
    const logged_user = getters[USERS].findById(state[APP].idUser);

    const headerItems = [
      { route: '/cuenta_usuario', text: 'Cuenta', icon: 'mdi-settings', onClick: () => {} },
      { route: '/login',          text: 'Salir',  icon: 'mdi-logout', onClick: logout },
    ];

    // function invertThemeColor() {
    //   vm.proxy.$vuetify.theme.dark = !vm.proxy.$vuetify.theme.dark;
    //   setStorageItem("vDarkTheme", vm.proxy.$vuetify.theme.dark);
    // };

    return {
      logged_user,
      logout,

      // invertThemeColor,
      headerItems,
    }
  }
};
</script>
