<template>
  <!-- Drawer left, props: color="blue-grey darken-3" dark -->
  <v-navigation-drawer 
    width="230" 
    v-model="drawer" 
    app 
    clipped 
    :mobile-breakpoint="1026"
  >    
    <ListItemSeparator
      title=""
      subtitle="Vista principal"
    />

    <v-list class="py-0">
      <v-list-item-group color="primary">
<!-- USERS -->
        <ListItem
          v-for="(itemUser, i) in drawerItems.user"
          :key="`${itemUser.text}_${i}`"
          :icon="itemUser.icon"
          :text="itemUser.text"
          :to="itemUser.route"
        />
<!-- USERS -->

<!-- ADMIN -->
        <div v-if="isAdmin || isDevUser">
          <ListItemSeparator
            subtitle="Catálogos (Admin)"
            disabled
          />

          <!-- OPCIONES ADMINISTRADOR -->
          <span v-for="(itemAdmin, i) in drawerItems.admin.routes" :key="`${itemAdmin.text}_${i}`">
            <ListItem
              :icon="itemAdmin.icon"
              :text="itemAdmin.text"
              :subtext="itemAdmin.subtext || ''"
              :flipIconX="itemAdmin.flipIconX"
              :to="itemAdmin.route"
            />
          </span>

          <!-- OPCIONES REPORTES/CONSULTAS -->
          <ListItemSeparator
            subtitle="Consultas (Admin)"
            disabled
          />
          <span v-for="(itemAdmin, i) in drawerItems.admin.informes" :key="`${itemAdmin.text}_${i}`">
            <ListItem
              :icon="itemAdmin.icon"
              :text="itemAdmin.text"
              :subtext="itemAdmin.subtext"
              :to="itemAdmin.route"
            />
          </span>
          
          <!-- <Accordion
            title="Informes"
            prepend-icon="mdi-text-box-search"
            :open="true || informeRoutesActive"
            :dataSource="drawerItems.admin.informes"
          /> -->
        </div>
<!-- ADMIN -->

<!-- OPCIONES CONFIGURACION -->
        <!-- <v-divider class="mb-3"></v-divider> -->
        <ListItemSeparator
          subtitle="Configuración"
          disabled
        />
        <ListItem
          icon="mdi-settings"
          text="Cuenta"
          to="/cuenta_usuario"
        />
        <ListItem
          v-if="isAdmin"
          icon="mdi-tune"
          text="Admin"
          to="/acciones_admin"
        />
        <ListItem
          v-if="isDevUser"
          icon="mdi-database-search"
          text="Consulta BD"
          to="/reporte_bd"
        />

        <ListItem
          icon="mdi-logout"
          text="Salir"
          @click="logout"
        />  
<!-- OPCIONES CONFIGURACION -->

      </v-list-item-group>
    </v-list>
    
  </v-navigation-drawer>
</template>

<script>
import { ref, watch, computed, getCurrentInstance } from 'vue';
import { logout } from "@/utils/Functions";
// import Accordion from '../components/ui/Accordion';
import ListItem from '../components/ui/ListItem';
import ListItemSeparator from '../components/ui/ListItemSeparator';
import { state, getters, STORE_NAMESPACES } from "@/store";

export default {
  props: {
    drawerVal: {
      required: true,
    },
  },
  components: {
    // Accordion,
    ListItem,
    ListItemSeparator,
  },
  setup(props) {
    const vm = getCurrentInstance();
    const { APP, USERS } = STORE_NAMESPACES;

    const isAdmin   = getters[APP].getIsAdmin.value;
    const isDevUser = getters[APP].getIsDevUser.value;
    const logged_user = getters[USERS].findById(state[APP].idUser);

    const drawer = ref(null);
    const drawerItems = computed(() => ({
      user: [
        { 
          route: '/cobranza',  
          text: 'Cobranza',  
          icon: 'mdi-cash-multiple', 
        },
      ],
      admin: {
        routes: [
          { 
            route: '/clientes', 
            text: 'Clientes',   
            subtext: `${vm?.proxy?.$root?.$route?.path === `/clientes/${vm?.proxy?.$root?.$route?.params?.idCliente}/planes` ? 'consultando plan =>' : ''}`,
            icon: 'mdi-account-multiple-plus',
            flipIconX: true
          },
          { 
            route: '/users',    
            text: logged_user.value.privilegio == 2 ? 'Cobradores' : 'Usuarios',
            subtext: logged_user.value.privilegio != 2 ? 'Admins. y Cobradores' : '',
            icon: 'mdi-briefcase-plus' 
          },
          { 
            route: '/planes',   
            text: 'Planes',     
            icon: 'mdi-file-document-box-plus' 
          },
        ],
        informes: [
          { 
            route: '/consulta_plan_pagos',  
            text: 'Planes de Pago',  
            icon: 'mdi-book-open-page-variant' 
          },
          { 
            route: '/reporte_contratos',  
            text: 'Vigencia Contratos',  
            icon: 'mdi-calendar-clock' 
          },
          { 
            route: '/reporte_corte',  
            text: 'Ver Corte del Día',  
            icon: 'mdi-calendar-today' 
          },
          { 
            route: '/reporte_saldos',  
            text: 'Saldos',  
            subtext: '(acreedores / deudores)',  
            icon: 'mdi-book-search' 
          },
        ],
      },
    }));

    // const informeRoutesActive = drawerItems.value.admin.informes
    //   .map(informe => informe.route)
    //   .includes(vm.proxy.$root.$route.path);

    /* Se requiere reaccionar al cambio de este prop, pero como no se hace referencia en el template
    ésta es la única manera de detectar el cambio (o utilizando una computed property, pero en este
    caso no vale para el v-model al que se aplicará pues esta ultima solo funciona como getter y v-model
    combina un getter y setter) */
    watch(() => props.drawerVal, () => {
      drawer.value = !drawer.value;
    });

    return {
      drawer,
      drawerItems,
      // informeRoutesActive,
      logout,

      isAdmin,
      isDevUser,
    }
  }
};
</script>

