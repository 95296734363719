<template>
  <v-btn 
    v-bind="$attrs"
    v-on="$listeners"
    :small="$attrs.fab"
    :disabled="loading || $attrs['disabled']"
    :loading="loading"
  >
    <v-icon v-if="$attrs.fab"> mdi-plus</v-icon>
    <slot v-else />

    <template v-if="customLoading" template v-slot:loader>
      <span>Procesando...</span>
    </template>
  </v-btn>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    customLoading: {
      type: Boolean,
      default: false,
    }
  },
}
</script>
