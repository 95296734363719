<template>  
  <v-container>
    <v-card elevation="0" class="d-flex mb-2 justify-center" style="border-radius: 24px 24px 8px 8px;;">
      <v-card-title 
        class="pa-4 px-6 grey--text text--darken-3 font-weight-bold text-uppercase" 
        :style="($vuetify.breakpoint.xs ? 'font-size: 18px;' : 'font-size: 20px;')"
      >
        Cobranza
      </v-card-title>
    </v-card>
    <!-- <div v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" class="text-center title mb-2">
      {{ `Cobranza` }}
    </div> -->

    <v-alert
      v-if="clientesActivos.length"
      cols="12" sm="12" md="12" 
      elevation="0"
      class="mb-2"
      border="left"
      colored-border
      color="blue-grey"
      :value="dismissCobranzaAlert"
      @input="() => {}"
    >
      <div class="d-flex grey--text text--darken-2 align-center justify-space-between">
        <!-- <v-icon class="mr-4" color="primary">mdi-information</v-icon> -->
        <div class="pl-2">
          Esta vista mostrará a los clientes que tengan sus próximos pagos para el 
          día de hoy (la fecha actual), también mostrará los que estén atrasados si es que existen.
          Si se quita el filtro de fecha se mostraran todos los clientes.
        </div>
        <v-btn @click="handleDismissCobranzaAlertOnClose" color="default" class="d-flex ml-4 font-weight-bold" style="color: #616161;">
          Quitar nota
        </v-btn>
      </div>
    </v-alert>

    <Jumbotron 
      v-if="clientesActivos.length == 0 && !tableLoading"
      btnText="Agregar cliente"
      redirect="clientes"
    >
      Se necesita agregar o dar de alta al menos un Cliente.
    </Jumbotron>

    <!-- REVIEW: De momento no mostrar este Jumbotron -->
    <!-- <Jumbotron 
      v-else-if="cobranzaPagoTable.items.length == 0 && !tableLoading"
      btnText="Ver clientes"
      redirect="clientes"
    >
      Se necesita agregar o dar de alta al menos un Cliente y tener al menos un Plan de Cliente activo.
    </Jumbotron> -->

<!-- FILTROS COBRANZA -->
    <v-card v-if="clientesActivos.length" class="mt-5 mb-5" elevation="1">
      <v-row class="v-list-item">

        <v-col 
          v-if="true /*logged_user.privilegio != 3*/"
          class="py-0 pt-3" cols="12" sm="6" md="4"
        > 
          <v-menu
            ref="dateRangeMenu"
            v-model="filter_datePagoRange.dateOpen"
            :close-on-content-click="false"
            class="my-0 "
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <VTextField
                :value="filter_datePagoRange.formattedDate"
                :label="`Fecha`"
                :hint="
                  filter_datePagoRange.dateText
                    ? `${$dayjs(filter_datePagoRange.dateText).format('dddd, DD [de] MMMM [del] YYYY')}`
                    : filter_datePagoRange.dateText
                "
                class="mt-0"
                prepend-inner-icon="mdi-calendar"
                persistent-hint
                @click:clear="handleClearFechaRango"
                clearable
                readonly
                v-on="on"
              ></VTextField>
            </template>
            <v-date-picker 
              v-model="filter_datePagoRange.dateText" 
              @change="filter_datePagoRange.dateOpen = false"
              no-title 
              scrollable>
              <!-- <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.dateRangeMenu.save(handleDatePagoFilterRange(filter_datePagoRange.dateText))">OK</v-btn> -->
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col 
          v-if="logged_user.privilegio != 3"
          class="py-0 pt-3" cols="12" sm="6" md="4"
        > 
          <v-autocomplete
            v-model="filter_cobrador"
            class="my-0"
            :label="`Cobrador`"
            :items="cobradoresSelectFilter"
            clearable
          ></v-autocomplete>
        </v-col> 

        <v-col 
          class="py-0 pt-3" 
          cols="12" sm="6" md="4"
        > 
          <v-autocomplete
            v-model="filter_localidad"
            class="my-0"
            :label="`Localidad`"
            :items="localidadesSelectFilter"
            clearable
          ></v-autocomplete>
        </v-col> 

        <v-col 
          class="py-0 pt-3" 
          cols="12" sm="6" md="4"
        > 
          <v-select
            v-model="filter_turno"
            class="my-0"
            :label="`Turno`"
            :items="turnoSelectFilter"
            clearable
          ></v-select>
        </v-col> 

        <v-col 
          class="py-0 pt-5" 
          cols="12" sm="6"  md="4"
        > 
          <v-checkbox
            v-model="filter_domiciliado"
            color="primary"
            class="my-0"
            :style="{ maxWidth: 'max-content' }"
            :label="`Domiciliados`"
            :hint="''"
            persistent-hint
          ></v-checkbox>
        </v-col>

        <!-- <v-col 
          class="py-0 pt-5" 
          cols="12" sm="6"  md="4"
        > 
          <v-checkbox v-if="false"
            :value="check_atrasados"
            @change="handleAtrasadosCheck"
            color="primary"
            class="my-0"
            :style="{ maxWidth: 'max-content' }"
            :label="`Fecha de pago anterior a la fecha actual (${$dayjs().format('DD/MM/YYYY')})`"
            :hint="''"
            persistent-hint
          ></v-checkbox>
        </v-col> -->

      </v-row>
    </v-card>
<!-- FILTROS COBRANZA -->

    <DataTable
      v-if="clientesActivos.length"
      id="cobranza-table"
      :title="`Cobranza`"
      :subtitle="`Pagos/Abonos de clientes`"
      :searchLabel="'Filtro general'"
      :headers="headers"
      :items="cobranzaPagoTable.items"
      :loading="tableLoading"
      :searchEnabled="true"
      :showCobroAction="true"
      :showUpdateTableOption="true"
      :showEditarAction="false"
      :showDeleteAction="false"
      :forceItemsPerPage="20"
      @onCobroRow="handleAplicarAbonoDirecto"
      @onRowClick="cobranzaPagoTable.editItem"
      @onUpdateTable="handleTableUpdate"
    >
      <template slot="toolbarActions">

        <v-form v-if="cobranzaPagoTable.dialog" ref="form" v-model="validFormCobranza">
          <Dialog
            useToolbarStyle
            v-model="cobranzaPagoTable.dialog"
            :title="`${(!$vuetify.breakpoint.xs) ? truncateStr('Cliente: ' + cliente.nombre + ' ' + cliente.apellidos, 50) : ''}`"
            :subtitle="`
              ${(!$vuetify.breakpoint.xs) ? 'Fecha actual: ' + $dayjs().format('ddd DD/MMM/YYYY') : ''}
            `"
            :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            :disableCloseIcon="fullPageLoading"
            :persistent="fullPageLoading"
            @onCloseToolbarBtn="cobranzaPagoTable.dialog = false"
          >
            <template slot="content">
              <v-row>
                <v-col cols="12" class="pt-2">
                  <v-list-item-title>
                    <div class="grey--text text--darken-3">
                      <span class="">
                        <span class="font-weight-bold">
                          {{ plazoToString(cobranzaPagoTable.editedItem.forma_pago).capitalized }}: 
                        </span>
                        <span :class="[{
                            'green--text text--darken-3': 
                            (+cobranzaPagoTable.editedItem.trimestre_actual.amortizacion == calcAmortizaciones(reactive_forma_pago, cobranzaPagoTable.editedItem.anios_aseguradora)) 
                              && (+pago_actual >= +num_pagos_al_trimestre)
                          }]"
                        >
                          {{ cobranzaPagoTable.editedItem.trimestre_actual.amortizacion }}/{{ calcAmortizaciones(reactive_forma_pago, cobranzaPagoTable.editedItem.anios_aseguradora) }}
                        </span>
                      </span>
                      <!-- <span>
                        {{` | ${cobranzaPagoTable.editedItem.forma_pago}`}}
                      </span> -->
                      <br />

                      <div class="">
                        <span class="font-weight-bold">
                          Abono:
                        </span>
                        <span :class="[{'green--text text--darken-3': +pago_actual >= +num_pagos_al_trimestre}]">
                          {{ `${pago_actual}/${num_pagos_al_trimestre}` }}
                        </span>
                      </div>

                      <span v-if="!cobranzaPagoTable.editedItem.trimestre_actual.trimestre_iniciado">
                        <!-- (primer abono del trimestre) -->
                      </span>
<!-- VER TABLA DE PAGOS REALIZADOS SI SE HA INICIADO EL TRIMESTRE -->
                      <!-- <span v-if="existenAbonosTrimActual && cobranzaPagoTable.dialog">
                        (<Dialog
                          useToolbarStyle
                          overlay-opacity="0.7"
                          linkText="clic para ver abonos registrados..."
                          v-model="dialogPagos"
                          max-width="1024px"
                          :title="`Abonos registrados`"
                          :subtitle="`${truncateStr(cliente.nombre + ' ' + cliente.apellidos, 50)}`"
                          :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                          @onCloseToolbarBtn="dialogPagos = false"
                        >
                          <template slot="content">
                            <div v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" class="text-center title">
                              {{ `${plazoToString(cobranzaPagoTable.editedItem.forma_pago).capitalized} actual: ${cobranzaPagoTable.editedItem.trimestre_actual.amortizacion}` }}
                            </div>
                            <div v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" class="text-center subtitle mb-2">
                              {{ `Pago periódico: $${currency(cobranzaPagoTable.editedItem.trimestre_actual.pago_periodico_calculado)}` }}
                            </div>

                            <DataTable
                              id="abonos-table"
                              class="mb-4"
                              :title="`${plazoToString(cobranzaPagoTable.editedItem.forma_pago).capitalized} actual: ${cobranzaPagoTable.editedItem.trimestre_actual.amortizacion}`"
                              :subtitle="`Pago periódico: $${currency(cobranzaPagoTable.editedItem.trimestre_actual.pago_periodico_calculado)}`"
                              :headers="headersPago"
                              :items="cobranzaPagoTable.editedItem.trimestre_actual.abonos"
                              :forceItemsPerPage="20"
                              :loading="tableLoading"
                              :searchEnabled="true"
                              :sortBy="['id']"
                              :sortDesc="[false, true]"
                              :showToolbar="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
                            />
                          </template>
                        </Dialog>) -->

                        <div v-if="!$vuetify.breakpoint.xs" class="my-2"></div>
                    </div>
                  </v-list-item-title>

                  <v-divider v-if="$vuetify.breakpoint.xs" class="my-2"></v-divider>

                  <div>
                    <div>
                      {{ `${formatCurrency(cobranzaPagoTable.editedItem.trimestre_actual.pago_moneda_contratada)} (${cobranzaPagoTable.editedItem.moneda}) | ${formatCurrency(reactive_tipo_cambio, {moneda: cobranzaPagoTable.editedItem.moneda})} (tipo de cambio)` }}
                    </div>
                    <div v-if="$vuetify.breakpoint.xs">
                      <span class="font-weight-bold">Cliente: </span>
                      {{ cliente.nombre + ' ' + cliente.apellidos }}
                    </div>
                    <div>
                      <span class="font-weight-bold">Núm. cliente: </span>
                      {{ `${cliente.num_cliente}` }}
                    </div>
                    <div>
                      <span class="font-weight-bold">Teléfono: </span>
                      {{ `${cliente.tel || 'No registrado'}` }}
                    </div>
                    <div>
                      <span class="font-weight-bold">Domicilio de cobro: </span>
                      {{ `${cliente.domicilio_de_cobro || 'No registrado'}` }}
                    </div>
                    <div v-if="$vuetify.breakpoint.xs">
                      <span class="font-weight-bold">Fecha actual: </span>
                      {{ `${$dayjs().format('dddd, DD [de] MMM. [del] YYYY')}` }}
                    </div>
                    <div>
                      <span class="font-weight-bold">Póliza: </span>
                      {{ `${cobranzaPagoTable.editedItem.poliza} | 
                      ${cobranzaPagoTable.editedItem.forma_pago}` }} 
                      {{ cobranzaPagoTable.editedItem.dia_pago && `(${formatDiaPago(cobranzaPagoTable.editedItem.dia_pago)})` }}
                      {{ cobranzaPagoTable.editedItem.forma_pago == FORMA_PAGO.MENSUAL
                          ? cobranzaPagoTable.editedItem.num_dia_pago > 0 
                            ? `(cada día ${formatDiaPago(cobranzaPagoTable.editedItem.num_dia_pago)})`
                            : `(cada fin de mes)`
                          : ''  
                      }}
                    </div>
                  </div>

                </v-col>
              </v-row>

              <v-divider class="mt-2 mb-6"></v-divider>

              <v-row>
                <v-col v-if="false" cols="12" sm="6" md="4">
                  <VTextField
                    :label="`Pago moneda contratada (${cobranzaPagoTable.editedItem.moneda})`"
                    :value="cobranzaPagoTable.editedItem.trimestre_actual.pago_moneda_contratada"
                    :disabled="true"
                  ></VTextField>
                </v-col>

                <!-- v-if="
                    !cobranzaPagoTable.editedItem.trimestre_actual.trimestre_iniciado 
                    && !aplazar_dia_sig_check
                    && !esFormaPagoNoTrimestral(cobranzaPagoTable.editedItem.forma_pago)
                  "  -->
                <v-col 
                  v-if="false"
                  cols="12" sm="6" 
                  :md="4/*reactive_forma_pago == FORMA_PAGO.SEMANAL ? 3 : 4*/"
                >
                  <div style="display: flex; flex: row nowrap;">
                    <v-checkbox
                      v-if="false"
                      v-model="enable_forma_pago"
                      color="primary"
                    ></v-checkbox>
                    <v-select
                      label="Forma de pago"
                      :value="reactive_forma_pago"
                      @change="handleFormaPago"
                      :hint="''"
                      :items="FORMA_PAGO_SELECT"
                      :disabled="false /*enable_forma_pago == false*/"
                      persistent-hint
                    ></v-select>
                  </div>
                </v-col> 

                <!-- v-if="(!cobranzaPagoTable.editedItem.trimestre_actual.trimestre_iniciado && reactive_forma_pago == FORMA_PAGO.SEMANAL) && !aplazar_dia_sig_check"  -->
                <v-col 
                  v-if="false"
                  cols="12" sm="6" md="4"
                >
                  <div style="display: flex; flex: row nowrap;">
                    <v-checkbox
                      v-if="false /*dia_pago*/"
                      v-model="enable_dia_pago"
                      color="primary"
                    ></v-checkbox>
                    <v-select
                      label="Día de pago"
                      v-model="dia_pago"
                      :hint="''"
                      :items="PLAN_DATA.DIAS_SEMANA_SELECT"
                      :disabled="enable_dia_pago == false
                        || (reactive_forma_pago != FORMA_PAGO.SEMANAL 
                            && aplazar_dia_sig_check)"
                      persistent-hint
                    ></v-select>
                  </div>
                </v-col> 

                <v-col cols="12" class="pa-0"> 
                </v-col>

                <!-- v-if="!cobranzaPagoTable.editedItem.trimestre_actual.trimestre_iniciado && !aplazar_dia_sig_check"  -->
                <v-col 
                  v-if="false"
                  cols="12" sm="6"
                  :md="reactive_forma_pago == FORMA_PAGO.SEMANAL ? 3 : 4"
                > 
                  <div style="display: flex; flex: row nowrap;">
                    <v-checkbox
                      v-model="enable_pago_periodico"
                      color="primary"
                    ></v-checkbox>
                    <VCurrencyField
                      label="Pago periódico (MN)"
                      prefix="$"
                      :value="pago_periodico"
                      @input="handlePagoPeriodico"
                      :hint="''"
                      :disabled="enable_pago_periodico == false"
                      persistent-hint
                    ></VCurrencyField>
                  </div>
                </v-col>

                <!-- v-if="!cobranzaPagoTable.editedItem.trimestre_actual.trimestre_iniciado && !aplazar_dia_sig_check"  -->
                <v-col 
                  v-if="false" 
                  cols="12"
                > 
                  <v-divider></v-divider>
                </v-col>

                <v-col 
                  v-if="!aplazar_dia_sig_check && logged_user.privilegio != 3" 
                  cols="12" sm="6" md="8"
                  class="pb-0"
                >
                  <v-select
                    label="Usuario/Cobrador"
                    v-model="cobradorSelected"
                    :hint="''"
                    :disabled="logged_user.privilegio == 3"
                    :items="cobradoresSelect"
                    persistent-hint
                  ></v-select>
                </v-col>

                <v-col cols="12" class="pa-0"> 
                </v-col>

                <!-- :md="reactive_forma_pago == FORMA_PAGO.SEMANAL ? 3 : 4" -->
                <v-col 
                  v-if="false /*(cobranzaPagoTable.editedItem.moneda && cobranzaPagoTable.editedItem.moneda != MONEDAS.MXN && !cobranzaPagoTable.editedItem.trimestre_actual.trimestre_iniciado) && !aplazar_dia_sig_check*/" 
                  cols="12" sm="6" md="4"
                >
                  <VTextField
                    :label="`Tipo de cambio ${cobranzaPagoTable.editedItem.moneda || ''} a MN`"
                    prefix="$"
                    :value="reactive_tipo_cambio"
                    @input="handleTipoCambio"
                    :rules="[FormValidations.required, FormValidations.onlyRealNumber, FormValidations.nonZero]"
                    :hint="``"
                    :maxlength="tipoCambioMaxLength(cobranzaPagoTable.editedItem.moneda, reactive_tipo_cambio+'')"
                    :disabled="cobranzaPagoTable.editedItem.trimestre_actual.trimestre_iniciado"
                    persistent-hint
                  ></VTextField>
                </v-col>

                <v-col v-if="!aplazar_dia_sig_check && !utilizar_saldo_a_favor_check" cols="12" sm="6" md="4"> 
                  <VCurrencyField
                    v-model="cantidad"
                    label="Cantidad a abonar (MN)"
                    prefix="$"
                    placeholder=" "
                    :hint="`Pago periódico: $${pago_periodico}`"
                    persistent-hint
                  ></VCurrencyField>
                </v-col>

                <!-- <v-col cols="12" sm="6" md="4"> 
                  <VCurrencyField
                    label="Saldo pendiente"
                    prefix="$"
                    :value="currency(+pago_periodico - +cantidad)"
                  ></VCurrencyField>
                </v-col>  -->

                <v-col v-if="!aplazar_dia_sig_check" cols="12" sm="6" md="4"> 
                  <VTextField
                    :label="`Saldo restante (total ${plazoToString(cobranzaPagoTable.editedItem.forma_pago).lower})`"
                    prefix="$"
                    :value="`${formatCurrency(
                      currency(saldo_trimestre) == 0 
                        ? 0 
                        : currency(saldo_trimestre)
                      , {prefix: ''})}
                    `"
                    :hint="`
                      ${formatCurrency(currency(+reactive_tipo_cambio * +cobranzaPagoTable.editedItem.trimestre_actual.pago_moneda_contratada), {prefix: ''})} (al ${plazoToString(cobranzaPagoTable.editedItem.forma_pago).lower})
                      ${(+reactive_saldo_pendiente >= 0 ? '+' : '')}
                      ${formatCurrency(+reactive_saldo_pendiente, {prefix: ''})} (saldo pendiente)
                      - ${formatCurrency(+cobranzaPagoTable.editedItem.trimestre_actual.abonado, {prefix: ''})} (abonados)
                      - ${formatCurrency(+cantidad, {prefix: ''})} (cant. abono actual)
                    `"
                    persistent-hint
                    disabled
                  ></VTextField>
                </v-col> 

                <!-- v-if="!aplazar_dia_sig_check && !utilizar_saldo_a_favor_check" -->
                <v-col v-if="false" cols="12" sm="6" md="4"> 
                  <VTextField
                    label="Total abonado"
                    prefix="$"
                    :value="`${formatCurrency(total_abonado, {prefix: ''})}`"
                    :hint="`
                      ${formatCurrency(cobranzaPagoTable.editedItem.total_abonado, {prefix: ''})} (total abonado)
                      + ${formatCurrency(+cantidad, {prefix: ''})} (cant. abono actual)
                    `"
                    persistent-hint
                    disabled
                  ></VTextField>
                </v-col>

                <v-col cols="12" sm="12" md="12"
                  v-if="!aplazar_dia_sig_check"
                  :class="utilizar_saldo_a_favor_check
                    ? 'py-0' 
                    : 'py-0 mb-n6'"
                > 
                  <v-checkbox
                    v-model="utilizar_saldo_a_favor_check"
                    color="primary"
                    class="my-2"
                    :style="{ maxWidth: 'max-content' }"
                    :label="`Utilizar saldo a favor
                      ${!utilizar_saldo_a_favor_check ? `(${formatCurrency(Math.abs(+cobranzaPagoTable.editedItem.saldo_a_favor))})` : ''} 
                    `"
                    :hint="utilizar_saldo_a_favor_check 
                      ? `Saldo a favor utilizado para cubrir el pago periódico:
                        ${formatCurrency(Math.abs(+cobranzaPagoTable.editedItem.saldo_a_favor, {prefix: ''}), {prefix: ''})} (saldo a favor)
                        - ${formatCurrency(+cobranzaPagoTable.editedItem.pago_periodico_ajuste, {prefix: ''})} (pendientes)
                        = ${formatCurrency(Math.abs(+cobranzaPagoTable.editedItem.saldo_a_favor + +cobranzaPagoTable.editedItem.pago_periodico_ajuste), {prefix: ''})}
                        (saldo a favor restante)
                      ` 
                      : ` `"
                    :disabled="(+cobranzaPagoTable.editedItem.pago_periodico_ajuste > Math.abs(+cobranzaPagoTable.editedItem.saldo_a_favor)) || currency(saldo_trimestre_incluyendo_cantidad) < +cobranzaPagoTable.editedItem.pago_periodico_ajuste"
                    persistent-hint
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="12" md="12" 
                  v-if="reactive_forma_pago != FORMA_PAGO.DIARIO && !utilizar_saldo_a_favor_check"
                  :class="diasAplazados >= diasAplazadosMaxPermitidos
                    ? 'py-0'
                    : aplazar_dia_sig_check 
                    ? 'py-0' 
                    : 'py-0 mb-n6'"
                > 
                  <v-checkbox
                    v-model="aplazar_dia_sig_check"
                    color="primary"
                    class="my-2"
                    :style="{ maxWidth: 'max-content' }"
                    :label="`Aplazar abono al día siguiente`"
                    :disabled="diasAplazados >= diasAplazadosMaxPermitidos && logged_user.privilegio > 1"
                    :hint="diasAplazados >= diasAplazadosMaxPermitidos
                      ? `Sólo se puede aplazar un pago un máximo de ${diasAplazadosMaxPermitidos} dias (${cobranzaPagoTable.editedItem.forma_pago})`
                      : aplazar_dia_sig_check 
                      ? `Al aplazarse el día sólo se cambiará la fecha y no afectarán las cantidades ni se acumulará el abono, sólo podrá aplazarse un máximo de ${diasAplazadosMaxPermitidos} días (${(diasAplazados + 1)}/${diasAplazadosMaxPermitidos})` 
                      : ''
                    "
                    persistent-hint
                  ></v-checkbox>
                </v-col>

                <v-col v-if="false" cols="12" sm="6" md="4"> 
                  <v-menu
                    v-model="datePago.dateOpen"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <VTextField
                        :value="datePago.formattedDate"
                        :hint="$dayjs(datePago.dateText).format('dddd, DD [de] MMM. [del] YYYY')"
                        :label="`Fecha del abono`"
                        prepend-inner-icon="mdi-calendar"
                        persistent-hint
                        disabled
                        v-on="on"
                      ></VTextField>
                    </template>
                    <v-date-picker v-model="datePago.dateText" @change="datePago.dateOpen = false" no-title scrollable>
                    </v-date-picker>
                  </v-menu>
                </v-col> 

                <v-col v-if="aplazar_dia_sig_check /*&& logged_user.privilegio <= 1*/" cols="12" sm="6" md="6"> 
                  <v-menu
                    v-model="datePagoAdminFechaAplazada.dateOpen"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <VTextField
                        :value="datePagoAdminFechaAplazada.formattedDate"
                        :hint="datePagoAdminFechaAplazada.dateText && $dayjs(datePagoAdminFechaAplazada.dateText).format('dddd, DD [de] MMM. [del] YYYY')"
                        :label="'O bien, se puede elegir la fecha'"
                        prepend-inner-icon="mdi-calendar"
                        persistent-hint
                        @click:clear="handleClearFechaPagoAplazadaAdmin"
                        clearable
                        v-on="on"
                      ></VTextField>
                    </template>

                    <v-date-picker 
                      v-model="datePagoAdminFechaAplazada.dateText" 
                      @change="datePagoAdminFechaAplazada.dateOpen = false" 
                      :allowed-dates="allowedDatesAplazarAbono"
                      :show-current="fechaAbono"
                      no-title 
                      scrollable>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col v-if="false" cols="12" sm="6" md="4"> 
                  <VTextField
                    :value="fechaProxPago && $dayjs(fechaProxPago).format('DD/MM/YYYY')"
                    :hint="fechaProxPago && $dayjs(fechaProxPago).format('dddd, DD [de] MMM. [del] YYYY')"
                    placeholder="- - -"
                    label="Fecha próxima de pago"
                    prepend-inner-icon="mdi-calendar"
                    persistent-hint
                    disabled
                  ></VTextField>
                </v-col>

              </v-row>

              <v-divider class="mt-6 mb-4"></v-divider>

              <v-row>
                <v-col cols="12" sm="12" md="12"> 
                  <div style="font-size: 16px;" :class="[{'grey--text text--lighten-1': datePagoAdminFechaAplazada.dateText} ,'']">
                    <b>
                      Fecha {{ `${aplazar_dia_sig_check ? 'del próximo pago (día sig.)' : 'de este pago'}` }}: 
                    </b>
                    {{ capitalizeFirst($dayjs(fechaAbono).format('dddd, DD [de] MMMM [del] YYYY')) }}
                  </div>

                  <div v-if="aplazar_dia_sig_check && datePagoAdminFechaAplazada.dateText" style="font-size: 16px;"  class="my-1 mt-2">
                    <b>
                      Fecha del próximo pago (elegida): 
                    </b>
                    {{ capitalizeFirst($dayjs(datePagoAdminFechaAplazada.dateText).format('dddd, DD [de] MMMM [del] YYYY')) }}
                  </div>

                  <div style="font-size: 16px;" class="my-1 mt-2" v-if="!aplazar_dia_sig_check && (pago_actual < num_pagos_al_trimestre || +cobranzaPagoTable.editedItem.trimestre_actual.amortizacion != calcAmortizaciones(reactive_forma_pago, +cobranzaPagoTable.editedItem.anios_aseguradora))">
                    <b>
                      Fecha del próximo pago: 
                    </b>
                    {{ fechaProxPago ? capitalizeFirst($dayjs(fechaProxPago).format('dddd, DD [de] MMMM [del] YYYY')) : '- - -' }}
                  </div>
                </v-col>
              </v-row>

              <v-divider v-if="!aplazar_dia_sig_check" class="mt-3 mb-4"></v-divider>

              <v-row>
                <v-col v-show="!aplazar_dia_sig_check && IS_ADMIN" cols="12" sm="12" md="12" class="pt-4 pb-0"> 
                  <v-switch
                    v-model="cerrar_trimestre_check"
                    color="green darken-3"
                    class="font-weight-bold mb-6"
                    :prepend-icon="`${cerrar_trimestre_check ? 'mdi-calendar-check' : 'mdi-calendar'}`"
                    :label="`Cerrar el ${plazoToString(cobranzaPagoTable.editedItem.forma_pago).lower} ${(+cobranzaPagoTable.editedItem.trimestre_actual.amortizacion == calcAmortizaciones(reactive_forma_pago, cobranzaPagoTable.editedItem.anios_aseguradora)) ? 'final' : 'actual'} (${cobranzaPagoTable.editedItem.trimestre_actual.amortizacion})`"
                    :hint="`${
                      cerrar_trimestre_check 
                        ? `El ${plazoToString(cobranzaPagoTable.editedItem.forma_pago).lower} actual quedará cerrado al aplicar el abono.`
                        : ` ‎`
                    }
                    ${
                      cerrar_trimestre_check && +cobranzaPagoTable.editedItem.trimestre_actual.amortizacion == calcAmortizaciones(reactive_forma_pago, cobranzaPagoTable.editedItem.anios_aseguradora) 
                        ? `Al ser el último abono del último ${plazoToString(cobranzaPagoTable.editedItem.forma_pago).lower}, el plan del cliente se marcará como completado y no se mostrará en este apartado de Cobranza.` 
                        : ` ‎`
                    }`"
                    :readonly="disable_cerrar_trimestre_check"
                    persistent-hint
                  ></v-switch>
                </v-col>
              </v-row>

            </template>
            
            <template slot="actions">
              <!-- <Button text @click="cobranzaPagoTable.dialog = false">Cancelar</Button> -->

              <Button 
                text 
                :disabled="!validFormCobranza || cobranzaPagoTable.disableActionBtn || fullPageLoading"
                :loading="tableLoading"
                @click="handleAplicarAbonoOnClick"
              >
                {{ `${aplazar_dia_sig_check ? 'Aplazar' : 'Aplicar'} abono` }}
              </Button>
            </template>
          </Dialog> 
        </v-form>
      </template>
    </DataTable>

    <vue-element-loading 
      :active="fullPageLoading" 
      :text="vueElementLoadingText" 
      :text-style="{ color: '#333', fontSize: '20px' }" 
      :is-full-screen="true"
    />
    
  </v-container>
</template>

<script>
import Button from "@/components/ui/Button";
import Dialog from "@/components/ui/Dialog";
import DataTable from "@/components/ui/DataTable";
// import Jumbotron from "@/components/ui/Jumbotron";
import VCurrencyField from "@/components/ui/VCurrencyField";
import useDataTable from "@/composables/useDataTable";
import useDatepicker from "@/composables/useDatepicker";
import useDatepickerRange from "@/composables/useDatepickerRange";
import { 
  truncateStr, 
  // calcularPagoPeriodicoCobranza, 
  // calcularPlazo,
  dateProxPago, 
  calcularNumPagosAlTrimestre, 
  capitalize, 
  capitalizeFirst,
  currency, 
  formatCurrency, 
  formatDiaPago,
  // parseDate, 
  dateUtils, 
  esFormaPagoNoTrimestral, 
  plazoToString,
  calcAmortizaciones,
  setStorageItem, 
  getStorageItem,
  tipoCambioMaxLength,
  activateFullPageLoading,
  updateFullPageLoadingText,
  disableFullPageLoading,
} from "@/utils/Functions";
import { APP_DEBUG_MODE as APP_DEBUG, TURNO_PAGO_SELECT, PLAN_DATA, MONEDAS, FORMA_PAGO, DIAS_SEMANA } from "@/utils/Constants";
import FormValidations from "@/utils/FormValidations";
import { state, actions, getters, STORE_NAMESPACES } from "@/store";
import { getCurrentInstance, ref, watch, computed, onBeforeUnmount } from "vue";

export default {
  components: {
    Button,
    Dialog,
    DataTable,
    VCurrencyField,
    // Jumbotron,
  },
  setup(props) {
    const vm = getCurrentInstance();
    const { APP, USERS, CLIENTES, CLIENTE_PLAN_Y_PAGOS, PLANES } = STORE_NAMESPACES;
        
    const IS_DEV_USER = getters[APP].getIsDevUser.value;
    const IS_ADMIN = getters[APP].getIsAdmin.value;
    const APP_DEBUG_MODE = APP_DEBUG || IS_DEV_USER;

    const logged_user = getters[USERS].findById(state[APP].idUser);

    onBeforeUnmount(() => {
      if (logged_user.value.privilegio != 3) {
        APP_DEBUG_MODE && console.log("[Cobranza]: ClientePlanYPagos unmount => SET_DEFAULT()");
        actions[CLIENTES].SET_DEFAULT_PLAN_Y_PAGOS();
      }
    });

    const headers = [
      { text: "Núm. cliente", value: "clientes.num_cliente", align: "left", sortable: true },
      { text: "Nombre", value: "nombre_completo_cliente" },
      // { text: "Apellidos", value: "clientes.apellidos" },
      { text: "Tel.", value: "clientes.tel" },
      { text: "Domicilio de cobro", value: "clientes.domicilio_de_cobro" },
      { text: "Forma de pago", value: "forma_pago" },
      { text: "Fecha de pago", value: "fecha_prox_pago" },
      { text: "Pendientes (MN)", value: "pago_periodico_ajuste" },
      { text: "Aplicar abono directo", value: "action", sortable: false },
      // { text: "Pago periodico", value: "pago_periodico_calculado" },
    ];
    // const headersPago = [
    //   { text: "Id", value: "id_abono", sortable: false },
    //   { text: "Forma de pago", value: "plan_forma_pago" },
    //   { text: "Cantidad abonada", value: "cantidad" },
    //   { text: "Saldo en contra", value: "saldo_en_contra" },
    //   { text: "Fecha", value: "fecha_abono" },
    //   { text: "Cobro realizado por", value: "nombre_completo_cobrador" },
    //   { text: "Estatus", value: "abono_estatus" },
    // ];

    const turnoSelectFilter = TURNO_PAGO_SELECT;

    const defaultValuesOnOpen = {};

    const fullPageLoading = ref(false);
    const vueElementLoadingText = ref("Procesando...");

    const esAbonoDirecto = ref(false);
    const procesandoAbono = ref(false);
    
    const datePago = useDatepicker(0, "", true); // La fecha de pago que le toca al abono
    const datePagoAdminFechaAplazada = useDatepicker(0, "");
    const filter_datePagoRange = useDatepicker(
      0, "", true
      // vm.proxy.$root.$dayjs().startOf("month").format("YYYY-MM-DD"), 
      // vm.proxy.$root.$dayjs().endOf("month").format("YYYY-MM-DD"),
    );

    if (!state[CLIENTE_PLAN_Y_PAGOS].length) {
      actions[CLIENTES].FETCH_WITH_PAGOS(
        filter_datePagoRange.dateText
      );
    }

    let oldDateTextVal = filter_datePagoRange.dateText;
    const tableLoading = getters[APP].getLoading;
    const dataSource = getters[CLIENTES].getActivosWithPlanYPagos;
    const cobranzaPagoTable = useDataTable(dataSource, defaultValuesOnOpen);
    const clientesActivos = getters[CLIENTES].getActivos;
    // const existenAbonosTrimActual = computed(() => {
    //   return cobranzaPagoTable?.editedItem?.trimestre_actual?.abonos?.filter(abono => {
    //     return abono.abono_estatus == true;
    //   })?.length;
    // });

    const FORMA_PAGO_SELECT = computed(() => {
      if (!esFormaPagoNoTrimestral(cobranzaPagoTable.editedItem.forma_pago)) {
        return PLAN_DATA.FORMA_PAGO_SELECT.slice(0, 5);
      }
      return PLAN_DATA.FORMA_PAGO_SELECT;
    });

    // Filtros
    cobranzaPagoTable.items = computed(() => {
      return dataSource.value.filter(item => {
        return (!filter_cobrador.value || item.clientes.id_cobrador.includes(filter_cobrador.value))
          && (!filter_localidad.value || item.clientes?.localidad?.toLowerCase() == filter_localidad.value.toLowerCase())
          && (!filter_domiciliado.value || item.domiciliado == filter_domiciliado.value)
          && (!filter_turno.value || item.turno_pago == filter_turno.value)
          && (!filter_atrasados.value || vm.proxy.$root.$dayjs(item.fecha_prox_pago).isBefore(filter_atrasados.value, "day"))
      }) || []
    });

    let pago_periodico = ref(0);

    const pago_al_trimestre = computed(() => {
      return currency(
        // Pago en pesos
        (+reactive_tipo_cambio.value 
        * +cobranzaPagoTable.editedItem.trimestre_actual.pago_moneda_contratada)
        // Saldo pendiente
        + +reactive_saldo_pendiente.value
      );
    });

    const saldo_trimestre = computed(() => {
      return (pago_al_trimestre.value - +cobranzaPagoTable.editedItem.trimestre_actual.abonado - cantidad.value);
    });

    const saldo_trimestre_incluyendo_cantidad = ref(0);

    const total_abonado = computed(() => {
      return +cobranzaPagoTable.editedItem.total_abonado + cantidad.value;
    });

    const dismissCobranzaAlert = ref(!getStorageItem("dismiss-cobranza-alert"));

    const validFormCobranza = ref(true);
    const dialogPagos = ref(false);
    const aplazar_dia_sig_check = ref(false);
    const utilizar_saldo_a_favor_check = ref(false);
    const cerrar_trimestre_check = ref(false);
    const disable_cerrar_trimestre_check = ref(false); // no se utiliza de momento
    const plan_completado = ref(false);
    const cliente = ref('');
    const plan = ref('');
    const cantidad = ref(null);
    const dia_pago = ref(null);
    const enable_forma_pago = ref(false);
    const enable_dia_pago = ref(false);
    const enable_pago_periodico = ref(false);
    const plan_with_pagos_edited_index = ref(-1);

    const reactive_tipo_cambio = ref(0);
    const reactive_forma_pago = ref('');
    const reactive_saldo_pendiente = ref(0);

    const filter_cobrador = ref("");
    const filter_localidad = ref("");
    const filter_turno = ref("");
    const filter_domiciliado = ref(false);

    // const check_atrasados =ref(false);
    const filter_atrasados = ref("");

    const cobradores = getters[USERS].getAllCobranza;
    const cobradorSelected = ref(0);
    const cobradoresSelect = computed(() => {
      return cobradores.value
        .filter(user => user.estatus)
        .map(user => ({ text: `${user.nombre} ${user.apellidos || ''}`, value: user.id }));
    });
    
    const cobradores_filter = getters[USERS].getAllCobradoresOnly;
    const cobradoresSelectFilter = computed(() => {
      return cobradores_filter.value
        .filter(user => user.estatus)
        .map(user => ({ text: `${user.nombre} ${user.apellidos || ''}`, value: user.id }));
    });

    const estatusPlan = computed(() => {
      if (cobranzaPagoTable.isEditable) {
        const existePlan = getters[PLANES].getAll.value.find(item => item.id == plan.value.id);
        return !existePlan ? '(Eliminado)' : existePlan.estatus ? '' : '(Dado de baja)';
      }

      return "";
    });
    
    const localidadesSelectFilter = computed(() => {
      const localidades = dataSource.value
        .filter(item => {
          if (item.clientes.localidad) {
            return true;
          }
          return false;
        })
        .map(item => ({ text: `${item.clientes.localidad}`, value: item.clientes.localidad })) || [];

      /* Si existen localidades duplicadas no se incluyen ("Set" se encarga de eso automaticamente)
      y se formatea el arreglo resultante de datos al estilo usable para un select con .map */
      // NOTA: al parecer el componente v-autocomplete de vuetify ya hace que no haya valores repetidos
      // const localidades = state[CLIENTE_PLAN_Y_PAGOS].map(item => `${item.clientes.localidad}`);
      // const selectFormat = Array.from(new Set(localidades))
      //   .map(localidad => ({ text: `${localidad}`, value: localidad }))

      return localidades; // selectFormat;
    });

    const diasAplazados = computed(() => {
      const fecha_prox_pago = vm.proxy.$root.$dayjs(cobranzaPagoTable.editedItem.fecha_prox_pago);
      const fecha_prox_pago_tolerancia = vm.proxy.$root.$dayjs(cobranzaPagoTable.editedItem.fecha_prox_pago_tolerancia);
      const total_dias_diferencia = Math.abs(fecha_prox_pago.diff(fecha_prox_pago_tolerancia, "day"));

      let dias_no_habiles = 0;
      for (let index = 0; index <= total_dias_diferencia; index++) {
        if (dateUtils.esDomingo(fecha_prox_pago_tolerancia, (index))) {
          dias_no_habiles += 1;
        }
      }
      
      return total_dias_diferencia - dias_no_habiles;
    });

    const diasAplazadosMaxPermitidos = computed(() => {
      switch(cobranzaPagoTable.editedItem.forma_pago) {
        case FORMA_PAGO.SEMANAL:
          return 5;
        case FORMA_PAGO.QUINCENAL:
          return 10;
        case FORMA_PAGO.MENSUAL:
          return 20;
        case FORMA_PAGO.TRIMESTRAL:
          return 60;
        case FORMA_PAGO.SEMESTRAL:
          return 120;
        case FORMA_PAGO.ANUAL:
          return 240;
        default:
          return 5;
      }
    });

    const fechaAbono = computed(() => {
      let fecha_del_abono = vm.proxy.$root.$dayjs(datePago.dateText);

      // Cuando se aplica "Aplazar abono al dia siguiente" en Cobranza
      if (aplazar_dia_sig_check.value) {
        fecha_del_abono = vm.proxy.$root.$dayjs(fecha_del_abono).add(1, "day");

        if (dateUtils.esDomingo(fecha_del_abono)) {
          fecha_del_abono = vm.proxy.$root.$dayjs(fecha_del_abono).add(1, "day");
        }
      }

      return vm.proxy.$root.$dayjs(fecha_del_abono).format("YYYY-MM-DD");
    });

    // La proxima fecha calculada de pago dependiendo de la fecha en que tocaba pagarse el abono
    // ej. Diario: 23/oct/2020 => fechaProxPago = 24/oct/2020
    const fechaProxPago = computed(() => 
      dateProxPago(
        datePago.dateText, 
        reactive_forma_pago.value, 
        dia_pago.value,
        false,
        cobranzaPagoTable.editedItem.num_dia_pago,
      )
    );

    const pago_actual = computed(() => {
      // const abonos_activos = cobranzaPagoTable.editedItem.trimestre_actual.abonos
      //   ? cobranzaPagoTable.editedItem.trimestre_actual.abonos.filter(abono => {
      //       return abono.abono_estatus == true;
      //     })
      //   : [];

      // const abonos_registrados = abonos_activos.length
      //   ? (abonos_activos.length + 1)
      //   : 1;

      const abonos_registrados = +cobranzaPagoTable.editedItem.abonos_pagados_migracion
        ? (+cobranzaPagoTable.editedItem.abonos_pagados_migracion + 1)
        : 1;

      /* Cubre caso de migracion cuando se cuenta ya con abonos (recibido a cuenta), se comprueba 
      que no existan abonos activos para que se ejecute solo 1 vez */
      // if (+cobranzaPagoTable.editedItem.abonos_pagados_migracion > 0 && !abonos_activos.length) {
      //   const pagos_realizados = +cobranzaPagoTable.editedItem.abonos_pagados_migracion;
      //   return abonos_registrados + pagos_realizados;
      // }
      
      return abonos_registrados;
    });
    const num_pagos_al_trimestre = computed(() => {
      // Si no ha sido iniciado el trimestre, calcular el numero de pagos
      if (!cobranzaPagoTable.editedItem.trimestre_actual.trimestre_iniciado) {
        return calcularNumPagosAlTrimestre(
          reactive_forma_pago.value,
          datePago.dateText,
        );
      }

      // De lo contrario quiere decir que ya se establecieron y entonces se toman del trimestre actual
      // Antes se retornaba: +cobranzaPagoTable.editedItem.num_pagos_al_trimestre;
      return +cobranzaPagoTable.editedItem.num_pagos_al_trimestre;
      // return calcularNumPagosAlTrimestre(
      //   cobranzaPagoTable.editedItem.trimestre_actual.forma_pago,
      //   cobranzaPagoTable.editedItem.trimestre_actual.abonos.find(abono => abono.abono_estatus).fecha_abono,
      // );
    });

    function set_ClientePlanYPagosDataOnDialogOpen(callback = null) {
      actions[CLIENTES].FETCH_WITH_PAGOS(
        filter_datePagoRange.dateText,

        (res) => {
          /* Si el cliente con plan de pagos existe, actualizarlo, de lo contrario (ej. si se completó el plan), no realizar 
          las acciones siguientes, pero actualizar la tabla, avisar con un toast, quitar el page loading y cerrar el modal */
          if (res[0]) {
            cobranzaPagoTable.editItem(res[0]);
            const editedIndex = state[CLIENTE_PLAN_Y_PAGOS].findIndex(item => item.id == res[0].id);
            plan_with_pagos_edited_index.value = editedIndex;
            Object.assign(state[CLIENTE_PLAN_Y_PAGOS][plan_with_pagos_edited_index.value], res[0]);
          }
          else {
            actions[CLIENTES].FETCH_WITH_PAGOS(filter_datePagoRange.dateText, () => {
              vm.proxy.$root.$toast.info(
                `Este cliente ya tiene su plan de pagos completado. Se ha actualizado la tabla con la información más reciente.`, 
                { timeout: 5000 }
              );
              disableFullPageLoading(fullPageLoading);
              return cobranzaPagoTable.close();
            });
          }

          cliente.value = getters[CLIENTES].findById(cobranzaPagoTable.editedItem.id_cliente).value;
          plan.value = cobranzaPagoTable.editedItem.plan_catalog_data;
          datePago.dateText = vm.proxy.$root.$dayjs(cobranzaPagoTable.editedItem.fecha_prox_pago).format('YYYY-MM-DD');

          pago_periodico.value = cobranzaPagoTable.editedItem.pago_periodico_calculado;
          cantidad.value = cobranzaPagoTable.editedItem.pago_periodico_ajuste // pago_periodico.value;

          reactive_forma_pago.value = cobranzaPagoTable.editedItem.forma_pago;
          dia_pago.value = cobranzaPagoTable.editedItem.dia_pago;

          cobradorSelected.value = cobranzaPagoTable.editedItem.clientes.id_cobrador
            .find(cobId => cobId == logged_user.value.id) || cobranzaPagoTable.editedItem.clientes.id_cobrador[0];

          if (cobranzaPagoTable.editedItem.trimestre_actual.amortizacion > 1 
            && !cobranzaPagoTable.editedItem.trimestre_actual.trimestre_iniciado) 
          {
            /* REVIEW: anteriormente se tomaba el tipo de cambio del trimestre anterior, esto era porque el tipo de cambio solo se podia modificar
            al estar en el inicio de un nuevo trimestre (sin abonos), pero si se piensa cambiar el tipo de cambio de manera global o desde la
            opción de "establecer abonos", entonces debe quedar con el tipo de cambio del trimestre actual */
            reactive_tipo_cambio.value = cobranzaPagoTable.editedItem.trimestre_actual.tipo_cambio; // cobranzaPagoTable.editedItem.trimestre_anterior.tipo_cambio;
            // reactive_forma_pago.value = cobranzaPagoTable.editedItem.trimestre_anterior.forma_pago;
            reactive_saldo_pendiente.value = cobranzaPagoTable.editedItem.trimestre_anterior.saldo;

            // // Si se tiene saldo a favor o en contra, promediar el pago periodico
            // if (+reactive_saldo_pendiente.value != 0) {
            //   enable_pago_periodico.value = true;
            //   // Esta funcion debe ser identica a su contraparte del mismo nombre en la parte del backend
            //   pago_periodico.value = calcularPagoPeriodico(
            //     cobranzaPagoTable.editedItem.fecha_prox_pago,
            //     +reactive_saldo_pendiente.value,
            //   );
            // }
            // else {
              // pago_periodico.value = cobranzaPagoTable.editedItem.pago_periodico_anio;
            // }
          }
          else {
            reactive_tipo_cambio.value = cobranzaPagoTable.editedItem.trimestre_actual.tipo_cambio;
            reactive_forma_pago.value = cobranzaPagoTable.editedItem.trimestre_actual.forma_pago;
            reactive_saldo_pendiente.value = cobranzaPagoTable.editedItem.trimestre_actual.saldo_pendiente;
          }

          // Mostrar la moneda USD sólo con 4 decimales
          if (cobranzaPagoTable.editedItem.moneda == MONEDAS.USD) {
            reactive_tipo_cambio.value = reactive_tipo_cambio.value.slice(0, -2);
          }

          /* Si se trata del 1er. trimestre y no se ha iniciado, setear el tipo de cambio y forma de pago originales,
          esto para evitar que por ejemplo se cambien estos valores, se cierre el modal y al volverse a abrir
          se sigan mostrando los ultimos valores en lugar de los guardados en la BD */
          if (cobranzaPagoTable.editedItem.trimestre_actual.amortizacion == 1 && !cobranzaPagoTable.editedItem.trimestre_actual.trimestre_iniciado) {
            cobranzaPagoTable.editedItem.trimestre_actual.tipo_cambio = cobranzaPagoTable.editedItem.tipo_cambio;
            cobranzaPagoTable.editedItem.trimestre_actual.forma_pago = cobranzaPagoTable.editedItem.forma_pago;
          }

          /* Cerrar el trimestre automaticamente si el pago actual coincide o es mayor al numero de pagos
          necesarios para cerrar un trimestre/semestre/anio */
          // if (!cerrar_trimestre_check.value && pago_actual.value >= num_pagos_al_trimestre.value) {
          //   cerrar_trimestre_check.value = true;
          // }

          // const saldo_restante = currency(saldo_trimestre.value) == 0 ? 0 : currency(saldo_trimestre.value);
          // const trimestre_final = +cobranzaPagoTable.editedItem.trimestre_actual.amortizacion == calcAmortizaciones(reactive_forma_pago.value, +cobranzaPagoTable.editedItem.anios_aseguradora);
          // if (trimestre_final && cerrar_trimestre_check.value && saldo_restante <= 0) {
          //   cantidad.value = 0;
          // }

          saldo_trimestre_incluyendo_cantidad.value = pago_al_trimestre.value - +cobranzaPagoTable.editedItem.trimestre_actual.abonado;
          vm.proxy.$refs.form.resetValidation();
          
          callback && callback();
        },

        cobranzaPagoTable.editedItem.id
      );
    }

    function unset_ClientePlanYPagosDataOnDialogClose(callback = null) {
      enable_forma_pago.value = false;
      enable_dia_pago.value = false;
      enable_pago_periodico.value = false;
      cerrar_trimestre_check.value = false;
      aplazar_dia_sig_check.value = false;
      utilizar_saldo_a_favor_check.value = false;
      plan_completado.value = false;
      plan_with_pagos_edited_index.value = -1;
      cantidad.value = "";
      datePagoAdminFechaAplazada.dateText = null;

      callback && callback();
    }

    // Resetear validacion cuando se abre el dialog/modal
    watch(() => cobranzaPagoTable.dialog, (dialog) => {
      if (dialog) {
        APP_DEBUG_MODE && console.log(`watch: cobranzaPagoTable.dialog ==`, dialog);
        set_ClientePlanYPagosDataOnDialogOpen(() => {
          if (esAbonoDirecto.value) {
            APP_DEBUG_MODE && console.log(`esAbonoDirecto ==`, esAbonoDirecto.value);
            cobranzaSave();
          }
        });
      }
      else {
        APP_DEBUG_MODE && console.log(`watch: cobranzaPagoTable.dialog ==`, dialog);
        unset_ClientePlanYPagosDataOnDialogClose(() => {
          esAbonoDirecto.value = false;
          procesandoAbono.value = false;
        });
      }
    });

    watch(() => filter_datePagoRange.dateOpen, (value) => {
      if (value == false && oldDateTextVal != filter_datePagoRange.dateText) {
        oldDateTextVal = filter_datePagoRange.dateText;
        actions[CLIENTES].FETCH_WITH_PAGOS(
          filter_datePagoRange.dateText
        );
      }
    });

    // Quitar la fecha de aplazo personalizada al quitar el check de "aplazar abono al dia siguiente"
    watch(() => aplazar_dia_sig_check.value, (value) => {
      if (!value) {
        datePagoAdminFechaAplazada.dateText = null;
      }
    });

    /* Funcion de ayuda para calcular el pago periodico al cambiar las propiedades de 
    tipo de cambio y la forma de pago al iniciar el trimestre */
    // function calcularPagoPeriodico(fecha_prox_pago, saldo_pendiente = 0) {
    //   const pago_moneda_contratada = cobranzaPagoTable.editedItem.trimestre_actual.pago_moneda_contratada;
    //   const tipo_cambio = reactive_tipo_cambio.value;
    //   const forma_pago = reactive_forma_pago.value;

    //   return calcularPagoPeriodicoCobranza(
    //     pago_moneda_contratada,
    //     tipo_cambio,
    //     forma_pago,
    //     fecha_prox_pago,
    //     saldo_pendiente,
    //   );
    // }

    // function updatePagoPeriodico(cobPagoTable) {
    //   const primer_anio = 1;
    //   const aseg_total = currency(
    //     (+cobPagoTable.editedItem.prima_neta + +cobPagoTable.editedItem.pago_fraccionado)
    //   );
    //   pago_periodico.value = calcularPlazo(
    //     primer_anio,
    //     aseg_total,
    //     reactive_forma_pago.value,
    //     reactive_tipo_cambio.value,
    //     cobPagoTable.editedItem.fecha_inicial,
    //     false,
    //   );
    // }

    function handleTipoCambio(value) {
      reactive_tipo_cambio.value = value;
      // updatePagoPeriodico(cobranzaPagoTable);
      // pago_periodico.value = calcularPagoPeriodico(+reactive_saldo_pendiente.value);
    }

    /* Maneja el cambio de la forma de pago. Importante: si se cambia la forma de pago, tambien se ejecutara
    la funcion "handlePagoPeriodico", ya que se esta cambiando el valor del pago periodico */
    // No se usa de momento porque el input no se visualiza nunca
    function handleFormaPago(value) {
      reactive_forma_pago.value = value;
      // updatePagoPeriodico(cobranzaPagoTable);

      // if (reactive_forma_pago.value == cobranzaPagoTable.editedItem.forma_pago_inicial) {
      //   /* En este caso "pago_periodico_resto" hace referencia al pago periodico inicial que se puso 
      //   al registrar el plan de cliente. El "pago_periodico_anio" cambiara a la que se establezca si
      //   se cambia la forma de pago al inicio de un trimestre */
      //   pago_periodico.value = +cobranzaPagoTable.editedItem.pago_periodico_resto;
      // }
      // else {
      //   pago_periodico.value = calcularPagoPeriodico(
      //     cobranzaPagoTable.editedItem.fecha_prox_pago,
      //     +reactive_saldo_pendiente.value
      //   );
      // }

      enable_dia_pago.value = true;
      enable_pago_periodico.value = true;

      /* Cerrar el trimestre automaticamente si el pago actual coincide o es mayor al numero de pagos
      necesarios para cerrar un trimestre/semestre/anio */
      if (!cerrar_trimestre_check.value && pago_actual.value >= num_pagos_al_trimestre.value) {
        cerrar_trimestre_check.value = true;
      }
      else {
        cerrar_trimestre_check.value = false;
      }
    }

    function handlePagoPeriodico(value) {
      pago_periodico.value = value;

      // Acumular saldo anterior al pago periodico y la cantidad si no se ha iniciado el trimestre
      // if (!cobranzaPagoTable.editedItem.trimestre_actual.trimestre_iniciado) {
      //   const saldo_trimestre_anterior = cobranzaPagoTable.editedItem.trimestre_anterior
      //     ? cobranzaPagoTable.editedItem.trimestre_anterior.saldo
      //     : 0;

      //   cantidad.value = +pago_periodico.value; //(+pago_periodico.value + +saldo_trimestre_anterior);
      //   if (+cantidad.value <= 0) {
      //     cantidad.value = 0;
      //   }
      //   else if (+cantidad.value >= +cobranzaPagoTable.editedItem.trimestre_actual.saldo) {
      //     cantidad.value = cantidad.value + +saldo_trimestre.value;
      //   }
        
      //   cobranzaPagoTable.editedItem.pago_periodico_ajuste = cantidad.value;
      // }
    }

    function handleClearFechaRango() {
      filter_datePagoRange.dateText = null;
      oldDateTextVal = filter_datePagoRange.dateText;
      actions[CLIENTES].FETCH_WITH_PAGOS(
        filter_datePagoRange.dateText
      );
    }
  
    // function handleAtrasadosCheck(checked) {
    //   if (checked) {
    //     filter_datePagoRange.dateText && handleClearFechaRango();
    //     filter_atrasados.value = vm.proxy.$root.$dayjs().format("YYYY-MM-DD");

    //     // Filtrar atrasados conforme a la proxima fecha de pago
    //     // filter_atrasados.value = vm.proxy.$root.$dayjs(cobranzaPagoTable.editedItem.fecha_prox_pago).format("YYYY-MM-DD");
        
    //     // Si hay abono atrasados, se re-calcularán el abono a pagar al dia actual, similar a como se maneja el filtro de fecha
    //     // actions[CLIENTES].FETCH_WITH_PAGOS(
    //     //   filter_atrasados.value
    //     // );
    //   }
    //   else {
    //     filter_datePagoRange.dateText && handleClearFechaRango();
    //     filter_atrasados.value = "";
        
    //     // Si hay abono atrasados, se re-calcularán el abono a pagar al dia actual, similar a como se maneja el filtro de fecha
    //     // actions[CLIENTES].FETCH_WITH_PAGOS(
    //     //   filter_atrasados.value
    //     // );
    //   }
    // }

    function handleClearFechaPagoAplazadaAdmin() {
      datePagoAdminFechaAplazada.dateText = null;
    }

    // Fechas permitidas para los campos de fecha, solo se limita para el Cobrador
    function allowedDatesAplazarAbono(val) {
      const date = val;
      const diasMax = diasAplazadosMaxPermitidos.value;
      const diasAplaz = diasAplazados.value;
      const diasRestantesDisponiblesHabiles = diasMax - diasAplaz;

      const datePickerDayName      = vm.proxy.$root.$dayjs(date).format('dddd');

      // Si se trata de usuarios Dev o Super Admin, solo bloquear los dias Domingo
      if (logged_user.value.privilegio == 0 || logged_user.value.privilegio == 1)
        return datePickerDayName != DIAS_SEMANA.DOM;

      const datePickerDateFormat   = vm.proxy.$root.$dayjs(date).format('YYYY-MM-DD');
      const fechaAbonoFormat       = vm.proxy.$root.$dayjs(fechaAbono.value).format('YYYY-MM-DD');
      let fechaLimiteAplazarFormat = vm.proxy.$root.$dayjs(fechaAbono.value).add(diasRestantesDisponiblesHabiles, "day").format('YYYY-MM-DD');

      let diasNoHabiles = 0;
      for (let index = 0; index <= diasRestantesDisponiblesHabiles; index++) {
        if (dateUtils.esDomingo(fechaAbonoFormat, (index))) {
          diasNoHabiles += 1;
        }
      }

      if (diasNoHabiles) {
        fechaLimiteAplazarFormat = vm.proxy.$root.$dayjs(fechaLimiteAplazarFormat).add(diasNoHabiles, "day").format('YYYY-MM-DD');
      }

      return datePickerDayName != DIAS_SEMANA.DOM
        && datePickerDateFormat >= fechaAbonoFormat
        && datePickerDateFormat < fechaLimiteAplazarFormat
    }

    function handleDismissCobranzaAlertOnClose() {
      setStorageItem("dismiss-cobranza-alert", true);
      dismissCobranzaAlert.value = !dismissCobranzaAlert.value;
    }

    function handleTableUpdate() {
      actions[CLIENTES].FETCH_WITH_PAGOS(
        filter_datePagoRange.dateText,
        () => {
          vm.proxy.$root.$toast.success('Tabla actualizada', { timeout: 1500 });
        }
      );
    }

    // Aplicar abono al momento de hacer clic en el boton del modal
    function handleAplicarAbonoOnClick() {
      APP_DEBUG_MODE && console.log("handleAplicarAbonoOnClick");
      /* Si hay un abono en proceso, no permitir la aplicacion de mas abonos hasta que sino hasta 
      se haya terminado el proceso en curso, para prevenir en lo posible abonos duplicados */
      if (procesandoAbono.value) {
        return;
      }

      // Este valor se desactiva nuevamente al cerrar el dialog en el watcher
      procesandoAbono.value = true;

      activateFullPageLoading(vueElementLoadingText, fullPageLoading);
      cobranzaSave();
    }

    /* Aplicar abono al momento de hacer clic en el boton de abono directo de la fila,
    la funcion de cobranza() en este caso se delegara al watcher del dialog */
    function handleAplicarAbonoDirecto() {
      APP_DEBUG_MODE && console.log("handleAplicarAbonoDirecto");
      /* Si hay un abono en proceso, no permitir la aplicacion de mas abonos hasta que sino hasta 
      se haya terminado el proceso en curso, para prevenir en lo posible abonos duplicados */
      if (procesandoAbono.value) {
        return;
      }

      // Estos valores se desactivan nuevamente al cerrar el dialog en el watcher
      esAbonoDirecto.value = true;
      procesandoAbono.value = true;

      activateFullPageLoading(vueElementLoadingText, fullPageLoading);
    }

    function cobranzaSave() {
      activateFullPageLoading(vueElementLoadingText, fullPageLoading);

      // Si se aplazo el pago actualizar la nueva "fecha_prox_pago" (que seria al dia siguiente sin contar domingos)
      if (aplazar_dia_sig_check.value) {
        cobranzaPagoTable.save(isEditable => {
          const data = {
            abono: {},
            trimestre_actual: {},
            plan_cliente: {
              id: cobranzaPagoTable.editedItem.id,
              // Si el admin eligió una fecha en concreta, se tomará para aplazar el abono, de lo contrario se aplazará al dia siguiente
              fecha_prox_pago: datePagoAdminFechaAplazada.dateText || vm.proxy.$root.$dayjs(fechaAbono.value).format('YYYY-MM-DD'),
              plan_iniciado: true,
            }
          }

          // Actualizar amortizacion actual y plan de cliente
          actions[CLIENTES].UPDATE_PAGO(data, (res) => {
            if (res.success) {
              // Despues de actualizar la "fecha_prox_pago" en "planes_clientes", actualizar la tabla de cobranza
              // Considerar que en lugar de traer todos los datos, se pida solo el del objeto que se actualizó
              updateFullPageLoadingText(vueElementLoadingText);
              actions[CLIENTES].FETCH_WITH_PAGOS(
                filter_datePagoRange.dateText,
                () => {
                  vm.proxy.$root.$toast.success(res.message);
                  disableFullPageLoading(fullPageLoading);
                }
              );
            }
            else {
              vm.proxy.$root.$toast.error(`Ha ocurrido un error: ${res.message}`);
              disableFullPageLoading(fullPageLoading);
            }

            cobranzaPagoTable.close();
          });
        });
      }

      // Si no se aplazo el pago, entonces guardar el abono normalmente
      else {
        // if (reactive_forma_pago.value == FORMA_PAGO.SEMANAL && !dia_pago.value) {
        //   return vm.proxy.$root.$toast.info("Se necesita establecer el dia de pago");
        // }
        if (utilizar_saldo_a_favor_check.value) {
          cantidad.value = +cobranzaPagoTable.editedItem.pago_periodico_ajuste;
        }

        const saldo_restante = currency(saldo_trimestre.value) == 0 ? 0 : currency(saldo_trimestre.value);
        const trimestre_final = +cobranzaPagoTable.editedItem.trimestre_actual.amortizacion == calcAmortizaciones(reactive_forma_pago.value, +cobranzaPagoTable.editedItem.anios_aseguradora);
        
        // Funcion de guardado a la BD
        cobranzaPagoTable.save(isEditable => {
          const cobrador = {...getters[USERS].findById(cobradorSelected.value).value};

          const fecha_abono_real = `${datePago.dateText} ${vm.proxy.$root.$dayjs().tz().hour()}:${vm.proxy.$root.$dayjs().tz().minute()}:${vm.proxy.$root.$dayjs().tz().second()}`;
          const fecha_abono_a_guardar = vm.proxy.$root.$dayjs().tz().isBefore(vm.proxy.$root.$dayjs(cobranzaPagoTable.editedItem.fecha_prox_pago).tz(), "day")
            ? vm.proxy.$root.$dayjs().tz().format("YYYY-MM-DD HH:mm:ss")
            : fecha_abono_real;

          /* Si el ultimo pago registrado existe y la fecha de este pago es menor, mosrtar mensaje
          de error, pues existe una inconsistencia en la secuencia de abonos, 
          ej. abono anterior: 12/feb/2021, este abono: 10/feb/2021 (???) */
          if (cobranzaPagoTable.editedItem.ultimo_abono_activo) {
            if (vm.proxy.$root.$dayjs(cobranzaPagoTable.editedItem.ultimo_abono_activo.fecha_abono).tz().isAfter(vm.proxy.$root.$dayjs(cobranzaPagoTable.editedItem.fecha_prox_pago).tz(), "day")) {
              disableFullPageLoading(fullPageLoading);
              return vm.proxy.$root.$toast.error(
                'No se ha aplicado el abono: la fecha de este pago'
                + ` (${vm.proxy.$root.$dayjs(cobranzaPagoTable.editedItem.fecha_prox_pago).tz().format("DD/MMM/YYYY")})`
                + ' no puede tener una fecha menor al último abono aplicado' 
                + ` (${vm.proxy.$root.$dayjs(cobranzaPagoTable.editedItem.ultimo_abono_activo.fecha_abono).tz().format("DD/MMM/YYYY")})`
                + '. Por favor revise el plan de cliente y el plan de pago correspondientes para verificar que los abonos están en orden con las fechas.' 
                , { timeout: 20000 }
              );
            }
          }

          const data = {
            // Insertar abono
            abono: {
              id_plan_pagos: cobranzaPagoTable.editedItem.trimestre_actual.id,
              id_cliente: cobranzaPagoTable.editedItem.clientes.id,
              id_cobrador: cobrador.id,
              cantidad: cantidad.value,
              /* Si la fecha del abono es mayor al dia actual, se pondra la fecha del dia actual como fecha del abono, de lo contrario
              se tomará la fecha que toca realmente, es decir un abono a futuro se tomara como si hubiera sido abonado en la fecha actual */
              fecha_abono: fecha_abono_a_guardar,
              fecha_abono_real: fecha_abono_real,
              saldo_a_favor_usado: 0,

              // Estos datos serviran para mostrarse en el corte, aun si el cliente, cobrador o plan de cliente han sido eliminados
              plan_poliza: cobranzaPagoTable.editedItem.poliza,
              plan_turno_pago: cobranzaPagoTable.editedItem.turno_pago,
              plan_forma_pago: cobranzaPagoTable.editedItem.forma_pago,
              plan_dia_pago: reactive_forma_pago.value == FORMA_PAGO.SEMANAL
                ? dia_pago.value
                : '',
              domiciliado: cobranzaPagoTable.editedItem.domiciliado,
              num_cliente: cobranzaPagoTable.editedItem.clientes.num_cliente,
              nombre_completo_cliente: `${cobranzaPagoTable.editedItem.clientes.nombre} ${cobranzaPagoTable.editedItem.clientes.apellidos}`,
              nombre_completo_cobrador: `${cobrador.nombre} ${cobrador.apellidos}`,
              id_plan_cliente: cobranzaPagoTable.editedItem.id,
              id_usuario_logueado: logged_user.value.id,
            },
            // Actualizar trimestre actual
            trimestre_actual: {
              id: cobranzaPagoTable.editedItem.trimestre_actual.id,
              saldo_pendiente: +reactive_saldo_pendiente.value,
              abonado: +cobranzaPagoTable.editedItem.trimestre_actual.abonado + +cantidad.value,
              saldo: saldo_trimestre.value,
              trimestre_iniciado: true,
              trimestre_cerrado: cerrar_trimestre_check.value,
            },
            // Actualizar plan de cliente
            plan_cliente: {
              id: cobranzaPagoTable.editedItem.id,
              dia_pago: reactive_forma_pago.value == FORMA_PAGO.SEMANAL
                ? dia_pago.value
                : '',
              fecha_prox_pago: vm.proxy.$root.$dayjs(fechaProxPago.value).format('YYYY-MM-DD'),
              fecha_prox_pago_tolerancia: vm.proxy.$root.$dayjs(fechaProxPago.value).format('YYYY-MM-DD'),
              plan_iniciado: true,
              trimestres_pagados: +cobranzaPagoTable.editedItem.trimestre_actual.amortizacion - 1,
              recibido_a_cuenta: +cobranzaPagoTable.editedItem.trimestre_actual.abonado + +cantidad.value,
              abonos_pagados_migracion: +pago_actual.value,
            }
          }

          if (cerrar_trimestre_check.value) {
            data.plan_cliente.trimestres_pagados = +cobranzaPagoTable.editedItem.trimestre_actual.amortizacion;
            data.plan_cliente.recibido_a_cuenta = 0;
            data.plan_cliente.abonos_pagados_migracion = 0;
          }

          if (!cobranzaPagoTable.editedItem.trimestre_actual.trimestre_iniciado) {
            data.trimestre_actual.pago_pesos = (+reactive_tipo_cambio.value * +cobranzaPagoTable.editedItem.trimestre_actual.pago_moneda_contratada);
            data.trimestre_actual.tipo_cambio = reactive_tipo_cambio.value;
            data.trimestre_actual.forma_pago = reactive_forma_pago.value;
            data.trimestre_actual.pago_periodico_calculado = +pago_periodico.value;
            data.plan_cliente.forma_pago = reactive_forma_pago.value;
            data.plan_cliente.num_pagos_al_trimestre = num_pagos_al_trimestre.value;
            data.plan_cliente.pago_periodico_anio = pago_periodico.value;

            // if (reactive_forma_pago.value != cobranzaPagoTable.editedItem.forma_pago) {
            //   data.plan_cliente.pago_periodico_anio = pago_periodico.value;
            // }
            // if (reactive_forma_pago.value == cobranzaPagoTable.editedItem.forma_pago_inicial) {
            //   data.plan_cliente.pago_periodico_anio = +cobranzaPagoTable.editedItem.pago_periodico_resto;
            // }
          }
          else {
            delete cobranzaPagoTable.editedItem.trimestre_actual.forma_pago;
          }

          data.abono.saldo_en_contra = (+cobranzaPagoTable.editedItem.pago_periodico_ajuste - +cantidad.value); // + +cobranzaPagoTable.editedItem.ultimo_abono;
          if ((+cobranzaPagoTable.editedItem.pago_periodico_ajuste - +cantidad.value) < 0) {
            data.abono.saldo_en_contra = 0;
            data.plan_cliente.saldo_a_favor = 
              +cobranzaPagoTable.editedItem.saldo_a_favor
              + (+cobranzaPagoTable.editedItem.pago_periodico_ajuste - +cantidad.value);
          }
          
          if (utilizar_saldo_a_favor_check.value) {
            data.abono.saldo_a_favor_usado = +cobranzaPagoTable.editedItem.pago_periodico_ajuste;
            // data.abono.cantidad = 0;
            data.plan_cliente.saldo_a_favor = 
              +cobranzaPagoTable.editedItem.saldo_a_favor
              + (+cobranzaPagoTable.editedItem.pago_periodico_ajuste);
          }

          if (trimestre_final && cerrar_trimestre_check.value) {
            if (saldo_restante > 0) {
              disableFullPageLoading(fullPageLoading);
              return vm.proxy.$root.$toast.error("El saldo restante debe quedar en 0");
            }

            if (saldo_restante < 0) {
              data.plan_cliente.saldo_a_favor = 
                +cobranzaPagoTable.editedItem.saldo_a_favor
                + (+saldo_restante);
            }

            data.plan_cliente.plan_completado = true;
          }

          /* Se ajusta el saldo pendiente del abono cuando se paga de mas y la cantidad de ese pago es mayor a lo 
          establecido en el pago periodico */
          // if (cobranzaPagoTable.editedItem.trimestre_actual.abonos.length && +cobranzaPagoTable.editedItem.pago_periodico_ajuste == 0) {
          //   if (+cantidad.value == 0) {
          //     data.abono.saldo_pendiente = +pago_periodico.value + +cobranzaPagoTable.editedItem.trimestre_actual.abonos[cobranzaPagoTable.editedItem.trimestre_actual.abonos.length - 1].saldo_pendiente;
          //   }
          //   else {
          //     data.abono.saldo_pendiente = (+cantidad.value * -1) + +cobranzaPagoTable.editedItem.trimestre_actual.abonos[cobranzaPagoTable.editedItem.trimestre_actual.abonos.length - 1].saldo_pendiente;
          //   }

          //   if (saldo_restante == 0) {
          //     data.abono.saldo_pendiente = 0;
          //   }
          // }

          // if (!cobranzaPagoTable.editedItem.trimestre_actual.trimestre_iniciado && (+pago_periodico.value + +reactive_saldo_pendiente.value) <= 0) {
          //   data.abono.saldo_pendiente = (+pago_periodico.value + +reactive_saldo_pendiente.value);
          // }
          

          // Establecer el saldo en contra en el plan de cliente
          data.plan_cliente.saldo_en_contra = (+cobranzaPagoTable.editedItem.saldo_en_contra + +pago_periodico.value) - +cantidad.value;
          if (data.plan_cliente.saldo_en_contra <= 0) {
            data.plan_cliente.saldo_en_contra = 0;
          }

          // Insertar el pago/abono, actualizar amortizacion actual y plan de cliente
          APP_DEBUG_MODE && console.log("Datos insertar_pago a enviar: ", data);
          actions[CLIENTES].INSERT_PAGO(data, (insertPagoRes) => {
            if (insertPagoRes.success) {
              // Despues de insertar y actualizar los datos, actualizar la tabla de cobranza
              // Considerar que en lugar de traer todos los datos, se pida solo el del objeto que se actualizó
              updateFullPageLoadingText(vueElementLoadingText);
              actions[CLIENTES].FETCH_WITH_PAGOS(
                filter_datePagoRange.dateText,

                (fetchWithPagosRes) => {
                  // Si el cliente con plan de pagos existe, actualizarlo, de lo contrario (ej. si se completó el plan), eliminarlo
                  if (fetchWithPagosRes[0]) {
                    Object.assign(state[CLIENTE_PLAN_Y_PAGOS][plan_with_pagos_edited_index.value], fetchWithPagosRes[0]);
                  }
                  else {
                    console.log(state[CLIENTE_PLAN_Y_PAGOS])
                    state[CLIENTE_PLAN_Y_PAGOS].splice(plan_with_pagos_edited_index.value, 1)
                    console.log(plan_with_pagos_edited_index.value, state[CLIENTE_PLAN_Y_PAGOS])
                  }

                  insertPagoRes.message && vm.proxy.$root.$toast.success(insertPagoRes.message);

                  if (plan_completado.value) {
                    vm.proxy.$root.$toast.success("Plan completado");
                  }

                  disableFullPageLoading(fullPageLoading);
                  cobranzaPagoTable.close();
                },

                data.plan_cliente.id
              );
            }
            else if (insertPagoRes.duplicated) {
              vm.proxy.$root.$toast.error(
                `Ha ocurrido un error: El abono en la fecha indicada ya ha sido aplicado. Por favor revise el plan de cliente y el plan de pago correspondientes para verificar que los abonos están en orden con las fechas.`,
                { timeout: 20000 }
              );

              disableFullPageLoading(fullPageLoading);
              cobranzaPagoTable.close();
            }
            else {
              vm.proxy.$root.$toast.error(
                `Ha ocurrido un error: ${insertPagoRes.message}`,
                { timeout: 10000 }
              );

              disableFullPageLoading(fullPageLoading);
              cobranzaPagoTable.close();
            }
          });
        });
      }
    }

    return {
      logged_user,
      plan,
      cliente,
      cantidad,
      dia_pago,
      reactive_tipo_cambio,
      reactive_forma_pago,
      reactive_saldo_pendiente,

      validFormCobranza,
      datePago,
      datePagoAdminFechaAplazada,
      cobranzaPagoTable,
      cobranzaSave,
      tableLoading,
      headers,
      // headersPago,
      aplazar_dia_sig_check, 
      utilizar_saldo_a_favor_check,
      cerrar_trimestre_check,
      disable_cerrar_trimestre_check,
      dialogPagos,
      pago_periodico,
      pago_al_trimestre,
      saldo_trimestre,
      handleTipoCambio,
      handleFormaPago,
      handlePagoPeriodico,
      handleClearFechaRango,
      handleClearFechaPagoAplazadaAdmin,
      handleDismissCobranzaAlertOnClose,
      handleTableUpdate,
      handleAplicarAbonoOnClick,
      handleAplicarAbonoDirecto,
      enable_forma_pago,
      enable_dia_pago,
      enable_pago_periodico,
      pago_actual,
      num_pagos_al_trimestre,
      total_abonado,
      fechaAbono,
      fechaProxPago,
      diasAplazados,
      diasAplazadosMaxPermitidos,
      saldo_trimestre_incluyendo_cantidad,
      clientesActivos,
      estatusPlan,
      allowedDatesAplazarAbono,
      dismissCobranzaAlert,

      fullPageLoading,
      vueElementLoadingText,

      filter_cobrador,
      filter_localidad,
      filter_domiciliado,
      filter_datePagoRange,
      filter_turno,
      filter_atrasados,
      // check_atrasados,
      // handleAtrasadosCheck,
      cobradoresSelect,
      cobradoresSelectFilter,
      cobradorSelected,
      localidadesSelectFilter,
      turnoSelectFilter,

      // existenAbonosTrimActual,
      esFormaPagoNoTrimestral,
      plazoToString,
      calcAmortizaciones,
      truncateStr,
      capitalize,
      capitalizeFirst,
      currency,
      formatCurrency,
      formatDiaPago,
      // parseDate,
      tipoCambioMaxLength,
      IS_ADMIN,
      PLAN_DATA,
      MONEDAS,
      FORMA_PAGO,
      FORMA_PAGO_SELECT,

      // Funcs. de validacion
      FormValidations,
    };
  }
};
</script>

<style lang="scss">

#cobranza-table {
  tbody tr {
    cursor: pointer;
  }
}
  
</style>