import { abonosAPI } from "@/utils/Axios";
import { forceArrayResult } from "@/utils/Functions";
import { $toast } from "@/main";
import { STORE_NAMESPACES } from "@/store/utils/constants";
import { APP_DEBUG_MODE } from "@/utils/Constants";

const { APP, USERS } = STORE_NAMESPACES;

//======================================================
// ACTIONS
//======================================================
const actionModule = (state) => ({
  [USERS]: { // Namespace

    // AUTH
    LOGIN: (data, callback = null) => {
      abonosAPI
        .put(`auth/login`, data)
        .then(res => {
          callback && callback({...res});
        });
    },
    // AUTH

    FETCH_ALL: (callback = null) => {
      abonosAPI
        .get(`users`)
        .then(res => {
          /* Si la respuesta es un arreglo se devuelve la respuesta, en caso contrario quiere decir 
          que ocurrio algun error, pues se regresa un objeto con las propiedades "success" y "message" 
          en lugar de un arreglo con los datos, por tanto si se da este caso se establece un arreglo 
          vacio por default al guardar en el store y así evitar errores en consola */
          const result = forceArrayResult(res);

          state[USERS] = result;
          callback && callback(result, res);
        });
    },

    INSERT: (data, callback = null) => {
      // Actualizar datos en la BD y el state
      delete data.id;
      
      abonosAPI
        .post(`user`, data)
        .then(res => {
          if (!res.success) {
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("INSERTED - USER: ", res.user);

          state[USERS] = [...state[USERS], res.user];
          $toast.success(res.message);

          callback && callback();
        });
    },

    UPDATE: (data, callback = null) => {
      // Actualizar datos en la BD y el state (este ultimo sólo el usuario que se modificó, no todo el state)
      const index = state[USERS].findIndex(user => user.id === data.id);

      if (data.estatus != null) {
        data.estatus = !data.estatus;
      }

      abonosAPI
        .patch(`user`, data)
        .then(res => {
          if (!res.success) {
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("UPDATED - USER: ", res.user);

          Object.assign(state[USERS][index], res.user);
          $toast.success(res.message);

          callback && callback(res.user);
        });
    },

    DELETE: (data, callback = null) => {
      const index = state[USERS].findIndex(usuario => usuario.id === data.id);

      const deleteReqBodyUsuario = { data: { id: data.id } };
      abonosAPI
        .delete(`user`, deleteReqBodyUsuario)
        .then(res => {
          if (!res.success) {
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("DELETED - USUARIO: ", res.usuario);
          
          state[USERS].splice(index, 1);
          $toast.success(res.message);

          callback && callback();
        });
    },
  },
});

//======================================================
// GETTERS
//======================================================
const getterModule = (state, computed) => ({
  [USERS]: {
    
    getAll: computed(() => {
      return state[USERS].filter(user => user.privilegio != 0) || [];
    }),

    getAllNonAdmin: computed(() => {
      const logged_user = state[USERS].find(user => user.id == state[APP].idUser) || {};

      // Si el usuario es super admin, se obtienen todos los usuarios menos él mismo
      // Si el usuario es admin, solo se obtienen los usuarios cobradores
      switch (logged_user.privilegio) {
        case 0:
        case 1:
          return state[USERS].filter(user => (user.id != logged_user.id) && (user.privilegio != 0)) || [];
        case 2:
          return state[USERS].filter(user => user.privilegio == 3) || [];
        default:
          return state[USERS].filter(user => user.id == logged_user.id) || [];
      }
    }),

    getAllCobradoresOnly: computed(() => {
      const logged_user = state[USERS].find(user => user.id == state[APP].idUser) || {};

      switch (logged_user.privilegio) {
        case 0:
        case 1:
        case 2:
          return state[USERS].filter(user => user.privilegio == 3) || [];
        default:
          return state[USERS].filter(user => user.id == logged_user.id) || [];
      }
    }),

    getAllCobranza: computed(() => {
      const logged_user = state[USERS].find(user => user.id == state[APP].idUser) || {};

      switch (logged_user.privilegio) {
        case 0:
        case 1:
          return state[USERS].filter(user => user.privilegio != 0) || [];
        case 2:
          return state[USERS].filter(user => user.privilegio != 1) || [];
        default:
          return state[USERS].filter(user => user.id == logged_user.id) || [];
      }
    }),

    getActivos: computed(() => {
      return state[USERS].filter(user => user.estatus && (user.privilegio != 0)) || [];
    }),

    findById: (id) => {
      return computed(() => state[USERS].find(user => user.id == id) || {});
    },
  },
});

export const userActions = actionModule;
export const userGetters = getterModule;