<template>
  <v-app id="app">
    <transition mode="out-in" name="fadeApp">
      <router-view class="grey lighten-3" />
    </transition>

    <vue-element-loading 
      :active="fullPageLoading" 
      text="Cargando..." 
      :text-style="{ color: '#333', fontSize: '20px' }" 
      :is-full-screen="true"
    />
  </v-app>
</template>

<script>
/* NOTAS: 
  - Checar por actualizaciones en dependencias del proyecto: npm i -g npm-check-updates@5.0.0 
    => ncu -u -x eslint,prettier,axios,sass-loader,p-queue,@vue/composition-api,sass
    utilizar la flag --target minor para no incluir los cambios de version mayores
  - La func. "setup(props, ctx)" se llama despues del hook "beforeCreate" y antes de "created".
  - Si un prop no se referencia en el template, no se ocasionara un re-render si éste cambia en el componente padre. Si se ocupa reaccionar 
    a un cambio de un prop sin que tenga referencia en el template, se deberá optar por utilizar una computed property o un watcher.
  - Se prefiere el uso del hook "watchEffect" por ser mas simple en lugar de "watch" pues no se tiene que especificar el valor observado
    y el callback por separado, sino que en estructura sera similar a los "lifecycle hooks" o el hook "computed". Aunque el hook "watch" 
    todavía seguirá existiendo sólo que en lugar de utilizar la propiedad "lazy" se usará la propiedad "immediate" la cual es opcional, 
    de no especificarse, está propiedad será "false" por default.
*/
/* Lifecycle Hooks: 
  - Para beforeCreate y created -> usar la funcion setup()

--- Utilizables ---
  - onBeforeMount
  - onMounted
  - onBeforeUpdate
  - onUpdated
  - onBeforeUnmount
  - onUnmounted
  - onErrorCaptured

--- Nuevos (para hacer debug en el render) ---
  - onRenderTracked
  - onRenderTriggered
*/
/**
 * Tabla abonos: valores "id_usuario_logueado":
    * Si se trata de un valor positivo, este apuntara a un usuario/cobrador del sistema
    * -1 => Proceso automatizado de node-cron en el que se realiza un abono en 0 a los planes Diarios que esten al corriente siempre y cuando estos hayan llegado a las 11:59pm horas
    * Para el proceso node-cron manual, se utiliza un id del usuario/cobrador que lo realizo, y la hora se establecera por default a las 11:59pm para indicar que realmente se trato de un proceso manual
 */
import { 
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onErrorCaptured,
  computed,
  ref,
} from 'vue';
import { abonosAPI } from "@/utils/Axios";
import { APP_DEBUG_MODE } from "@/utils/Constants";
import { getStorageItem, isObj, debounce } from "@/utils/Functions";
import { state, actions, getters, STORE_NAMESPACES } from "@/store";

export default {
  // setup puede recibir 2 params: "props" y "context" (abreviado "ctx")
  setup(props, ctx) {
    const { APP, PLANES, USERS, CLIENTES } = STORE_NAMESPACES;

    const fullPageLoading = ref(true);

    const token  = computed(() => getStorageItem("auth-token"));
    const userId = computed(() => getStorageItem("id-user"));

    if (token.value) {
      state[APP].idToken = token.value;
      state[APP].idUser  = userId.value;
      abonosAPI.defaults.headers.common.Authorization = `Bearer ${token.value}`;

      APP_DEBUG_MODE && console.log("token: ", state[APP].idToken);
      APP_DEBUG_MODE && console.log("idUser: ", state[APP].idUser);

      actions[USERS].FETCH_ALL(users => {
        const logged_user = Array.isArray(users) ? users.find(user => user.id == state[APP].idUser) : null;

        if (logged_user) {
          
          if (logged_user.privilegio == 0) {
            state[APP].isDevUser = true;
            state[APP].isAdmin   = true;
          }
          else if (logged_user.privilegio == 1 || logged_user.privilegio == 2) {
            state[APP].isAdmin   = true;
          }
          else {
            state[USERS] = [logged_user];
          }

          APP_DEBUG_MODE && console.log("FETCHED_ALL - USERS: ", state[USERS]);
          
          actions[CLIENTES].FETCH_ALL(clientes => {

            actions[PLANES].FETCH_ALL(planes => {
              state[APP].appFullyLoaded = true;
              fullPageLoading.value = false;
              APP_DEBUG_MODE && console.log("[App.vue - App Fully Loaded]: ", state[APP].appFullyLoaded);
            });
          });
        }
        else {
          state[USERS] = [];
          fullPageLoading.value = false;
          return;
        }
      });
    }

    onBeforeMount(() => APP_DEBUG_MODE && console.log('[App.vue]: beforeMount'));
    onMounted(() => {
      APP_DEBUG_MODE && console.log('[App.vue]: mounted');
      window.onresize = debounce(() => {
        actions[APP].SET_WINDOWS_WIDTH(window.innerWidth);
      }, 500);
    });
    onBeforeUpdate(() => APP_DEBUG_MODE && console.log('[App.vue]: beforeUpdate'));
    onUpdated(() => {
      APP_DEBUG_MODE && console.log('[App.vue]: updated');
      fullPageLoading.value = false;
    });
    onBeforeUnmount(() => APP_DEBUG_MODE && console.log('[App.vue]: beforeDestroy'));
    onUnmounted(() => APP_DEBUG_MODE && console.log('[App.vue]: destroyed'));
    onErrorCaptured((e) => APP_DEBUG_MODE && console.log('[App.vue]: errorCaptured', e));
    
    return { fullPageLoading };
  }
}
</script>

<style lang="scss">
html {
  overflow: auto;
}

a {
  text-decoration: none;
}

@media only screen and (max-width: 1264px) {
  .container {
    max-width: 100%;
    min-width: 100%;
  }
}

@media only screen and (min-width: 1904px) {
  .container {
    max-width: 84%;
  }
}

#exportarPDF:hover, #exportarExcel:hover { 
  text-decoration: underline;
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100% !important;
}

.v-text-field__slot > .v-label {
  max-width: 110% !important;
}

.v-select__slot > .v-label {
  max-width: 95% !important;
}

// Vue router transition
.fadeApp-enter-active,
.fadeApp-leave-active {
  transition: opacity 0.2s;
}
.fadeApp-enter,
.fadeApp-leave-to {
  opacity: 0;
}

// Vue toastification custom "fade" transition
@keyframes fadeIn {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    transform: translateY(100px);
    opacity: 0;
  }
}
.fade-enter-active {
  animation-name: fadeIn;
}
.fade-leave-active {
  animation-name: fadeOut;
}
.fade-move {
  transition-timing-function: ease-in-out;
  transition-property: opacity, transform;
  transition-duration: .2s;
}

// v-tooltip directive
.tooltip {
  display: block !important;
  z-index: 10000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;

  .tooltip-inner {
    background: #424242;
    color: white;
    border-radius: 5px;
    padding: 8px 16px;
  }

  // .tooltip-arrow {
  //   width: 0;
  //   height: 0;
  //   border-style: solid;
  //   position: absolute;
  //   margin: 5px;
  //   border-color: black;
  //   z-index: 1;
  // }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    // .tooltip-arrow {
    //   border-width: 5px 5px 0 5px;
    //   border-left-color: transparent !important;
    //   border-right-color: transparent !important;
    //   border-bottom-color: transparent !important;
    //   bottom: -5px;
    //   left: calc(50% - 5px);
    //   margin-top: 0;
    //   margin-bottom: 0;
    // }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    // .tooltip-arrow {
    //   border-width: 0 5px 5px 5px;
    //   border-left-color: transparent !important;
    //   border-right-color: transparent !important;
    //   border-top-color: transparent !important;
    //   top: -5px;
    //   left: calc(50% - 5px);
    //   margin-top: 0;
    //   margin-bottom: 0;
    // }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    // .tooltip-arrow {
    //   border-width: 5px 5px 5px 0;
    //   border-left-color: transparent !important;
    //   border-top-color: transparent !important;
    //   border-bottom-color: transparent !important;
    //   left: -5px;
    //   top: calc(50% - 5px);
    //   margin-left: 0;
    //   margin-right: 0;
    // }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    // .tooltip-arrow {
    //   border-width: 5px 0 5px 5px;
    //   border-top-color: transparent !important;
    //   border-right-color: transparent !important;
    //   border-bottom-color: transparent !important;
    //   right: -5px;
    //   top: calc(50% - 5px);
    //   margin-left: 0;
    //   margin-right: 0;
    // }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
</style>
