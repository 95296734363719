const vueToastificationOpts = {
  position: "bottom-right",
  transition: "fade",
  maxToasts: 5,
  timeout: 3000,
  draggable: true,
  draggablePercent: 0.2,
  transitionDuration: 500,
  newestOnTop: true,
  hideProgressBar: true,
  hideCloseButton: true,
  closeOnClick: true,
};

const vTooltipOpts = {
  // defaultDelay: 200,
  // defaultOffset: 10,
};

// const loadingOpts = { 
//   active: true, 
//   transition: 'fadeApp', 
//   color: '#1976D2' 
// };

export {
  vueToastificationOpts,
  vTooltipOpts,
  // loadingOpts,
}