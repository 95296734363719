<template>
<!-- dialog-bottom-transition -->
  <v-dialog 
    v-bind="$attrs"
    v-on="$listeners"
    class="min-height: 100%;"
    :max-width="$attrs['max-width'] || '768px'"
    :hide-overlay="fullscreenProp ? true : false"
    :transition="fullscreenProp ? 'fade-transition' : 'fade-transition'"
    @click:outside="$emit('onClickOutside')"
  >
    <template v-slot:activator="{ on }">
      <Button v-if="btnText" color="primary" :disabled="$attrs['disabled']" :dark="!$attrs['disabled']" v-on="on">
        {{ btnText }}
      </Button>
      <a v-if="linkText" id="dialog-linkText" color="primary" :disabled="$attrs['disabled']" :dark="!$attrs['disabled']" v-on="on">
        {{ linkText }}
      </a>
    </template>

    <v-card class="pb-6">
      <v-app-bar 
        v-if="useToolbarStyle" 
        style="position: sticky; top: -1px; z-index: 1;" 
        :height="`${toolbarHeight ? toolbarHeight : '64px'}`"
        :class="showCloseIcon ? 'pl-1 mb-3' : 'mb-3'"
        dark 
        color="primary"
      >
        <!-- Evento para cerrar dialog por medio del boton "X", props: class="mr-2"-->
        <Button v-if="showCloseIcon" 
          icon 
          dark 
          :disabled="disableCloseIcon"
          @click="$emit('onCloseToolbarBtn')"
        >
          <v-icon>mdi-close</v-icon>
        </Button>

        <v-toolbar-title :class="showCloseIcon ? 'pl-2' : ''">
          <div class="d-flex flex-column">
            <div>
              {{ title }}
            </div>
            <div v-if="subtitle" class="body-1">
              {{ subtitle }}
            </div>
            <div v-if="second_subtitle" class="body-2">
              {{ second_subtitle }}
            </div>
          </div>
        </v-toolbar-title>
        <v-spacer />

        <div v-if="actionsEnabled" :class="showCloseIcon ? '' : ''">
          <slot name="actions" />
        </div>

      </v-app-bar>
      <v-card-title v-else>
        <span class="headline">{{ title }}</span>
      </v-card-title>

      <v-card-text class="pb-0">
          <v-container>
              <slot name="content" />

              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <div>
                  <slot name="actions" :color="'primary'" />
                </div>
              </v-card-actions> -->
          </v-container>
      </v-card-text>

      <!-- Pass on all named slots -->
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>

      <!-- Pass on all scoped slots -->
      <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/></template>

      <!-- <div style="height: 50px; width: 100%; position: sticky; bottom: 0; background: linear-gradient(360deg, rgba(0,0,0,.4) 0%, rgba(0,0,0,0) 60%, rgba(0,212,255,0) 100%);">
      </div> -->
    </v-card>
  </v-dialog>
</template>

<script>
import { getCurrentInstance, computed } from "vue";
import Button from "@/components/ui/Button";

export default {
  props: {
    title: String,
    subtitle: String,
    second_subtitle: String,
    toolbarHeight: String,
    btnText: String,
    linkText: String,
    useToolbarStyle: Boolean,
    actionsEnabled: {
      type: Boolean,
      default: true,
    },
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
    disableCloseIcon: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Button,
  },
  setup(props) {
    const vm = getCurrentInstance();
    const fullscreenProp = computed(() => vm?.proxy.$attrs?.fullscreen);

    return {
      fullscreenProp,
    };
  }
};
</script>

<style scoped>
#dialog-linkText:hover { 
  text-decoration: underline;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 96%;
}
</style>