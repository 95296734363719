<template>
  <v-list-item 
    v-bind="$attrs"
    v-on="$listeners"
    style="min-height: 50px;"
  >
    <v-list-item-icon v-if="icon" style="margin-top: auto; margin-bottom: auto; margin-right: 14px;">
      <v-icon dense v-text="icon" :style="flipIconX ? {transform: 'scaleX(-1)'} : {}"></v-icon>
    </v-list-item-icon>

    <v-list-item-action v-if="action">
      <slot />
    </v-list-item-action>

    <v-list-item-content :class="(overtext && subtext) ? 'py-3' : ''">
      <v-list-item-subtitle v-if="overtext" v-text="overtext" class="caption"></v-list-item-subtitle>
      <v-list-item-title style="margin-bottom: 0px;" v-text="text"></v-list-item-title>
      <v-list-item-subtitle v-if="subtext" v-text="subtext" class="caption"></v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    text: {
      required: true,
      type: String,
    },
    subtext: {
      type: String,
    },
    overtext: {
      type: String,
    },
    icon: {
      type: String,
    },
    action: {
      type: Boolean,
    },
    flipIconX: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
