import { abonosAPI } from "@/utils/Axios";
import { forceArrayResult } from "@/utils/Functions";
import { STORE_NAMESPACES } from "@/store/utils/constants";
import { APP_DEBUG_MODE } from "@/utils/Constants";
import { $toast } from "@/main";

const { PLAN_PAGOS } = STORE_NAMESPACES;

//======================================================
// ACTIONS
//======================================================
const actionModule = (state) => ({
  [PLAN_PAGOS]: {

    SET_DEFAULT: () => {
      state[PLAN_PAGOS] = [];
    },
    
    FETCH: (idPlanCliente, suspendido = false, callback = null, fetchWithAbonos = true) => {
      abonosAPI
        .get(`plan_pago/${idPlanCliente}/${suspendido}/${fetchWithAbonos}`)
        .then(res => {
          APP_DEBUG_MODE && console.log("FETCHED - PLAN_PAGOS: ", res);
          
          /* Si la respuesta es un arreglo se devuelve la respuesta, en caso contrario quiere decir 
          que ocurrio algun error, pues se regresa un objeto con las propiedades "success" y "message" 
          en lugar de un arreglo con los datos, por tanto si se da este caso se establece un arreglo 
          vacio por default al guardar en el store y así evitar errores en consola */
          const result = forceArrayResult(res);

          state[PLAN_PAGOS] = result;
          callback && callback(result, res);
        });
    },

    // Llamado desde planes_cliente al guardar un plan de cliente. Inserta todo el plan de pagos conforme a los años del plan del cliente
    INSERT: (data, callback = null) => {
      // Guardar el id del plan del cliente en una propiedad aparte
      const plan_cliente_data = {...data};
      plan_cliente_data.id_plan_cliente = data.id;

      // Quitar la propiedad "id" del plan de cliente para realizar la insercion
      delete plan_cliente_data.id;

      abonosAPI
        .post(`plan_pago`, plan_cliente_data)
        .then(res => {
          if (!res.success) {
            callback && callback(res.success);
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("INSERTED - PLAN_PAGOS: ", res.plan_pago);
          callback && callback(res.success);
        });
    },

    // Llamado desde planes_cliente al actualizar un plan de cliente. Actualiza todo el plan de pagos siempre y cuando no se haya inicializado el plan del cliente
    // Asi mismo solo es posible actualizar el plan de pagos con la misma cantidad de años con la que se registro el plan del cliente 
    // (se deshabilitan la seleccion del plan y los años de duracion del plan en la vista para este propósito)
    UPDATE: (data, callback = null) => {
      const plan_cliente_data = {...data};
      plan_cliente_data.id_plan_cliente = data.id;

      abonosAPI
        .patch(`plan_pago`, plan_cliente_data)
        .then(res => {
          if (!res.success) {
            callback && callback(res.success);
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("UPDATED - PLAN_PAGOS: ", res.plan_pago);
          callback && callback(res.success);
        });
    },

    // Se utilizara para actualizar una fila determinada cuando se haga un abono
    // UPDATE_BY_CRITERIA: (criteria, dataToUpdate) => {
    //   const data = {
    //     criteria,
    //     dataToUpdate,
    //   }

    //   abonosAPI
    //     .patch(`plan_pago/updateByCriteria`, data)
    //     .then(res => {
    //       APP_DEBUG_MODE && console.log("UPDATE_BY_CRITERIA - PLAN_PAGOS: ", res);
    //     });
    // },

    DELETE: (data) => {
      /* Si se tienen planes de clientes quiere decir que se estan eliminando desde la vista de PlanesClientes, y por tanto se manda el
      "id" del plan del cliente a eliminar. De lo contrario quiere decir que se esta dando de baja al cliente y con él todos sus planes,
      por lo que todos los que tengan el "id_cliente" del cliente serán eliminados */
      const deleteReqBodyPlanPagos = { data: { id_plan_cliente: data.id } };
      abonosAPI
        .delete(`plan_pago`, deleteReqBodyPlanPagos)
        .then(res => {
          APP_DEBUG_MODE && console.log("DELETED - PLAN_PAGOS: ", res.plan_pago);
        });
    },

  }
});

//======================================================
// GETTERS
//======================================================
const getterModule = (state, computed) => ({
  [PLAN_PAGOS]: {
    
    getAll: computed(() => state[PLAN_PAGOS]),

  },
});

export const planPagoActions = actionModule;
export const planPagoGetters = getterModule;