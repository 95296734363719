import { computed, watch, reactive } from "vue";
import { getters, STORE_NAMESPACES } from "@/store";

const { APP } = STORE_NAMESPACES;

// Function que exporta toda la lógica CRUD para una tabla
/**
 * 
 * @param {Object[]} dataSource 
 * @param {Object} itemStructure 
 */
export default function useDataTable(dataSource, itemStructure) {
  const state = reactive({
    items: dataSource,
    editedItem: itemStructure,
    defaultItem: itemStructure,
    dialog: false,
    disableRowClick: false,
    editedIndex: -1,
    disableActionBtn: getters[APP].getDisableActionBtn,
    setEditedItem,
    editItem,
    // deleteItem,
    close,
    save,
    isEditable: computed(() => state.editedIndex > -1),
    formTitle: computed(() => state.isEditable ? `Editar` : "Nuevo"),
  });

  watch(() => state.dialog, val => {
    return val || close();
  }, { immediate: false });

  function setEditedItem(newItem, showDialog = false) {
    state.disableRowClick = false;
    
    setTimeout(() => {
      editItem(newItem);
      state.editedIndex = state.items.findIndex(item => item.id == newItem.id);
      state.dialog = showDialog;
    }, 100);
  }

  /**
   * 
   * @param {Object} item 
   */
  function editItem(item) {
    // Deshabilitar la funcion para editar el objeto de la tabla si se cierra y abre rapidamente el modal
    if (state.disableRowClick) {
      return;
    }

    state.editedIndex = state.items.indexOf(item);
    state.editedItem = Object.assign({}, item);
    if (state.editedItem.estatus != null) {
      state.editedItem.estatus = !state.editedItem.estatus;
    }
    state.dialog = true;
  }

  // function deleteItem(item) {
  //   const index = state.items.indexOf(item);
  //   state.items.splice(index, 1);
  // }

  function close() {
    state.dialog = false;
    state.disableRowClick = true;
    setTimeout(() => {
      // state.activeTab = 0;
      state.editedItem = Object.assign({}, state.defaultItem);
      state.editedIndex = -1;

      // if (state.disableActionBtn == true) {
      //   state.disableActionBtn = false;
      // }

      state.disableRowClick = false;
    }, 300);
  }

  /**
   * 
   * @param {requestCallback} cb
   */
  function save(callback) {
    // state.disableActionBtn = true;
    callback(state.isEditable);
    /* Llamar a esta funcion en el setup() de los componentes vue para cerrar
    el modal de manera mas controlada */
    // close();
  }

  return state;
}
