import Vue from "vue";
// import VueCompositionApi from "@vue/composition-api";
// Vue.use(VueCompositionApi);

import { reactive, computed } from "vue";
import { userActions, userGetters } from "./actions_getters/users";
import { clienteActions, clienteGetters } from "./actions_getters/clientes";
import { planActions, planGetters } from "./actions_getters/planes";
import { planClienteActions, planClienteGetters } from "./actions_getters/planes_clientes";
import { planPagoActions, planPagoGetters } from "./actions_getters/planes_pagos";
import { abonoActions, abonoGetters } from "./actions_getters/abonos";
import { devAdminActions, devAdminGetters } from "./actions_getters/dev_admin";
import { STORE_NAMESPACES } from "./utils/constants";

const { APP, USERS, CLIENTES, PLANES, PLANES_CLIENTE, PLAN_PAGOS, ABONOS, DEV_ADMIN, CLIENTE_PLAN_Y_PAGOS } = STORE_NAMESPACES;

const APP_DEFAULTS = {
  cached : {},
  idToken: null,
  idUser : null,
  isAdmin: false,
  loading: false,
  isDevUser: false, // Para ver info. de debug en modo producción si se entra con un usuario dev (privilegio 0)
  appFullyLoaded:   false,
  serverError:      false,
  expiredSession:   false,
  disableActionBtn: false,
  windowWidth: window.innerWidth
};

const STORE_CONTAINER_DEFAULTS = {
  [CLIENTES]: [], // Se carga al iniciar la app - FETCH_ALL
  [USERS]: [],    // Se carga al iniciar la app - FETCH_ALL
  [PLANES]: [],   // Se carga al iniciar la app - FETCH_ALL
  [PLANES_CLIENTE]: [], 
  [PLAN_PAGOS]: [],
  [ABONOS]: [],
  [DEV_ADMIN]: [],

  // Llamado en planes_clientes.js: almacena el plan de cliente con su plan de pagos y los abonos de cada trimestre
  [CLIENTE_PLAN_Y_PAGOS]: [], 
}

// State del store
const state = reactive({
  [APP]: { ...APP_DEFAULTS },
  ...STORE_CONTAINER_DEFAULTS
});

// Separar las acciones en modulos
const actions = {
  [APP]: {
    SET_LOADING: (loadingState) => {
      state[APP].loading = loadingState;
    },
    SET_DISABLE_ACTION_BTN: (disableActionBtnState) => {
      state[APP].disableActionBtn = disableActionBtnState;
    },
    SET_EXPIRED_SESSION: (expiredSessionState) => {
      state[APP].expiredSession = expiredSessionState;
    },
    SET_WINDOWS_WIDTH: (newWindowWidth) => {
      state[APP].windowWidth = newWindowWidth;
    },
    SET_DEFAULTS: () => {
      state[APP] = { ...APP_DEFAULTS };
      Object.keys(STORE_CONTAINER_DEFAULTS).forEach(storeKey => {
        state[storeKey] = [];
      })
    },
  },
  ...userActions(state),
  ...clienteActions(state),
  ...planActions(state),
  ...planClienteActions(state),
  ...planPagoActions(state),
  ...abonoActions(state),
  ...devAdminActions(state),
};

const getters = {
  [APP]: {
    getIsAdmin: computed(() => state[APP].isAdmin),
    getIsDevUser: computed(() => state[APP].isDevUser),
    getIdUser: computed(() => state[APP].idUser),
    getIdToken: computed(() => state[APP].idToken),
    getLoading: computed(() => state[APP].loading),
    getDisableActionBtn: computed(() => state[APP].disableActionBtn),
    getWindowWidth: computed(() => state[APP].windowWidth),
  },
  ...userGetters(state, computed),
  ...clienteGetters(state, computed),
  ...planGetters(state, computed),
  ...planClienteGetters(state, computed),
  ...planPagoGetters(state, computed),
  ...abonoGetters(state, computed),
  ...devAdminGetters(state, computed),
};

// "state" se exporta solo para cuestiones de DEBUG, no se debe mutar directamente
export { state, actions, getters, STORE_NAMESPACES };
