const integer = /^[0-9]*$/;
const realNum = /^[0-9]+([.][0-9]+)?$/g;
const email   = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9]{1,9}$/i;

const RegExp = {
  integer,
  realNum,
  email,
};

export default RegExp;