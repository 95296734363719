import { abonosAPI } from "@/utils/Axios";
import { forceArrayResult } from "@/utils/Functions";
import { $toast } from "@/main";
import { STORE_NAMESPACES } from "@/store/utils/constants";
import { APP_DEBUG_MODE } from "@/utils/Constants";

const { APP, PLANES } = STORE_NAMESPACES;

//======================================================
// ACTIONS
//======================================================
const actionModule = (state) => ({
  [PLANES]: {

    FETCH_ALL: (callback = null) => {
      if (!state[APP].isAdmin) {
        callback && callback([]);
        return;
      }
      
      abonosAPI
        .get(`planes`)
        .then(res => {
          APP_DEBUG_MODE && console.log("FETCHED_ALL - PLANES: ", res);
          
          /* Si la respuesta es un arreglo se devuelve la respuesta, en caso contrario quiere decir 
          que ocurrio algun error, pues se regresa un objeto con las propiedades "success" y "message" 
          en lugar de un arreglo con los datos, por tanto si se da este caso se establece un arreglo 
          vacio por default al guardar en el store y así evitar errores en consola */
          const result = forceArrayResult(res);

          state[PLANES] = result;
          callback && callback(result, res);
        });
    },

    INSERT: (data, callback = null) => {
      delete data.id;

      abonosAPI
        .post(`plan`, data)
        .then(res => {
          if (!res.success) {
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("INSERTED - PLAN: ", res.plan);

          state[PLANES] = [...state[PLANES], res.plan];
          $toast.success(res.message);

          callback && callback();
        });
    },

    UPDATE: (data, callback = null) => {
      const index = state[PLANES].findIndex(plan => plan.id === data.id);

      if (data.estatus != null) {
        data.estatus = !data.estatus;
      }

      abonosAPI
        .patch(`plan`, data)
        .then(res => {
          if (!res.success) {
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("UPDATED - PLAN: ", res.plan);

          Object.assign(state[PLANES][index], res.plan);
          $toast.success(res.message);

          callback && callback();
        });
    },

    DELETE: (data, callback = null) => {
      const index = state[PLANES].findIndex(plan => plan.id === data.id);

      const deleteReqBodyPlan = { data: { id: data.id } };
      abonosAPI
        .delete(`plan`, deleteReqBodyPlan)
        .then(res => {
          if (!res.success) {
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("DELETED - PLAN: ", res.plan);
          
          state[PLANES].splice(index, 1);
          $toast.success(res.message);

          callback && callback();
        });
    },
  },
});

//======================================================
// GETTERS
//======================================================
const getterModule = (state, computed) => ({
  [PLANES]: {
    
    getAll: computed(() => state[PLANES]),

    getActivos: computed(() => {
      return state[PLANES]
        .filter(plan => plan.estatus) || [];
    }),

    findById: (id) => {
      return computed(() => state[PLANES]
        .find(plan => plan.id == id)) || {};
    },

  },
});

export const planActions = actionModule;
export const planGetters = getterModule;