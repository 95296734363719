import axios from "axios";
import { $route, $router } from "@/main";
import { actions, STORE_NAMESPACES } from "@/store";
import { removeStorageItem } from "@/utils/Functions";
import { DEVELOPER_ENV, APP_DEBUG_MODE } from "@/utils/Constants";
const { APP } = STORE_NAMESPACES;

const port = DEVELOPER_ENV ? 3004 : 3005;
const baseURL = DEVELOPER_ENV ? `http://127.0.0.1:${port}/api` : `/api`;

APP_DEBUG_MODE && console.log("Axios Base URL: ", baseURL, port);
const abonosAPI = axios.create({ baseURL });

abonosAPI.interceptors.request.use((config) => {
  actions[APP].SET_LOADING(true);
  actions[APP].SET_DISABLE_ACTION_BTN(true);

  return config;
}, (error) => {
  APP_DEBUG_MODE && console.log("Error axios interceptor (req): ", error);
    
  setTimeout(() => {
    actions[APP].SET_LOADING(false);
    actions[APP].SET_DISABLE_ACTION_BTN(false);
  }, 300);
    
  return Promise.reject(error.response);
});

abonosAPI.interceptors.response.use((res) => {
  setTimeout(() => {
    actions[APP].SET_LOADING(false);
    actions[APP].SET_DISABLE_ACTION_BTN(false);
  }, 300);

  return res.data;
}, (error) => {
  APP_DEBUG_MODE && console.log("Error axios interceptor (res): ", error.response || {error});
  APP_DEBUG_MODE && console.log("router path - axios interceptor (res)", $route.path);

  setTimeout(() => {
    actions[APP].SET_LOADING(false);
    actions[APP].SET_DISABLE_ACTION_BTN(false);
  }, 300);

  if (error?.response?.status === 401 && !window.location.pathname.includes("/login") ) {
    APP_DEBUG_MODE && console.log('no autorizado, cerrando sesión ...');

    actions[APP].SET_DEFAULTS();
    actions[APP].SET_EXPIRED_SESSION(true);
    removeStorageItem("auth-token");
    removeStorageItem("id-user");

    // $router.replace("/login");
    $router.go();
  }
    
  return error?.response?.data || 
    { message: error.message.includes("Network") 
        ? "No se pudo establecer la conexión con el servidor." 
        : error.message, 
      success: false };
});

export { abonosAPI };