import { abonosAPI } from "@/utils/Axios";
import { forceArrayResult } from "@/utils/Functions";
import { $toast } from "@/main";
import { STORE_NAMESPACES } from "@/store/utils/constants";
import { APP_DEBUG_MODE } from "@/utils/Constants";
import { actions } from "@/store";

const { APP, CLIENTES, PLANES_CLIENTE, /*USERS,*/ CLIENTE_PLAN_Y_PAGOS } = STORE_NAMESPACES;

//======================================================
// ACTIONS
//======================================================
const actionModule = (state) => ({
  [CLIENTES]: { // Namespace

    SET_DEFAULT_PLAN_Y_PAGOS: () => {
      state[CLIENTE_PLAN_Y_PAGOS] = [];
    },

    FETCH_ALL: (callback = null) => {
      const idUser = `?idUser=${state[APP].isAdmin ? `${''}` : state[APP].idUser}`;

      abonosAPI
        .get(`clientes${idUser}`)
        .then(res => {
          APP_DEBUG_MODE && console.log("FETCHED_ALL - CLIENTES: ", res);
          
          /* Si la respuesta es un arreglo se devuelve la respuesta, en caso contrario quiere decir 
          que ocurrio algun error, pues se regresa un objeto con las propiedades "success" y "message" 
          en lugar de un arreglo con los datos, por tanto si se da este caso se establece un arreglo 
          vacio por default al guardar en el store y así evitar errores en consola */
          const result = forceArrayResult(res);

          state[CLIENTES] = result;
          callback && callback(result, res);
        });
    },

    /** INICIA COBRANZA */
    FETCH_WITH_PAGOS: (fecha, callback = null, id = null) => {
      // const [fecha, fechaFin] = fechaRango;
      // const fecha_actual = `?fechaActual=${''/*$dayjs().format("YYYY-MM-DD")*/}`;
      const filtro_fecha_rango_inicio = fecha ? `?fechaInicio=${fecha}` : `?fechaInicio=${''}`;
      const idUser = `&idUser=${state[APP].isAdmin ? `${''}` : state[APP].idUser}`;
      // const filtro_fecha_rango_fin = fechaFin ? `&fechaFin=${fechaFin}` : '';
      const idPlan = id ? `&idPlan=${id}` : `&idPlan=${''}`;

      abonosAPI
        .get(`clientes/withPlanYPagos${filtro_fecha_rango_inicio}${idUser}${idPlan}`)
        .then(res => {
          APP_DEBUG_MODE && console.log("FETCHED_ALL - CLIENTES (WITH_PLAN_Y_PAGOS): ", res || []);
          
          /* Si la respuesta es un arreglo se devuelve la respuesta, en caso contrario quiere decir 
          que ocurrio algun error, pues se regresa un objeto con las propiedades "success" y "message" 
          en lugar de un arreglo con los datos, por tanto si se da este caso se establece un arreglo 
          vacio por default al guardar en el store y así evitar errores en consola */
          const result = forceArrayResult(res);

          if (!id) {
            state[CLIENTE_PLAN_Y_PAGOS] = result;
          }

          callback && callback(result);
        });
    },

    INSERT_PAGO: (data, callback = null) => {
      // En esta ruta: 
      /* Se inserta el abono y se actualiza la tabla plan de pagos 
      (solo el trimestre/amortizacion actual) y plan de cliente */

      abonosAPI
        .post(`cliente/createAbono`, data)
        .then(res => {
          callback && callback(res);
        });
    },

    UPDATE_PAGO: (data, callback = null) => {
      // En esta ruta: 
      /* Dependiendo de los datos que se manden, se actualizara la tabla abonos, 
      plan de pagos (solo el trimestre/amortizacion actual) y plan de cliente.
      ! Esta ruta tambien es utilizada por la vista de plan de pagos */

      abonosAPI
        .patch(`cliente/updateAbono`, data)
        .then(res => {
          callback && callback(res);
        });
    },
    /** TERMINA COBRANZA */

    INSERT: (data, callback = null) => {
      // Actualizar datos en la BD y el state
      delete data.id;
      
      abonosAPI
        .post(`cliente`, data)
        .then(res => {
          if (!res.success) {
            if (res.message.includes("llave duplicada") || res.message.includes("duplicate key")) { 
              return $toast.error(
                `Error: el número de cliente ya existe en otro cliente registrado`,
                { timeout: 4500 }
              );
            }
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("INSERTED - CLIENTE: ", res.cliente);

          state[CLIENTES] = [...state[CLIENTES], res.cliente];
          $toast.success(res.message);

          callback && callback(res.cliente.id);
        });
    },

    UPDATE: (data, callback = null) => {
      // Actualizar datos en la BD y el state
      const index = state[CLIENTES].findIndex(cliente => cliente.id === data.id);

      if (data.estatus != null) {
        data.estatus = !data.estatus;
      }

      abonosAPI
        .patch(`cliente`, data)
        .then(res => {
          if (!res.success) {
            if (res.message.includes("llave duplicada") || res.message.includes("duplicate key")) { 
              return $toast.error(
                `Error: el número de cliente ya existe en otro cliente registrado`,
                { timeout: 4500 }
              );
            }
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("UPDATED - CLIENTE: ", res.cliente);

          Object.assign(state[CLIENTES][index], res.cliente);
          $toast.success(res.message);

          callback && callback();

          // Si el cliente se da de baja, dar de baja sus planes
          // if (!data.estatus) {
          //   actions[PLANES_CLIENTE].UPDATE_BY_CRITERIA({ id_cliente: data.id }, { estatus: false });
          // }

          // Si estaba en baja y se da de alta, redirigir a los planes del cliente
          // else {
          //   $router.push({
          //     path: `clientes/${data.id}/planes`,
          //     // query: { isNewClient: true }
          //   });
          // }
        });
    },

    DELETE: (data, callback = null) => {
      const index = state[CLIENTES].findIndex(cliente => cliente.id === data.id);

      const deleteReqBodyCliente = { data: { id: data.id } };
      abonosAPI
        .delete(`cliente`, deleteReqBodyCliente)
        .then(res => {
          if (!res.success) {
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("DELETED - CLIENTE: ", res.cliente);
          
          state[CLIENTES].splice(index, 1);
          $toast.success(res.message);

          callback && callback();

          // Eliminar también sus planes, esto liberará los numeros de poliza que estuvieran usados en los planes relacionados al cliente
          actions[PLANES_CLIENTE].DELETE(data);
        });
    },

  },
});

//======================================================
// GETTERS
//======================================================
const getterModule = (state, computed) => ({
  [CLIENTES]: {
    
    getAll: computed(() => state[CLIENTES]),
    
    getActivos: computed(() => {
      return state[CLIENTES]
        .filter(cliente => cliente.estatus);
    }),

  /** UTILIZADO EN LA VISTA DE COBRANZA */
    getActivosWithPlanYPagos: computed(() => {
      return state[CLIENTE_PLAN_Y_PAGOS];
    }),

    // getAllCobradoresOnly: computed(() => {
    //   const logged_user = state[USERS].find(user => user.id == state[APP].idUser) || {};

    //   switch (logged_user.privilegio) {
    //     case 0:
    //     case 1:
    //     case 2:
    //       return state[CLIENTE_PLAN_Y_PAGOS]
    //         .filter(plan_cliente => plan_cliente.clientes.users.every(user => user.estatus)) || [];
    //     default:
    //       return state[CLIENTE_PLAN_Y_PAGOS]
    //         .filter(plan_cliente => plan_cliente.clientes.users.find(user => user.id == logged_user.id) && plan_cliente.clientes.users.every(user => user.estatus)) || [];
    //         // .filter(plan_cliente => plan_cliente.clientes.users.id == logged_user.id) || [];
    //   }
    // }),
  /** UTILIZADO EN LA VISTA DE COBRANZA */

    findById: (id) => {
      return computed(() => state[CLIENTES]
        .find(cliente => cliente.id == id)) || {};
    },

  },
});

export const clienteActions = actionModule;
export const clienteGetters = getterModule;