import { $dayjs, $router, $toast } from "@/main";
import { state, actions } from "@/store";
import { STORE_NAMESPACES } from "@/store/utils/constants";
import { APP_DEBUG_MODE, FORMA_PAGO, DIAS_SEMANA, MONEDAS } from "@/utils/Constants";

const { APP } = STORE_NAMESPACES;
const { DIARIO, SEMANAL, QUINCENAL, MENSUAL, TRIMESTRAL, SEMESTRAL, ANUAL } = FORMA_PAGO;

function tipoCambioMaxLength(moneda, tipo_cambio) {
  const res = moneda == MONEDAS.UDIS 
    ? (tipo_cambio + "").split('.')[0].length == 1 ? 8 : 9
    : moneda == MONEDAS.USD 
    ? 7 
    : 1

  return res;
}

function addUserLogToSendToDB(tableFormData, userData, tipoLog) {
  const { nombre, apellidos, email, domicilio, localidad, cp, privilegio } = userData;

  tableFormData.userLogData = {
    // el id del registro que recién va a ser insertado/actualizado, si es una inserción se debe actualizar esta prop en el backend
    id_log_relacionado: tableFormData.id || 0,
    tipo_log: tipoLog,
    fecha_log: $dayjs().tz().format('YYYY-MM-DD HH:mm:ss'),
    nombre,
    apellidos,
    email,
    domicilio,
    localidad,
    cp,
    privilegio,
  }
}

// =============================
/* WRAPPERS PARA LOCALSTORAGE */
// =============================
function setStorageItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function getStorageItem(key) {
  try {
    return JSON.parse(localStorage.getItem(key)) || null;
  }
  catch {
    return null;
  }
}

function removeStorageItem(key) {
  localStorage.removeItem(key);
}

function clearStorageItems() {
  localStorage.clear();
}

// ==================================================================
/* FUNCIONES DE AYUDA PARA EL CALCULO DE PLAZOS Y PAGOS PERIODICOS */
// ==================================================================
function esFormaPagoNoTrimestral(forma_pago) {
  if ([SEMESTRAL, ANUAL].includes(forma_pago)) {
    return true;
  }

  return false;
}

function plazoToString(forma_pago) {
  const plazo = {
    capitalized: "Trimestre",
    lower: "trimestre",
  };

  switch(forma_pago) {
    case SEMESTRAL:
      plazo.capitalized = "Semestre";
      plazo.lower = "semestre";
      return plazo;
    case ANUAL:
      plazo.capitalized = "Año";
      plazo.lower = "año";
      return plazo;
    default:
      return plazo;
  }
}

function calcAmortizaciones(forma_pago, anios_aseguradora) {
  switch(forma_pago) {
    case SEMESTRAL:
      return anios_aseguradora * 2;
    case ANUAL:
      return anios_aseguradora;
    default:
      return anios_aseguradora * 4;
  }
}

/** Se debera calcular el plazo en 1 anio y en los anios restantes (valor de "aseguradora_total").
Utilizada solo de momento en los planes de clientes */
function calcularPlazo(anios_aseguradora, aseguradora_total, forma_pago, tipo_cambio, fecha_inicio, restantes = false) {
  if (anios_aseguradora <= 0) {
    return 0;
  }

  const trimestres_al_anio = 4;
  const total_trimestres_aseguradora = trimestres_al_anio * anios_aseguradora;
      
  let plazoCalculado = 0;

  switch (forma_pago) {
    case DIARIO:
      const num_pagos_al_trimestre = calcularNumPagosAlTrimestre(
        forma_pago,
        fecha_inicio,
      );

      plazoCalculado = (aseguradora_total / total_trimestres_aseguradora) / num_pagos_al_trimestre;
      break;
    case SEMANAL:
      plazoCalculado = (aseguradora_total / total_trimestres_aseguradora) / 13;
      break;
    case QUINCENAL:
      plazoCalculado = (aseguradora_total / total_trimestres_aseguradora) / 6;
      break;
    case MENSUAL:
      plazoCalculado = (aseguradora_total / total_trimestres_aseguradora) / 3;
      break;
    case TRIMESTRAL:
      plazoCalculado = (aseguradora_total / total_trimestres_aseguradora);
      break;
    case SEMESTRAL:
      plazoCalculado = (aseguradora_total / 2);
      break;
    case ANUAL:
      plazoCalculado = (aseguradora_total);
      break;
  }

  return currency(plazoCalculado * tipo_cambio);
}

/* Funcion para calcular el pago periodico en la parte de Cobranza (si se cambia la forma de pago 
al iniciar un trimestre). NO SE USA DE MOMENTO EN NINGUNA PARTE DE LA APP */
// function calcularPagoPeriodicoCobranza(pago_moneda_contratada, tipo_cambio, forma_pago, fecha_prox_pago, saldo_pendiente = 0) {
//   const pago_pesos = +pago_moneda_contratada * +tipo_cambio;
//   let plazoCalculado = 0;

//   switch (forma_pago) {
//     case DIARIO:
//       const pago_inicial = $dayjs(fecha_prox_pago);
//       const pago_final = $dayjs(pago_inicial).add(3, "month");
//       const total_dias_diferencia = Math.abs(pago_inicial.diff(pago_final, "day"));
      
//       let dias_no_habiles = 0;
//       for (let index = 0; index < total_dias_diferencia; index++) {
//         if (esDomingo(pago_inicial, (index))) {
//           dias_no_habiles += 1;
//         }
//       }
      
//       plazoCalculado = (pago_pesos + +saldo_pendiente) / (total_dias_diferencia - dias_no_habiles);
//       break;
//     case SEMANAL:
//       plazoCalculado = (pago_pesos + +saldo_pendiente) / 13;
//       break;
//     case QUINCENAL:
//       plazoCalculado = (pago_pesos + +saldo_pendiente) / 6;
//       break;
//     case MENSUAL:
//       plazoCalculado = (pago_pesos + +saldo_pendiente) / 3;
//       break;
//     case TRIMESTRAL:
//       plazoCalculado = (pago_pesos + +saldo_pendiente);
//       break;

//     // Realmente no se utilizaria el case SEMESTRAL o ANUAL, ej:
//     /**
//      * Si se selecciona cualquiera de DIARIO a TRIMESTRAL, no se podran seleccionar la SEMESTRAL o ANUAL
//      * Si se selecciona SEMESTRAL o ANUAL, no se podra cambiar a ninguna de las anteriores
//      * Si se selecciona SEMESTRAL, no se podra cambiar a ANUAL y viceversa
//      */
//     case SEMESTRAL:
//       plazoCalculado = (pago_pesos + +saldo_pendiente) * 2;
//       break;
//     case ANUAL:
//       plazoCalculado = (pago_pesos + +saldo_pendiente) * 4;
//       break;
//   }

//   return currency(plazoCalculado);
// }

// Calcula los pagos que se necesitan para cerrar el trimestre dependiendo de la forma de pago
// !!! Si se modifica esta funcion, debe modificarse la del mismo nombre en el server
function calcularNumPagosAlTrimestre(forma_pago, fecha_inicio = null) {
  let pagosAlTrimestre = 0;

  switch (forma_pago) {
    case DIARIO:
      const pago_inicial = fecha_inicio ? $dayjs(fecha_inicio) : $dayjs();
      const pago_final = $dayjs(pago_inicial).add(3, "month");
      const total_dias_diferencia = Math.abs(pago_inicial.diff(pago_final, "day"));
      
      let dias_no_habiles = 0;
      for (let index = 0; index < total_dias_diferencia; index++) {
        if (esDomingo(pago_inicial, (index))) {
          dias_no_habiles += 1;
        }
      }
      
      pagosAlTrimestre = (+total_dias_diferencia - +dias_no_habiles);
      break;
    case SEMANAL:
      pagosAlTrimestre = 13;
      break;
    case QUINCENAL:
      pagosAlTrimestre = 6;
      break;
    case MENSUAL:
      pagosAlTrimestre = 3;
      break;
    case TRIMESTRAL:
      pagosAlTrimestre = 1;
      break;
    
    // Aqui ya se maneja el plazo por año
    case SEMESTRAL:
      pagosAlTrimestre = 1;
      break;
    case ANUAL:
      pagosAlTrimestre = 1;
      break;
  }

  return Number(pagosAlTrimestre);
}

/* El dato de la "fecha_pago_actual" se tomará en principio de la "fecha_inicial" en el plan del cliente, 
posteriormente se tomará de la fecha del pago actual del abono cuando ya se tenga al menos 1 registrado */
// !!! Si se modifica esta funcion, debe modificarse la del mismo nombre en el server
// Prop "dia_pago_establecido" es exclusiva del case "SEMANAL"
// Prop "num_dia_pago_establecido" es exclusiva del case "MENSUAL"
function calcularSigPago(forma_pago, fecha_pago_actual, dia_pago_establecido = "", registro_inicial = false, num_dia_pago_establecido = 0) {
  const endOfMonth = endOfMonthNumDia(fecha_pago_actual);
  let siguientePago = '';

  switch (forma_pago) {
    case DIARIO:
      /* Si se trata del registro inicial en el plan de clientes, la misma fecha inicial se toma como
      fecha de Prox. pago */
      if (registro_inicial) {
        siguientePago = $dayjs(fecha_pago_actual).add(0, "day");
        break;
      }

      siguientePago = $dayjs(fecha_pago_actual).add(1, "day");
      
      // Si la proxima fecha de abono cae en domingo, saltar 1 dia para setearla para el prox. lunes
      if (esDomingo(siguientePago)) {
        siguientePago = $dayjs(siguientePago).add(1, "day");
      }
      break;

    case SEMANAL:
      /* Si se trata del registro inicial en el plan de clientes, la misma fecha inicial se toma como
      fecha de Prox. pago */
      if (registro_inicial) {
        siguientePago = $dayjs(fecha_pago_actual).add(0, "day");
        break;
      }

      // Si la fecha de inicio/ultimo pago coincide con el dia de pago, setear la proxima fecha de abono en 7 dias
      if (getDiaFromFecha(fecha_pago_actual, 0) == dia_pago_establecido) {
        siguientePago = $dayjs(fecha_pago_actual).add(7, "day");
      }

      /* else: Si la fecha de inicio/ultimo pago NO coincide con el dia de pago:
       * ej. fecha de inicio/ultimo pago es un lunes
       * y el dia de pago se establece en un jueves
       * entonces: setear la proxima fecha de abono el dia jueves sig. (3 dias despues)
       * y a partir de ahi setear las proximas fechas en 7 dias 
       * (en los cuales la fecha de inicio/ultimo pago volvera a coincidir con el dia de pago)
       */
      else {
        for (let index = 1; index < 7; index++) {
          if ( getDiaFromFecha(fecha_pago_actual, index) == dia_pago_establecido ) {
            siguientePago = $dayjs(fecha_pago_actual).add(index, "day");
            break;
          }
        }
      }
      break;

    case QUINCENAL:
      /* Si se trata del registro inicial en el plan de clientes, la misma fecha inicial se toma como
      fecha de Prox. pago */
      if (registro_inicial) {
        siguientePago = $dayjs(fecha_pago_actual).add(0, "day");
        break;
      }

      // Si la fecha del abono actual es < a 15, setear la proxima fecha de abono en el 15
      if (numDiaFromFecha(fecha_pago_actual) < 15) {
        siguientePago = $dayjs(fecha_pago_actual).set("date", 15);

        // Si el dia 15 cae en domingo, setear la proxima fecha de abono al 16
        if (esDomingo(siguientePago)) {
          siguientePago = $dayjs(siguientePago).add(1, "day");
        }
      }

      // Si la fecha del abono actual es >= 15 y < al final del mes, setear la proxima fecha de abono en el fin del mes
      else if (numDiaFromFecha(fecha_pago_actual) >= 15 && numDiaFromFecha(fecha_pago_actual) < endOfMonth) {
        siguientePago = $dayjs(fecha_pago_actual).endOf("month");

        // Si el fin de mes cae en domingo, setear la proxima fecha de abono al 01 del sig. mes
        if (esDomingo(siguientePago)) {
          siguientePago = $dayjs(siguientePago).add(1, "day");
        }
      }
      // Si la fecha del abono actual cae el fin de mes, setear la proxima fecha de abono en el 15 del sig. mes
      else if (numDiaFromFecha(fecha_pago_actual) == endOfMonth) {
        siguientePago = $dayjs(fecha_pago_actual).add(1, "month").set("date", 15);

        // Si el dia 15 del sig. mes cae en domingo, setear la proxima fecha de abono al 16 del sig. mes
        if (esDomingo(siguientePago)) {
          siguientePago = $dayjs(siguientePago).add(1, "day");
        }
      }
      break;
      
    case MENSUAL:
      /* Si se trata del registro inicial en el plan de clientes, la misma fecha inicial se toma como
      fecha de Prox. pago */
      if (registro_inicial) {
        siguientePago = $dayjs(fecha_pago_actual).add(0, "day");
        break;
      }

      // num_dia_pago_establecido > 0 se debe actualizar la fecha al num de dia establecido
      if (num_dia_pago_establecido > 0) {
        // Si la fecha del abono actual es < al num. dia establecido, setear la proxima fecha de abono en el dia establecido
        if (numDiaFromFecha(fecha_pago_actual) < num_dia_pago_establecido) {
          siguientePago = $dayjs(fecha_pago_actual).set("date", num_dia_pago_establecido);

          // Si cae en domingo, setear la proxima fecha de abono a 1 dia despues
          if (esDomingo(siguientePago)) {
            siguientePago = $dayjs(siguientePago).add(1, "day");
          }
        }

        // Si la fecha del abono actual es > o cae en el dia establecido, setear la proxima fecha de abono en el dia establecido del sig. mes
        else if (numDiaFromFecha(fecha_pago_actual) >= num_dia_pago_establecido) {
          siguientePago = $dayjs(fecha_pago_actual).add(1, "month").set("date", num_dia_pago_establecido);
          
          // Si cae en domingo, setear la proxima fecha de abono a 1 dia despues
          if (esDomingo(siguientePago)) {
            siguientePago = $dayjs(siguientePago).add(1, "day");
          }
        }

      }
      // num_dia_pago_establecido = 0 es el comportamiento de pagos al mes por default, se debe actualizar la fecha al fin de mes
      else {
        // Si la fecha del abono actual es < al fin de mes, setear la proxima fecha de abono en el fin del mes
        if (numDiaFromFecha(fecha_pago_actual) < endOfMonth) {
          siguientePago = $dayjs(fecha_pago_actual).endOf("month");
  
          // Si el fin de mes cae en domingo, setear la proxima fecha de abono al 01 del sig. mes
          if (esDomingo(siguientePago)) {
            siguientePago = $dayjs(siguientePago).add(1, "day");
          }
        }

        // Si la fecha del abono actual cae el fin de mes, setear la proxima fecha de abono en el fin del sig. mes
        else if (numDiaFromFecha(fecha_pago_actual) == endOfMonth) {
          siguientePago = $dayjs(fecha_pago_actual).add(1, "month").endOf("month");
          
          // Si el fin de mes cae en domingo, setear la proxima fecha de abono al 01 del sig. mes 
          // Ej. abono actual se hace el 31, el abono prox. seria el 31 del sig. mes, pero cae domingo y se pasa al 01 del sig. mes
          if (esDomingo(siguientePago)) {
            siguientePago = $dayjs(siguientePago).add(1, "day");
          }
        }

      }
      break;

    case TRIMESTRAL:
      /* Si se trata del registro inicial en el plan de clientes, la misma fecha inicial se toma como
      fecha de Prox. pago */
      if (registro_inicial) {
        siguientePago = $dayjs(fecha_pago_actual).add(0, "day");
        break;
      }

      siguientePago = $dayjs(fecha_pago_actual).add(3, "month").endOf("month");

      // Si el fin de trimestre cae en domingo, setear la proxima fecha de abono un dia despues
      if (esDomingo(siguientePago)) {
        siguientePago = $dayjs(siguientePago).add(1, "day");
      }
      break;

    case SEMESTRAL:
      /* Si se trata del registro inicial en el plan de clientes, la misma fecha inicial se toma como
      fecha de Prox. pago */
      if (registro_inicial) {
        siguientePago = $dayjs(fecha_pago_actual).add(0, "day");
        break;
      }

      siguientePago = $dayjs(fecha_pago_actual).add(6, "month").endOf("month");

      // Si el fin de semestre cae en domingo, setear la proxima fecha de abono un dia despues
      if (esDomingo(siguientePago)) {
        siguientePago = $dayjs(siguientePago).add(1, "day");
      }
      break;

    case ANUAL:
      /* Si se trata del registro inicial en el plan de clientes, la misma fecha inicial se toma como
      fecha de Prox. pago */
      if (registro_inicial) {
        siguientePago = $dayjs(fecha_pago_actual).add(0, "day");
        break;
      }

      siguientePago = $dayjs(fecha_pago_actual).add(1, "year"); //.subtract(1, "day");

      // Si el fin de año cae en domingo, setear la proxima fecha de abono un dia despues
      if (esDomingo(siguientePago)) {
        siguientePago = $dayjs(siguientePago).add(1, "day");
      }
      break;
  }
  return $dayjs(siguientePago);
}

/** Funcion de ayuda para obtener la proxima fecha de pago dependiendo de la forma de pago, 
la fecha de pago actual,  y el dia de pago (si aplica). Utilizar como computed property */
// !!! Si se modifica esta funcion, debe modificarse la del mismo nombre en el server
function dateProxPago(fecha_pago_actual, forma_pago, dia_pago_establecido = "", registro_inicial = false, num_dia_pago_establecido = 0) {
  return forma_pago
    ? calcularSigPago(
        forma_pago, 
        fecha_pago_actual,
        dia_pago_establecido,
        registro_inicial,
        num_dia_pago_establecido,
      )
    : '';
}

// ===========================================================
/* FUNCIONES DE AYUDA NO RELACIONADAS AL CALCULO DE PLAZOS */
// ===========================================================
function sortById (a, b) {
  if (a.id > b.id) {
    return 1;
  }
  if (a.id < b.id) {
    return -1;
  }

  return 0;
}

const sortByProp = (prop, reverse = false) => (a, b) => {
  if (a[prop] > b[prop]) {
    return reverse ? -1 : 1;
  }
  if (a[prop] < b[prop]) {
    return reverse ? 1 : -1;
  }

  return 0;
}

function logout() {
  APP_DEBUG_MODE && console.info("Se ha hecho clic en Salir: destruyendo sesión y actualizando el store a los valores iniciales por default");
  APP_DEBUG_MODE && console.info("[Functions.js]: logout() => [APP]: SET_DEFAULTS()");

  actions[APP].SET_DEFAULTS();
  removeStorageItem("auth-token");
  removeStorageItem("id-user");

  $router.replace("/login");
}

// Comprueba si un objeto (o arreglo) esta vacio
function isEmptyObj(obj) {
  if (Array.isArray(obj)) {
    return !!obj.length;
  }

  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

function isObj(obj) {
  return typeof obj === "object" && obj.constructor === Object;
}

/* Funcion utilizada en todos los FETCH del store. Si el data resultante de la respuesta del servidor es un arreglo, 
se regresa tal cual, de lo contrario se regresa un arreglo vacío por default */
function forceArrayResult(data) {
  if (isObj(data) && !data.success) {
    state[APP].serverError = true;
  }
  else if (Array.isArray(data)) {
    state[APP].serverError = false;
  }

  if (state[APP].appFullyLoaded && state[APP].serverError) {
    $toast.error(`Ha ocurrido un error: No se pudo establecer la conexión con el servidor.`, { timeout: 4500 });
  }

  return Array.isArray(data) ? data : [];
}

// Formatea el dia de pago para que se muestre con acentos, y opcionalmente con la primera letra en mayusculas
function formatDiaPago(dia_pago, capitalizeWord = false) {
  let formattedDiaPago = dia_pago || "";

  switch (dia_pago) {
    case "miercoles":
      formattedDiaPago = "miércoles";
      break;
    case "sabado":
      formattedDiaPago = "sábado";
      break;
  }

  if (capitalizeWord) {
    formattedDiaPago = capitalize(formattedDiaPago);
  }

  return formattedDiaPago;
}

function capitalize(str, lower = true) {
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
}

function capitalizeFirst(str) {
  return str.replace(/^\w/, c => c.toUpperCase());
}

// Elimina los diacríticos de un texto excepto si es una "ñ" (ES6)
// !!! Si se modifica esta funcion, debe modificarse la del mismo nombre en el server
function normalizeStr(str) {
  const resultStr = str
    .normalize("NFD")
    .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1")
    .normalize();
    
  return resultStr;
}

function splitCamelCase(string) {
  return string
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([a-z])/g, " $1$2")
    .replace(/ +/g, " ")
    .replace(/^./, (str) => str.toUpperCase());
};

function initObjPropsToNull(object) {
  return object
    ? Object.keys(object).reduce((obj, val) => ({ ...obj, [val]: null }), {})
    : {};
}

// Convierte cualquier dato en un número con un máximo de decimales
function currency(any, dec = 2, round = false) {
  if (dec == null) {
    dec = 12;
  }

  let num = Number(any) || 0;
  let str = num + "";
  let pointIndex = str.indexOf(".");

  if (pointIndex >= 0) {
    let i = pointIndex + dec + 1;

    if (str.charAt(i) == "5") {
      num += 1 / Math.pow(10, dec + 1);
    }
  }

  if (round) {
    const nearest = 2; // cambiar a 4 si se requiere que sea de .25 en .25
    const roundToFiftyCents = (Math.round(num * nearest) / nearest);

    return +roundToFiftyCents.toFixed(dec);
  }

  return +num.toFixed(dec) == 0 ? 0 : +num.toFixed(dec);
}

// opts: {moneda: "", dec: 2}
function formatCurrency(value, opts = {}) {
  let { moneda = "MXN", dec = 2, prefix = "$" } = opts;

  // Principalmente usado para manejar los decimales dependiendo del tipo de moneda
  if (moneda == MONEDAS.UDIS) {
    dec = 6;
  }
  else if (moneda == MONEDAS.USD) {
    dec = 4;
  }

  const num = Number(value) || 0;
  let str = (num.toFixed(dec) == 0 ? 0 : num.toFixed(dec)) + "";
  const pointIndex = str.indexOf(".");
  
  if (pointIndex < 0) 
    str += ".00";
  else if (str.length - pointIndex -1 == 1) 
    str += "0";
  
  str = str.replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  
  // if (moneda == "MXN")
  //   return "$ " + str;
  
  // if (moneda == "EUR")
  //   return str + " €";

  // if (moneda)
  //   return `${str} ${moneda}`;
  
  if (prefix)
    return `$ ${str}`;
  
  return str;
}

function truncateStr(any, length) {
  let val = (any == null) ? "" : any + "";
  
  if (val.length > length) 
    return val.substring(0, length) + "...";
  else
    return val;
}

// Formatea una fecha de YYYY-MM-DD a DD/MM/YYYY
function formatDate(date) {
  if (!date) return null;

  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}`;
}

// Formatea una fecha de DD/MM/YYYY a YYYY-MM-DD con el "time" como opcional
// function parseDate(date, splitChar = '/', time = false) {
//   if (!date) return null;

//   const [day, month, year] = date.split(splitChar)
//   let parsedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;

//   if (time)
//     parsedDate += ` ${$dayjs().hour()}:${$dayjs().minute()}:${$dayjs().second()}`;
  
//   return parsedDate;
// }

// =====================================================
/* FUNCIONES DE AYUDA RELACIONADAS A MANEJO DE FECHAS */
// =====================================================
// Obtiene el número del dia en el último dia del mes de una fecha determinada
// !!! Si se modifican esta funciones, deben modificarse las del mismo nombre en el server
const endOfMonthNumDia = (fecha) => Number($dayjs(fecha).endOf("month").format("D"));

// Obtiene el número del dia en el mes de una fecha determinada
const numDiaFromFecha = (fecha) => {
  return Number($dayjs(fecha).format('D'));
}

// Obtiene el nombre del dia de una fecha determinada
const getDiaFromFecha = (fecha, offsetValue = 0, offsetType = "day") => {
  return normalizeStr($dayjs(fecha).add(offsetValue, offsetType).format('dddd'));
}

// Comprueba si una fecha en concreto cae en domingo
const esDomingo = (fecha, offsetValue = 0, offsetType = "day") => {
  const dia_domingo = normalizeStr(DIAS_SEMANA.DOM);
  return getDiaFromFecha(fecha, offsetValue, offsetType) == dia_domingo;
}

const dateUtils = {
  endOfMonthNumDia,
  numDiaFromFecha,
  getDiaFromFecha,
  esDomingo,
}
/** ======================================================= */

// Codificado a base64
function btoaEncodeData(data) {
  // Utilizar Buffer si se permite, de lo contrario utilizar window.btoa
  const encoded = typeof Buffer != 'undefined'
    ? Buffer.from(JSON.stringify(data), 'utf8').toString('base64') 
    : window.btoa(JSON.stringify(data));

  return encoded;
}

// Decodificado de base64
function btoaDecodeData(data) {
  // Utilizar Buffer si se permite, de lo contrario utilizar window.atob
  const decoded = typeof Buffer != 'undefined'
    ? JSON.parse(Buffer.from(data, 'base64').toString('utf8'))
    : JSON.parse(window.atob(data));

  return decoded;
}

function debounce(callback, wait = 500) {
  let timeout

  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => callback(...args), wait)
  }
}

function activateFullPageLoading(textVar, loaderVar) {
  if (textVar.value != "Procesando...") {
    textVar.value = "Procesando...";
  }

  if (!loaderVar.value) {
    loaderVar.value = true;
  }
}

function updateFullPageLoadingText(textVar, newText = '') {
  textVar.value = newText || 'Actualizando tabla...';
}

function disableFullPageLoading(loaderVar) {
  loaderVar.value = false;
}

// Se exportan las funciones
export {
  activateFullPageLoading,
  updateFullPageLoadingText,
  disableFullPageLoading,

  esFormaPagoNoTrimestral,
  plazoToString,
  calcAmortizaciones,

  setStorageItem,
  getStorageItem,
  removeStorageItem,
  clearStorageItems,

  tipoCambioMaxLength,
  addUserLogToSendToDB,
  
  btoaEncodeData,
  btoaDecodeData,
  formatDiaPago,
  sortById,
  sortByProp,
  logout,
  normalizeStr,
  truncateStr,
  isEmptyObj,
  isObj,
  forceArrayResult,
  capitalize,
  capitalizeFirst,
  formatDate,
  // parseDate,
  currency,
  formatCurrency,
  calcularPlazo,
  // calcularSigPago,
  dateProxPago,
  // calcularPagoPeriodicoCobranza,
  calcularNumPagosAlTrimestre,
  splitCamelCase,
  initObjPropsToNull,
  dateUtils,
  debounce,
};