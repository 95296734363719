// Checar por actualizaciones en dependencias del proyecto: npm i -g npm-check-updates@5.0.0 => ncu -u -x eslint,prettier

// import hooks from '@u3u/vue-hooks';
// import "./registerServiceWorker";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
// import VueCompositionApi from "vue";
import App from "./App.vue";
// import { state } from "@/store";
import { router /*, setStore*/ } from "./router";
// setStore(state);

import VueElementLoading from './components/ui/VueElementLoading';
const Jumbotron = async () => await import(`./components/ui/Jumbotron.vue`)
// import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';
import vuetify from "./plugins/vuetify";
import Toast from "vue-toastification";
import VTooltip from 'v-tooltip';
import { vueToastificationOpts, vTooltipOpts, /*loadingOpts*/ } from "@/utils/PluginConfigOpts";
import "vue-toastification/dist/index.css";
import Dayjs from 'vue-dayjs';
import 'dayjs/locale/es';

Vue.config.productionTip = false;

// Vue.use(Loading, loadingOpts);          // https://github.com/ankurk91/vue-loading-overlay
Vue.use(Toast, vueToastificationOpts);  // https://github.com/Maronato/vue-toastification
Vue.use(VTooltip, vTooltipOpts);        // https://github.com/Akryum/v-tooltip
Vue.use(Dayjs);                         // https://github.com/iamkun/dayjs/blob/30d2f026b47188833a4f44fee4bab52467d4a718/docs/en/API-reference.md#api-reference
// Vue.use(VueCompositionApi);             // https://github.com/vuejs/composition-api
// Vue.use(hooks);                      // https://github.com/u3u/vue-hooks#hooks

Vue.component('VueElementLoading', VueElementLoading);
Vue.component('Jumbotron', Jumbotron);
Vue.component("downloadExcel", JsonExcel);

const vm = new Vue({
  router,
  vuetify,
  render: h => {
    return h(App);
  }
}).$mount("#app");

import utc from 'dayjs/plugin/utc'; // dependent on utc plugin
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
vm.$dayjs.extend(utc);
vm.$dayjs.extend(timezone);
vm.$dayjs.locale("es");

const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
vm.$dayjs.tz.setDefault(clientTimezone);

console.warn('clientTimezone: ', clientTimezone);

const { $toast, $dayjs, $route, $router } = vm;
export {
  $toast,
  $dayjs,
  $route,
  $router,
};