<template>
  <v-main>
    <v-container>
      <!-- Menu bar -->
      <Header @drawer-toggle="drawer = !drawer" />

      <Sidedrawer :drawerVal.sync="drawer" />

      <transition mode="out-in" name="fadeHome">
        <router-view />
      </transition>

      <Footer />
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
import Header from "@/layout/Header";
import Footer from "@/layout/Footer";
import Sidedrawer from "@/layout/Sidedrawer";
import { ref } from 'vue';
import { onBeforeUpdate, computed } from "vue";

export default {
  components: {
    Header,
    Footer,
    Sidedrawer
  },
  setup(props, ctx) {
    const drawer  = ref(null);

    return {
      drawer,
    }
  }
};
</script>

<style lang="scss" scoped>
// Vue router transition
.fadeHome-enter-active,
.fadeHome-leave-active {
  transition: opacity, transform 0.15s;
}
.fadeHome-enter,
.fadeHome-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
