import { abonosAPI } from "@/utils/Axios";
import { forceArrayResult } from "@/utils/Functions";
import { STORE_NAMESPACES } from "@/store/utils/constants";
import { APP_DEBUG_MODE } from "@/utils/Constants";

const { ABONOS } = STORE_NAMESPACES;

//======================================================
// ACTIONS
//======================================================
const actionModule = (state) => ({
  [ABONOS]: {

    SET_DEFAULT: () => {
      state[ABONOS] = [];
    },
    
    FETCH_ALL_BY_DATE: (fechaSelecc, callback = null) => {
      abonosAPI
        .get(`abonos/${fechaSelecc}`)
        .then(res => {
          APP_DEBUG_MODE && console.log(`FETCHED ALL - ABONOS BY DATE (${fechaSelecc}): `, res);
          
          /* Si la respuesta es un arreglo se devuelve la respuesta, en caso contrario quiere decir 
          que ocurrio algun error, pues se regresa un objeto con las propiedades "success" y "message" 
          en lugar de un arreglo con los datos, por tanto si se da este caso se establece un arreglo 
          vacio por default al guardar en el store y así evitar errores en consola */
          const result = forceArrayResult(res);

          state[ABONOS] = result;
          callback && callback(result);
        });
    },

    CANCEL_MULTIPLE: (data, callback = null) => {
      // En esta ruta: 
      /* Se actualizaran la tabla abonos, plan de pagos (solo el trimestre/amortizacion actual) 
      y el plan de cliente.
      ! Utilizado en la vista de plan de pagos */

      abonosAPI
        .patch(`abonos/cancelMultiple`, data)
        .then(res => {
          callback && callback(res);
        });
    },

  }
});

//======================================================
// GETTERS
//======================================================
const getterModule = (state, computed) => ({
  [ABONOS]: {
    
    getAllByDate: computed(() => state[ABONOS]),

  },
});

export const abonoActions = actionModule;
export const abonoGetters = getterModule;