import { abonosAPI } from "@/utils/Axios";
import { forceArrayResult } from "@/utils/Functions";
import { $toast } from "@/main";
import { STORE_NAMESPACES } from "@/store/utils/constants";
import { APP_DEBUG_MODE } from "@/utils/Constants";
import { actions } from "@/store";

const { PLANES_CLIENTE, PLAN_PAGOS, /*CLIENTE_PLAN_Y_PAGOS*/ } = STORE_NAMESPACES;

//======================================================
// ACTIONS
//======================================================
const actionModule = (state) => ({
  [PLANES_CLIENTE]: {

    SET_DEFAULT: () => {
      state[PLANES_CLIENTE] = [];
    },
    
    FETCH: (idCliente, callback = null) => {
      abonosAPI
        .get(`planes_cliente/${idCliente}`)
        .then(res => {
          APP_DEBUG_MODE && console.log("FETCHED - PLAN_CLIENTE: ", res);
          
          /* Si la respuesta es un arreglo se devuelve la respuesta, en caso contrario quiere decir 
          que ocurrio algun error, pues se regresa un objeto con las propiedades "success" y "message" 
          en lugar de un arreglo con los datos, por tanto si se da este caso se establece un arreglo 
          vacio por default al guardar en el store y así evitar errores en consola */
          const result = forceArrayResult(res);
          state[PLANES_CLIENTE] = result;

          callback && callback(result);
        });
    },

    // Se traen todos los planes vigentes de actuerdo a la fecha de inicio y fin de contrato (aseguradora)
    FETCH_SALDOS: (tipo_saldo, callback = null) => {
      abonosAPI
        .get(`planes_cliente_saldos/${tipo_saldo}`)
        .then(res => {
          APP_DEBUG_MODE && console.log(`FETCHED_SALDOS (${tipo_saldo}) - PLANES_CLIENTE: `, res.plan_cliente);
          
          const result = forceArrayResult(res.plan_cliente);
          state[PLANES_CLIENTE] = result;

          callback && callback(res.totalTipoSaldo);
        });
    },

    // Se traen todos los planes vigentes de actuerdo a la fecha de inicio y fin de contrato (aseguradora)
    FETCH_ALL_VIGENTES: (currentDate, fetchWithClientes = true) => {
      abonosAPI
        .get(`planes_cliente_vigentes/${currentDate}/${fetchWithClientes}`)
        .then(res => {
          APP_DEBUG_MODE && console.log("FETCHED_ALL_VIGENTES - PLANES_CLIENTE: ", res);
          
          const result = forceArrayResult(res);
          state[PLANES_CLIENTE] = result;
        });
    },

    // Se traen todos los planes vigentes de actuerdo a la fecha de inicio y fin de contrato (aseguradora)
    FETCH_ALL_VIGENTES_IN_BETWEEN: (currentDate, currentDateWithOffset, fetchWithClientes = true) => {
      abonosAPI
        .get(`planes_cliente_vigentes_in_between/${currentDate}/${currentDateWithOffset}/${fetchWithClientes}`)
        .then(res => {
          APP_DEBUG_MODE && console.log("FETCH_ALL_VIGENTES_IN_BETWEEN - PLANES_CLIENTE: ", res);
          
          const result = forceArrayResult(res);
          state[PLANES_CLIENTE] = result;
        });
    },

    FETCH_ALL_VENCIDOS: (currentDate, fetchWithClientes = true) => {
      abonosAPI
        .get(`planes_cliente_vencidos/${currentDate}/${fetchWithClientes}`)
        .then(res => {
          APP_DEBUG_MODE && console.log("FETCHED_ALL_VENCIDOS - PLANES_CLIENTE: ", res);
          
          const result = forceArrayResult(res);
          state[PLANES_CLIENTE] = result;
        });
    },

    INSERT: (data, callback = null) => {
      delete data.id;

      abonosAPI
        .post(`plan_cliente`, data)
        .then(res => {
          if (!res.success) {
            callback && callback(res.success);

            if (res.message.includes("llave duplicada") || res.message.includes("duplicate key")) { 
              return $toast.error(
                `Error: el número de póliza ya existe en otro plan de cliente`,
                { timeout: 4500 }
              );
            }
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("INSERTED - PLANES_CLIENTE: ", res.plan_cliente);

          state[PLANES_CLIENTE] = [...state[PLANES_CLIENTE], res.plan_cliente];
          
          // Guardar el plan de pagos
          res.plan_cliente.trimestres_fields = data.trimestres_fields;
          res.plan_cliente.tipo_cambio_trimestre_actual_migr = data.tipo_cambio_trimestre_actual_migr;
          actions[PLAN_PAGOS].INSERT(res.plan_cliente, (resPPagosSuccess) => {
            callback && callback(resPPagosSuccess);
            resPPagosSuccess && $toast.success(res.message);
          });

        });
    },

    UPDATE: (data, callback = null) => {
      const index = state[PLANES_CLIENTE].findIndex(plan => plan.id === data.id);
      if (data.estatus != null) {
        data.estatus = !data.estatus;
      }

      abonosAPI
        .patch(`plan_cliente`, data)
        .then(res => {
          if (!res.success) {
            callback && callback(res.success);

            if (res.message.includes("llave duplicada") || res.message.includes("duplicate key")) { 
              return $toast.error(
                `Error: el número de póliza ya existe en otro plan de cliente`,
                { timeout: 4500 }
              );
            }
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("UPDATED - PLANES_CLIENTE: ", res.plan_cliente);

          Object.assign(state[PLANES_CLIENTE][index], res.plan_cliente);

          // Actualizar el plan de pagos mientras no haya sido inicializado
          // if (!res.plan_cliente.plan_iniciado) {
            res.plan_cliente.trimestres_fields = data.trimestres_fields;
            res.plan_cliente.tipo_cambio_trimestre_actual_migr = data.tipo_cambio_trimestre_actual_migr;
            actions[PLAN_PAGOS].UPDATE(res.plan_cliente, (resPPagosSuccess) => {
              callback && callback(resPPagosSuccess);
              resPPagosSuccess && $toast.success(res.message);
            });
          // }
          
        });
    },

    UPDATE_ON_SUSPEND_DEACTIVATION: (data, callback = null) => {
      const index = state[PLANES_CLIENTE].findIndex(plan => plan.id === data.plan_cliente.id);
      if (data.plan_cliente.estatus != null) {
        data.plan_cliente.estatus = !data.plan_cliente.estatus;
      }

      abonosAPI
        .patch(`plan_cliente_update_on_suspend_deactivation`, data)
        .then(res => {
          if (!res.success) {
            callback && callback(res.success);
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("UPDATED - UPDATE_ON_SUSPEND_DEACTIVATION (PLAN CLIENTE): ", res.plan_cliente);

          Object.assign(state[PLANES_CLIENTE][index], res.plan_cliente);
          $toast.success(res.message);

          callback && callback(res.success);
        });
    },

    // UPDATE_BY_CRITERIA: (criteria, dataToUpdate) => {
    //   const data = {
    //     criteria,
    //     dataToUpdate,
    //   };

    //   abonosAPI
    //     .patch(`plan_cliente/updateByCriteria`, data)
    //     .then(res => {
    //       APP_DEBUG_MODE && console.log("UPDATE_BY_CRITERIA - PLANES_CLIENTES: ", res);
    //     });
    // },

    DELETE: (data, callback = null) => {
      const index = state[PLANES_CLIENTE].findIndex(plan_cliente => plan_cliente.id === data.id);
      const planesClienteExists = state[PLANES_CLIENTE].length;

      /* Si se tienen planes de clientes quiere decir que se estan eliminando desde la vista de PlanesClientes, y por tanto se manda el
      "id" del plan del cliente a eliminar. De lo contrario quiere decir que se esta eliminando al cliente y con él todos sus planes,
      por lo que todos los que tengan el "id_cliente" del cliente serán eliminados */
      // Nota: si se elimina desde la vista de Clientes, no se eliminará el plan de pagos, sólo el plan de cliente
      const deleteReqBodyPlanCliente = { data: { [planesClienteExists ? "id" : "id_cliente"]: data.id } };
      abonosAPI
        .delete(`plan_cliente`, deleteReqBodyPlanCliente)
        .then(res => {
          if (!res.success) {
            return $toast.error(`Ha ocurrido un error: ${res.message}`);
          }

          APP_DEBUG_MODE && console.log("DELETED - PLANES_CLIENTES: ", res);

          // Ejecutar código sólo en vista de PlanCliente
          if (planesClienteExists) {
            // Eliminar los planes de pago asociados al plan de cliente eliminado
            // (de momento desactivado porque se ocupa para el historial de corte del dia)
            // actions[PLAN_PAGOS].DELETE(data);

            // Actualizar la tabla de planes cliente sin el registro eliminado
            state[PLANES_CLIENTE].splice(index, 1);
            $toast.success(res.message);
          }
          
          callback && callback();
        });
    },

  }
});

//======================================================
// GETTERS
//======================================================
const getterModule = (state, computed) => ({
  [PLANES_CLIENTE]: { 
    
    getAll: computed(() => state[PLANES_CLIENTE]),

    getActivos: computed(() => {
      return state[PLANES_CLIENTE]
        .filter(plan_cliente => plan_cliente.estatus && !plan_cliente.suspendido) || [];
    }),
    
    filterByClient: (clienteId) => {
      return computed(() => state[PLANES_CLIENTE]
        .filter(plan_cliente => plan_cliente.id_cliente == clienteId)) || [];
    },

    findById: (id) => {
      return computed(() => state[PLANES_CLIENTE]
        .find(plan_cliente => plan_cliente.id == id)) || {};
    },

  },
});

export const planClienteActions = actionModule;
export const planClienteGetters = getterModule;