<template>
  <v-footer 
    v-if="!$vuetify.breakpoint.xs" 
    color="blue-grey" 
    class="white--text"
    elevation 
    app 
  >
    <span class="caption"
      >Aplicación para el control de abonos de planes con seguro.</span
    >
    <v-spacer />
    <span 
      style="cursor: default;"
      class="d-flex caption"
    > 
      <v-icon
        v-if="hasTopPrivileges"
        v-tooltip.left-end="hasTopPrivileges ? CHANGELOG : ''"
        :size="16"
        class="mr-1"
        dark
      >
        mdi-alert-circle-outline
      </v-icon>
        {{ LAST_UPDATED }}
        {{ ''/*APP_VERSION*/ }}
    </span>
  </v-footer>
</template>

<script>
import { LAST_UPDATED, CHANGELOG, /*APP_VERSION*/ } from "@/utils/Constants";
import { getters, STORE_NAMESPACES } from "@/store";

export default {
  setup(props, ctx) {
    const { APP } = STORE_NAMESPACES;

    const isAdmin   = getters[APP].getIsAdmin.value;
    const isDevUser = getters[APP].getIsDevUser.value;
    const hasTopPrivileges = isAdmin || isDevUser;    

    return {
      LAST_UPDATED,
      CHANGELOG,
      hasTopPrivileges,
      // APP_VERSION,
    };
  }
};
</script>

