import { computed, reactive } from "vue";
import { $dayjs } from "@/main";

// offset: cantidad => offsetType: dias/meses/años...
export default function useDatepicker(offset, offsetType, now = false) {
  const state = reactive({
    offset,
    offsetType,
    dateText: now ? $dayjs().format('YYYY-MM-DD') : null,
    dateOpen: false,
    formattedDate: computed(() => state.dateText && $dayjs(state.dateText).format('DD/MM/YYYY')),
    formattedDateOffset: computed(() => $dayjs(state.dateText).add(state.offset, state.offsetType).format('DD/MM/YYYY')),
  });
  
  return state;
}
