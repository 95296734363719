<template>
  <!--  @current-items="getFilteredItems" -->
  <v-data-table
    v-model="rowValues"
    v-bind="$attrs"
    loading-text="Cargando datos..."
    class="elevation-1"
    :mobile-breakpoint="768"
    :search="search"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    :show-select="showSelect"
    :footer-props="{
      showFirstLastPage: true,
      itemsPerPageOptions: [forceItemsPerPage, 20, -1]
    }"
    :items-per-page="forceItemsPerPage"
    @click:row="$emit('onRowClick', $event)"
  >
    <template v-slot:item.estatus="{ item }">
      <span v-if="item.plan_completado" class="green--text text--darken-4 text-no-wrap font-italic font-weight-bold">{{ 'Completado' }}</span>
      <span v-else-if="item.suspendido && item.estatus" :class="{'amber--text text--darken-4': item.suspendido}">{{ (windowWidth >= 1024 && windowWidth <= 1152) || (windowWidth >= 768 && windowWidth <= 850) ? 'Susp.' : 'Suspendido' }}</span>
      <span v-else :class="{'red--text text--darken-4': !item.estatus}">{{ item.estatus ? 'Activo' : 'Baja' }}</span>
    </template>

    <template v-slot:item.forma_pago="{ item }">
      <span class="text-no-wrap" :style="{ position: 'relative'}">
        <span v-if="item.otrasFormasPagoIncluidas 
          && Array.isArray(item.otrasFormasPagoIncluidas)
          && item.otrasFormasPagoIncluidas.length"
          :style="{ position: 'absolute', top: '-4px', left: '-24px' }"
        >
          <v-icon
            v-tooltip="`
              Se tienen registros de abonos <br />con otras formas de pago: 
              <br />---<br /> 
              ${item.otrasFormasPagoIncluidas.join(', ')}
              <br />---<br />
              Esto indica que se ha cambiado la forma <br />
              de pago del plan de cliente antes de cerrar <br />
              el trimestre, por lo que el usuario debe <br />
              considerar este detalle para que las cuentas <br />
              sean correctas.
            `" :size="20"
          >
            mdi-alert-circle-outline
          </v-icon>
        </span>

        {{ (windowWidth >= 1024 && windowWidth <= 1152) || windowWidth >= 768 && (windowWidth <= 850)
            ? item.forma_pago == FORMA_PAGO.DIARIO && 'Diario'
            || item.forma_pago == FORMA_PAGO.SEMANAL && 'Seman.'
            || item.forma_pago == FORMA_PAGO.QUINCENAL && 'Quinc.'
            || item.forma_pago == FORMA_PAGO.MENSUAL && 'Mens.'
            || item.forma_pago == FORMA_PAGO.TRIMESTRAL && 'Trim.'
            || item.forma_pago == FORMA_PAGO.SEMESTRAL && 'Semes.'
            || item.forma_pago == FORMA_PAGO.ANUAL && 'Anual'
            : item.forma_pago
        }}
      </span>
    </template>

    <template v-slot:item.pago_moneda_contratada="{ item }">
      <span class="text-no-wrap">
        {{ formatCurrency(item.pago_moneda_contratada) }}
      </span>
    </template>

    <template v-slot:item.tipo_cambio="{ item }">
      <span class="text-no-wrap">
        {{ formatCurrency(item.tipo_cambio, {moneda: item.moneda || "MXN", prefix: ""}) }}
      </span>
    </template>

    <template v-slot:item.max_anios="{ item }">
      <span>{{ item.max_anios || "No establecido (sin límite)" }}</span>
    </template>

    <template v-slot:item.abonado="{ item }">
      <span :class="[(item.trimestre_iniciado ? 'font-weight-bold' : ''), 'text-no-wrap']">
        {{ formatCurrency(item.abonado) }}
      </span>
    </template>

    <template v-slot:item.pago_pesos="{ item }">
      <span :class="[(item.trimestre_iniciado ? 'font-weight-bold' : ''), 'text-no-wrap']">
        {{ formatCurrency(item.pago_pesos) }}
      </span>
    </template>

    <template v-slot:item.saldo_pendiente="{ item }">
      <span v-if="item.trimestre_iniciado" :class="[(item.saldo_pendiente > 0 ? 'red--text' : 'green--text'), 'font-weight-bold text--darken-4 text-no-wrap']">
        {{ formatCurrency(item.saldo_pendiente) }}
      </span>
      <span v-else class="text-no-wrap">
        {{ formatCurrency(item.saldo_pendiente) }}
      </span>
    </template>

    <template v-slot:item.saldo="{ item }">
      <span v-if="item.trimestre_iniciado" :class="[(item.saldo > 0 ? 'red--text' : 'green--text'), 'font-weight-bold text--darken-4 text-no-wrap']">
        {{ formatCurrency(item.saldo) }}
      </span>
      <span v-else class="text-no-wrap">
        {{ formatCurrency(item.saldo) }}
      </span>
    </template>

    <template v-slot:item.pago_periodico_calculado="{ item }">
      <span class="text-no-wrap">
        {{ formatCurrency(item.pago_periodico_calculado) }}
      </span>
    </template>

    <template v-slot:item.saldo_a_favor="{ item }">
      <span :class="[(Math.abs(item.saldo_a_favor) > 0 ? 'green--text font-weight-bold text--darken-4' : ''), 'text-no-wrap']">
        {{ formatCurrency(Math.abs(item.saldo_a_favor)) }}
      </span>
    </template>

    <template v-slot:item.poliza="{ item }">
      <v-badge
        v-if="item.domiciliado"
        v-tooltip="'Tiene pago domiciliado'"
        content="!"
        color="grey"
        left
        dot
      >
        {{ item.poliza }}
      </v-badge>
      <span v-else>{{ item.poliza }}</span>
    </template>

    <!-- <template v-slot:item.clientes.nombre="{ item }">
      <v-badge
        v-if="item.domiciliado"
        v-tooltip="'Tiene pago domiciliado'"
        content="!"
        color="grey"
        left
        dot
      >
        {{ item.clientes.nombre }}
      </v-badge>
      <span v-else>{{ item.clientes.nombre }}</span>
    </template> -->

    <template v-slot:item.abono_estatus="{ item }">
      <span :class="{'grey--text text--darken-1': !item.abono_estatus, 'green--text text--darken-4 font-weight-bold': item.abono_estatus}">
        {{ item.abono_estatus ? 'Aplicado' : 'Cancelado' }}
      </span>
    </template>

    <template v-slot:item.planes_clientes.eliminado="{ item }">
      <span :class="{'red--text text--darken-4': !item.planes_clientes.estatus || item.planes_clientes.suspendido}">
        {{ item.planes_clientes.eliminado
            ? `${item.plan_poliza}`
            : !item.planes_clientes.estatus 
              ? `${item.plan_poliza} (Baja)` 
              : item.planes_clientes.suspendido 
                ? `${item.plan_poliza} (Suspendida)` 
                : `${item.plan_poliza}`
        }}
      </span>
    </template>

    <template v-slot:item.users.nombre_completo="{ item }">
      <span :style="{ position: 'absolute' }" v-if="item.id_usuario_logueado != item.id_cobrador && !isEmptyObj(users.findById(item.id_usuario_logueado || 0).value)">
        <span :style="{ position: 'relative', left: '-20px' }">
          <v-icon
            v-tooltip="`Cobro aplicado por: <br />- ${users.findById(item.id_usuario_logueado).value.nombre} ${users.findById(item.id_usuario_logueado).value.apellidos} 
            (${[0, 1, 2].includes(users.findById(item.id_usuario_logueado).value.privilegio) ? 'Admin' : 'Cobrador'})`"
            :size="20"
          >
            mdi-alert-circle-outline
          </v-icon>
        </span>
      </span>

      <span :style="{ position: 'absolute' }" v-if="item.id_usuario_logueado == -1">
        <span :style="{ position: 'relative', left: '-20px' }">
          <v-icon
            v-tooltip.left="`
              Cobro aplicado por proceso automatizado del sistema <br />
              (cada lunes a sábado a las 11:59pm): 
              <br />---<br />
              Se aplica el abono automáticamente en 0 para las formas <br />
              de pago <u>Diario</u> si el plan estuvo al corriente conforme al <br />
              día actual (en su momento) y si no se realizó ninguna <br />
              acción para aplicarlo
            `"
            :size="20"
          >
            mdi-alert-circle-outline
          </v-icon>
        </span>
      </span>

      <span :class="{'red--text text--darken-4': !item.users.estatus}">
        {{ item.users.eliminado
            ? `${item.nombre_completo_cobrador}`
            : !item.users.estatus 
              ? `${item.nombre_completo_cobrador} (Baja)` 
              : `${item.nombre_completo_cobrador}`
        }}
      </span>
    </template>

    <template v-slot:item.nombre_completo_cobrador="{ item }">
      <span :style="{ position: 'absolute' }" v-if="item.id_usuario_logueado != item.id_cobrador && !isEmptyObj(users.findById(item.id_usuario_logueado || 0).value)">
        <span :style="{ position: 'relative', left: '-20px' }">
          <v-icon
            v-tooltip="`Cobro aplicado por: <br />- ${users.findById(item.id_usuario_logueado).value.nombre} ${users.findById(item.id_usuario_logueado).value.apellidos} 
            (${[0, 1, 2].includes(users.findById(item.id_usuario_logueado).value.privilegio) ? 'Admin' : 'Cobrador'})`"
            :size="20"
          >
            mdi-alert-circle-outline
          </v-icon>
        </span>
      </span>

      <span :style="{ position: 'absolute' }" v-if="item.id_usuario_logueado == -1">
        <span :style="{ position: 'relative', left: '-20px' }">
          <v-icon
            v-tooltip.left="`
              Cobro aplicado por proceso automatizado del sistema <br />
              (cada lunes a sábado a las 11:59pm): 
              <br />---<br />
              Se aplica el abono automáticamente en 0 para las formas <br />
              de pago <u>Diario</u> si el plan estuvo al corriente conforme al <br />
              día actual (en su momento) y si no se realizó ninguna <br />
              acción para aplicarlo
            `"
            :size="20"
          >
            mdi-alert-circle-outline
          </v-icon>
        </span>
      </span>

      <span v-if="isEmptyObj(users.findById(item.id_cobrador).value)" class="red--text text--darken-4">
        {{ `${item.nombre_completo_cobrador}` }}
      </span>
      <span v-else :class="{'red--text text--darken-4': !users.findById(item.id_cobrador).value.estatus}">
        {{ !users.findById(item.id_cobrador).value.estatus
            ? `${item.nombre_completo_cobrador} (Baja)`
            : `${item.nombre_completo_cobrador}`
        }}
      </span>
    </template>

    <template v-slot:item.nombre_completo_cliente_vigencia="{ item }">
      <span :class="{'red--text text--darken-4': !clientes.findById(item.id_cliente).value.estatus}">
        {{ !clientes.findById(item.id_cliente).value.estatus
            ? `${item.nombre_completo_cliente_vigencia} (Baja)` 
            : `${item.nombre_completo_cliente_vigencia}`
        }}
      </span>
    </template>

    <template v-slot:item.clientes.nombre_completo="{ item }">
      <span :class="{'red--text text--darken-4': !item.clientes.estatus}">
        {{ item.clientes.eliminado
            ? `${item.nombre_completo_cliente}`
            : !item.clientes.estatus 
              ? `${item.nombre_completo_cliente} (Baja)` 
              : `${item.nombre_completo_cliente}`
        }}
      </span>
    </template>

    <template v-slot:item.clientes_corte.num_cliente="{ item }">
      <span :class="{'red--text text--darken-4': !item.clientes.estatus}">
        {{ item.clientes.eliminado
            ? `${item.num_cliente}`
            : !item.clientes.estatus 
              ? `${item.num_cliente} (Baja)` 
              : `${item.num_cliente}`
        }}
      </span>
    </template>

    <template v-slot:item.abono_activo="{ item }">
      <span :style="{ position: 'absolute' }" v-if="item.saldo_a_favor_usado > 0">
        <span :style="{ position: 'relative', top: '-2px', left: '-20px' }">
          <v-icon 
            v-tooltip="`Abonado con saldo a favor`"
            :size="20" 
          >
            mdi-alert-circle-outline
          </v-icon>
        </span>
      </span>

      <span v-if="item.abono_activo" class="text-no-wrap">
        <v-badge
          v-if="item.abono_por_des_suspension"
          v-tooltip.left="'Abono por des-suspensión'"
          content="!"
          color="warning"
          left
          dot
        >
          {{ `${formatCurrency(item.cantidad)}` }}
        </v-badge>

        <span v-else>
          {{ `${formatCurrency(item.cantidad)}` }}
        </span>
      </span>

      <span v-else class="text-no-wrap red--text text--darken-4">
        <v-badge
          v-if="item.abono_por_des_suspension"
          v-tooltip.left="'Abono por des-suspensión'"
          content="!"
          color="warning"
          left
          dot
        >
          {{ `${formatCurrency(item.cantidad)}` }}
          <div>
            {{ '(Cancelado)' }}
          </div>
        </v-badge>

        <span v-else>
          {{ `${formatCurrency(item.cantidad)}` }}
          <div>
            {{ '(Cancelado)' }}
          </div>
        </span>
      </span>
    </template>

    <template v-slot:item.cantidad="{ item }">
      <span :style="{ position: 'absolute' }" v-if="item.saldo_a_favor_usado > 0">
        <span :style="{ position: 'relative', top: '-2px', left: '-20px' }">
          <v-icon 
            v-tooltip="`Abonado con saldo a favor`"
            :size="20" 
          >
            mdi-alert-circle-outline
          </v-icon>
        </span>
      </span>

      <span :style="{ position: 'absolute' }" v-if="item.abono_por_des_suspension">
        <span :style="{ position: 'relative', top: '-12px' }">
          <v-badge
            v-tooltip.top="'Abono por des-suspensión'"
            content="!"
            color="warning"
            left
            dot
          />
        </span>
      </span>

      <span class="text-no-wrap">
        {{ formatCurrency(item.cantidad) }}
      </span>
    </template>

    <template v-slot:item.saldo_en_contra="{ item }">
      <span class="text-no-wrap">
        {{ formatCurrency(item.saldo_en_contra) }}
      </span>
    </template>

    <template v-slot:item.saldo_en_contra_saldos="{ item }">
      <span class="text-no-wrap font-weight-bold red--text text--darken-4">
        {{ formatCurrency(item.saldo_en_contra_saldos) }}
      </span>
    </template>

    <!-- "id_abono" es un campo que no existe, y es solo para utilizar un estilo personalizado 
    en el campo "id" de un abono sin afectar otros campos "id" -->
    <template v-slot:item.id_abono="{ item }">
      <span class="text-no-wrap">
        <v-badge
          v-if="item.abono_por_des_suspension"
          v-tooltip.right="'Abono por des-suspensión'"
          content="!"
          color="warning"
          left
          dot
        >
          {{ item.id }}
        </v-badge>

        <span v-else>
          {{ item.id }}
        </span>
      </span>
    </template>

    <template v-slot:item.id_usuario_logueado="{ item }">
      <span class="text-no-wrap">
        {{ item.id_usuario_logueado }}
      </span>
    </template>

    <!-- <template v-if="customizeTableRowsOn == 'abonos_plan_pagos'" v-slot:body="{ items }">
      <tbody>
        <tr 
          v-for="item in items"
          :key="item.id"
          @click="$emit('onRowClick', item)"
        >
          <td>
            <v-badge
              v-if="item.abono_por_des_suspension"
              v-tooltip.right="'Abono por des-suspensión'"
              content="!"
              color="warning"
              left
              dot
            >
              {{ item.id }}
            </v-badge>

            <span v-else>
              {{ item.id }}
            </span>
          </td>
          <td>
            <span class="">
              {{ item.plan_forma_pago }}
            </span>
          </td>
          <td>
            <span class="text-no-wrap">
              {{ formatCurrency(item.cantidad) }}
            </span>
          </td>
          <td class="text-no-wrap">{{ formatCurrency(item.saldo_en_contra) }}</td>
          <td>
            {{ item.fecha_abono ? $dayjs(item.fecha_abono).tz().format('DD/MM/YYYY - (ddd) hh:mm:ss a') : '' }}
            <div v-if="item.fecha_abono_real && ($dayjs(item.fecha_abono_real).tz() > $dayjs(item.fecha_abono).tz())">
              {{ `- - -` }} <br />
              {{ `Adelanto de abono para la fecha ${$dayjs(item.fecha_abono_real).tz().format('DD/MM/YYYY - (ddd)')}` }}
            </div>
          </td>
          <td>
            <span v-if="isEmptyObj(users.findById(item.id_cobrador).value)" class="red--text text--darken-4">
              {{ `${item.nombre_completo_cobrador}` }}
            </span>
            <span v-else :class="{'red--text text--darken-4': !users.findById(item.id_cobrador).value.estatus}">
              {{ !users.findById(item.id_cobrador).value.estatus
                  ? `${item.nombre_completo_cobrador} (Baja)` 
                  : `${item.nombre_completo_cobrador}`
              }}
            </span>
          </td>
          <td>
            <span :class="{'grey--text text--darken-1': !item.abono_estatus, 'green--text text--darken-4 font-weight-bold': item.abono_estatus}">
              {{ item.abono_estatus ? 'Aplicado' : 'Cancelado' }}
            </span>
          </td>
          <td>
            
            <div class='d-flex'>
              <v-icon 
                v-if="item.saldo_a_favor_usado > 0"
                v-tooltip="`Abonado con saldo a favor`"
                :size="20" 
              >
                mdi-alert-circle-outline
              </v-icon>

              <v-btn class="px-2"
                v-tooltip.left="!item.es_editable ? 'Sólo se puede ver el último abono' : 'Ver acciones disponibles'"
                :disabled="!item.es_editable || $attrs.loading"
                :small="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
                @click="$emit('editItem', item)"
                text
              >
                <v-icon color="grey darken-2" :size="20">mdi-eye</v-icon>
                <div style="font-size: 12px !important; font-weight: bold;" class="caption grey--text text--darken-3">Ver</div>
              </v-btn>
            </div>
            
          </td>
        </tr>
      </tbody>
    </template> -->

    <template v-slot:item.pago_periodico_ajuste="{ item }">
      <span :class="[(item.pago_periodico_ajuste > 0 ? 'red--text' : 'green--text'), 'font-weight-bold text--darken-4 text-no-wrap']">
        {{ formatCurrency(item.pago_periodico_ajuste) }}
      </span>
    </template>

    <template v-slot:item.fecha_inicial="{ item }">
      <span>{{ item.fecha_inicial ? $dayjs(item.fecha_inicial).format('DD/MM/YYYY') : '' }}</span>
    </template>

    <template v-slot:item.fecha_inicial_contrato="{ item }">
      <span>{{ item.fecha_inicial_contrato ? $dayjs(item.fecha_inicial_contrato).format('DD/MM/YYYY') : '' }}</span>
    </template>

    <template v-slot:item.fecha_fin_contrato="{ item }">
      <span>{{ item.fecha_fin_contrato ? $dayjs(item.fecha_fin_contrato).format('DD/MM/YYYY') : '' }}</span>
    </template>

    <template v-slot:item.fecha_prox_pago="{ item }">
      <span>{{ item.fecha_prox_pago ? $dayjs(item.fecha_prox_pago).format('DD/MM/YYYY') : '' }}</span>
    </template>

    <template v-slot:item.fecha_abono="{ item }">
      <span :style="{ position: 'absolute' }" v-if="item.fecha_abono_real && ($dayjs(item.fecha_abono_real).tz() > $dayjs(item.fecha_abono).tz())">
        <span :style="{ position: 'relative', left: '-20px' }">
          <v-icon
            v-tooltip="`Adelanto de abono para la fecha: <br />${$dayjs(item.fecha_abono_real).tz().format('DD MMM YYYY - (dddd) hh:mm:ss a')}`"
            :size="20"
          >
            mdi-alert-circle-outline
          </v-icon>
        </span>
      </span>

      <span>
        <span style="font-weight: bold; color: #444;">
          {{ $dayjs(item.fecha_abono).tz().format('DD MMM YYYY') }}
        </span>
        {{ $dayjs(item.fecha_abono).tz().format('(dddd) hh:mm:ss a') }}
      </span>
    </template>

    <template v-slot:item.fecha_abono_BD="{ item }">
      <div> Fecha abono: {{ item.fecha_abono ? item.fecha_abono : '' }}</div>
      <div> Fecha abono real: {{ item.fecha_abono_real ? item.fecha_abono_real : '' }}</div>
    </template>

    <template v-slot:item.custom_hora_abono="{ item }">
      <span>{{ item.fecha_abono ? $dayjs(item.fecha_abono).tz().format('hh:mm:ss a') : '' }}</span>
    </template>

    <template v-slot:item.fecha="{ item }">
      <span>{{ $dayjs(item.fecha).format('DD/MM/YYYY') }}</span>
    </template>

    <template v-slot:item.fecha_log="{ item }">
      <span>{{ $dayjs(item.fecha_log).tz().format('DD/MM/YYYY HH:mm a') }}</span>
    </template>

    <template v-slot:item.fecha_reg="{ item }">
      <span>{{ $dayjs(item.fecha_reg).format('DD/MM/YYYY') }}</span>
    </template>

    <template v-slot:item.plan_catalog_data.nombre="{ item }">
      <span>{{ item.plan_catalog_data.nombre }}</span>
    </template>

    <template v-slot:item.dia_pago="{ item }">
      <span>{{ item.dia_pago ? capitalize(item.dia_pago) : '- - -' }}</span>
    </template>

    <template v-slot:top="{ pagination, options, updateOptions }">
      <!-- <v-data-footer 
        :pagination="pagination" 
        :options="{options}"
        @update:options="updateOptions"
        items-per-page-text="$vuetify.dataTable.itemsPerPageText"/> -->

      <v-toolbar flat v-if="showToolbar" >
        
        <div v-if="$attrs.items && $attrs.items.length && rowValues.length > 0" class="d-flex flex-column subtitle-2">
          <!-- <a v-show="showExportPDF" id="exportarPDF" @click="selectedRows('pdf')"> Exportar a PDF </a> -->
          <!-- <a v-show="showExportExcel" id="exportarExcel" @click="selectedRows('excel')"> Exportar a Excel </a> -->
          <!-- :data="rowValues" -->
          <a v-show="showCancelMultipleAbonos" id="showCancelMultipleAbonos" @click="selectedRows('cancelMultipleAbonos')"> Cancelar seleccionados </a>
          <slot v-show="showExportExcel" name="showExportExcel" v-bind:rowValues="rowValues"/>
          <!-- <a v-show="showExportExcel">
            <download-excel
              :data="rowValues || []"
              :before-generate="selectedRows('excel')"
            >
              Exportar a Excel
            </download-excel>
          </a> --> 

        </div>
        <v-divider v-if="$attrs.items && $attrs.items.length && rowValues.length > 0" class="mx-4" inset vertical></v-divider>

        <v-toolbar-title v-if="(!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm) && toolbarTitle">
          <div class="d-flex flex-column">
            <div>
              {{ title }}
            </div>
            <div v-if="subtitle" class="body-2 grey--text text--darken-2">
              {{ subtitle }}
            </div>
          </div>
        </v-toolbar-title>
        <v-divider v-if="(!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm) && toolbarTitle" class="mx-4" inset vertical></v-divider>

        <v-btn v-show="showUpdateTableOption" id="showUpdateTableOption" @click="$emit('onUpdateTable')"> Actualizar tabla </v-btn>
        <v-divider v-if="showUpdateTableOption" class="mx-4" inset vertical></v-divider>

<!-- BARRA DE BUSQUEDA -->
        <v-spacer v-if="!toolbarActions"></v-spacer>

        <VTextField v-if="searchEnabled"
          :label="searchLabel"
          :value="search"
          @input="handleSearch"
          @click:clear="handleClearSearch"
          :class="$vuetify.breakpoint.xs ? 'mr-4' : ''"
          prepend-inner-icon="mdi-magnify"
          clearable
          single-line
          hide-details
        ></VTextField>
<!-- BARRA DE BUSQUEDA -->

        <v-spacer v-if="toolbarActions"></v-spacer>

        <div v-if="!searchEnabled && rightText">
          <v-chip
            color="grey lighten-4"
            label
          >
            <div :class="rightText.color">
              {{ rightText.text || rightText}}
            </div>
          </v-chip>
        </div>
        <slot v-show="!toolbarActions" name="toolbarActions"/>
      </v-toolbar>

      <!-- DIALOG ELIMINAR REGISTRO -->
      <Dialog
        v-model="dialogDelModel"
        :title="`Eliminación de registro`"
        useToolbarStyle
        @onCloseToolbarBtn="dialogDelModel = false"
      >
        <template slot="content">
          <v-card-title class="justify-center">
            <span class="headline">
              ¿Seguro de que desea eliminar este registro?
            </span>
          </v-card-title>

          <div class="mb-4 font-weight-bold" style="text-align: center; font-size: 24px;">
            {{ printItemToDelete }}
          </div>

          <!-- <div class="px-4 mt-3" style="font-size: 16px;">
            Si lo que desea es dar de baja el registro y conservarlo, entonces se deberá hacer desde el apartado de <b><i>"Editar"</i></b>.
          </div>
          <div class="mb-6"> ‎</div> -->

          <div class="ml-4">
            <v-alert
              border="left"
              colored-border
              color="warning"
              class=" mb-0"
            >
              <div class="d-flex grey--text text--darken-3" style="flex-flow: row nowrap; font-size: 18px; vertical-align: bottom;">
                <v-icon color="warning" class="mr-4">mdi-alert</v-icon>
                {{ extraEliminarInfoText || `El registro se eliminará permanentemente y no podrá ser recuperado.` }}
              </div>
            </v-alert>
          </div>

          <!-- <div class="d-flex justify-center mb-5">
            <div class="subtitle-1"> 
              El registro se eliminará permanentemente y no podrá ser recuperado. 
              Si lo que desea es darlo de baja y conservar el registro, se deberá hacer desde el apartado de "Editar".
            </div>
            <div class="subtitle-1 font-weight-bold">Nombre</div>
            <div class="subtitle-1">: {{itemToDelete.nombre}}</div>
          </div> -->
        </template>

        <template slot="actions">
          <Button text @click="dialogDelModel = false">Cancelar</Button>
          <Button text @click="deleteItem">Aceptar</Button>
        </template>
      </Dialog>
    </template>

    <template v-slot:item.action="{ item }">
      <div v-if="actions" :class="[`d-flex justify-${actionsAlign}`, ($vuetify.breakpoint.xs ? 'ml-0' : 'ml-n2')]">
        <!-- El prop "actions" puede estar activo, pero si en la tabla no se especifica la columna "action",
        este apartado no se visualizará -->

        <!-- ACCION "EDITAR ABONO/VER ACCIONES ABONO" PLAN PAGOS -->
        <div v-if="showCancelMultipleAbonos" class='d-flex'>
          <v-btn
            v-tooltip.left="!item.es_editable ? 'Sólo se puede ver el último abono' : 'Ver acciones disponibles'"
            :disabled="!item.es_editable || $attrs.loading"
            :small="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
            @click="$emit('editItem', item)"
            text
          >
            <v-icon color="grey darken-2" :size="20">mdi-eye</v-icon>
            <div style="font-size: 12px !important; font-weight: bold;" class="caption grey--text text--darken-3">Ver</div>
          </v-btn>
        </div>
        <!-- <div v-else> - - - </div> -->

        <!-- ACCION "EDITAR" -->
        <v-btn class="px-2"
          v-if="showEditarAction"
          text
          v-tooltip="item.plan_completado ? 'Ver detalles' : tooltipEditarText"
          :small="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
          :disabled="$attrs.loading"
          @click="$emit('editItem', item)"
        >
          <!-- <div> -->
            <v-icon :color="`${highlightEditBtnWithColor || 'blue-grey darken-2'} darken-2`" :size="20">
              {{ item.plan_completado ? 'mdi-eye' : editarIcon }}
            </v-icon>
            <div :class="`subtitle-1 ${highlightEditBtnWithColor || 'blue-grey'}--text text--darken-4`" style="padding-top: 0px; padding-left: 2px;">
              <div class="font-weight-bold text-left" style="font-size: 12px !important;">
                {{ item.plan_completado ? 'Ver' : editarText }} <span v-if="editarTextSubtitle && windowWidth >= BREAKPOINT.LG">{{ editarTextSubtitle }}</span>
              </div>
              <div v-if="editarTextSubtitle && windowWidth < BREAKPOINT.LG" class="subtitle-1 text-left font-weight-bold " style="font-size: 12px !important; margin-top: -13px;">
                {{ editarTextSubtitle }}
              </div>
            </div>
          <!-- </div> -->
        </v-btn>
        
        <!-- ACCION "IR A PLAN" -->
        <div v-if="showPlanAction" style="padding-top: 2px;" class="grey--text text--darken-1">| </div>
        <v-btn id="editBtn" class="px-2"
          v-if="showPlanAction"
          text
          v-tooltip="'Ver planes del cliente'"
          :disabled="$attrs.loading"
          :small="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
          @click="$emit('goToPlan', item.id)"
        >
          <!-- <div> -->
            <v-icon color="blue-grey darken-2" :size="20">
              mdi-eye-settings
            </v-icon>
            <div class="subtitle-1 blue-grey--text text--darken-4" style="padding-top: 0px; padding-left: 2px;">
              <div class="font-weight-bold text-left" style="font-size: 12px !important;">
                Ver <span v-if="windowWidth >= BREAKPOINT.LG">Planes</span>
              </div>
              <div v-if="windowWidth < BREAKPOINT.LG" class="subtitle-1 text-left font-weight-bold " style="font-size: 12px !important; margin-top: -13px;">
                Planes
              </div>
            </div>
          <!-- </div> -->
        </v-btn>
  
        <!-- ACCION "ELIMINAR" -->
        <div v-if="showDeleteAction" style="padding-top: 2px;" class="grey--text text--darken-1">| </div>
        <v-btn class="ml-1"
          v-if="showDeleteAction"
          text
          icon
          v-tooltip.left="'Eliminar'"
          :small="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
          :disabled="$attrs.loading || (loggedUser.privilegio == 0 
            ? false 
            : (loggedUser.privilegio > 1 || (item.estatus && !item.plan_completado) || (item.suspendido && item.estatus)) 
              ? true 
              : false)" 
          @click="openModalDelete(item)" 
        >
          <!-- <v-icon color="grey darken-1" :size="20">mdi-delete-forever</v-icon> -->
          
          <v-icon color="red darken-4" style="opacity: 0.8;" :size="20">
            mdi-delete-forever
          </v-icon>
        </v-btn>

        <!-- ACCION "COBRAR FILA (SOLO VISTA DE COBRANZA)" -->
        <div v-if="showCobroAction" style="padding-top: 2px;" class="ml-2 grey--text text--darken-1">| </div>
        <v-btn class="ml-1"
          v-if="showCobroAction"
          text
          icon
          v-tooltip.left="'Aplicar abono directamente'"
          :small="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
          :disabled="$attrs.loading"
          @click="$emit('onCobroRow', item)" 
        >
          <!-- <v-icon color="grey darken-1" :size="20">mdi-delete-forever</v-icon> -->
          
          <!-- <v-icon color="blue-grey darken-2" :size="20">
            mdi-account
          </v-icon>
          <v-icon style="margin-left: -6px; margin-top: -4px;" color="blue-grey darken-2" :size="20">
            mdi-cash-minus
          </v-icon> -->
          <v-icon color="blue-grey darken-2" :size="20">
            mdi-cash-usd
          </v-icon>
          <!-- <div class="subtitle-1 blue-grey--text text--darken-4" style="padding-top: 0px; padding-left: 2px;">
            <div class="font-weight-bold text-left" style="font-size: 12px !important;">
              Aplicar
            </div>
          </div> -->
        </v-btn>

      </div>
    </template>

    <template v-slot:no-data>
      <!-- <Button color="primary" @click="initialize">Reset</Button> -->
      <!-- No existen clientes registrados -->
    </template>
  </v-data-table>
</template>

<script>
import { getCurrentInstance, ref, computed, watch } from "vue";
import Dialog from "@/components/ui/Dialog";
import Button from "@/components/ui/Button";
import { FORMA_PAGO, BREAKPOINT } from "@/utils/Constants";
import { formatCurrency, capitalize, isEmptyObj, debounce } from "@/utils/Functions";
import { state, getters, STORE_NAMESPACES } from "@/store";

export default {
  components: {
    Dialog,
    Button,
  },
  setup(props) {
    const vm = getCurrentInstance();
    const { APP, CLIENTES, USERS } = STORE_NAMESPACES;
    const rowValues = ref([]);
    // const filteredItems = ref([]);

    const search = ref(props.defaultSearchText);
    const itemToDelete = ref({});
    const dialogDelModel = ref(false);
    const printItemToDelete = ref("");
    const loggedUser = getters[USERS].findById(state[APP].idUser);
    const windowWidth = getters[APP].getWindowWidth;

    function openModalDelete(item) {
      dialogDelModel.value = !dialogDelModel.value;
      itemToDelete.value = item;

      // Concatena las props de "showItemPropToDelete" que esten separadas con el signo "+"
      const itemDescr = props.showItemDescrToDelete;
      const itemProps = props.showItemPropToDelete.split("+");
      let itemStr = "";

      itemProps.forEach(itemProp => {
        itemStr += `${item[itemProp]} `
      });

      if (itemDescr) {
        itemStr = `${itemDescr} ${itemStr}`;
      }

      printItemToDelete.value = itemStr;
    }

    function deleteItem() {
      dialogDelModel.value = false;
      vm.proxy.$emit("deleteItem", {...itemToDelete.value});
    }

    // Para obtener las filas seleccionadas con checkbox
    function selectedRows(exportType) {
      vm.proxy.$emit("onSelectedRows", exportType, rowValues);
      // rowValues.value = [];
    }

    watch(() => props.parentDialogState, (isOpen) => {
      if (!isOpen) {
        rowValues.value = [];
      }
    });

    watch(() => props.unselectAllCheckboxes, (unselectCheckboxes) => {
      if (unselectCheckboxes && rowValues.value.length) {
        rowValues.value = [];
      }
    }, { immediate: false });

    /* Obtiene los items a la vista, tomando en cuenta el filtro de busqueda
    Opcional, si no se quiere usar los checkbox para exportar */
    // const getFilteredItems = debounce((items) => {
    //   filteredItems.value = [...items];
    // });

    const handleSearch = debounce((value) => {
      search.value = value;
    }, 700);

    const handleClearSearch = () => {
      search.value = "";
    }

    return { 
      users: getters[USERS],
      clientes: getters[CLIENTES],
      loggedUser,
      search,
      dialogDelModel,
      openModalDelete,
      deleteItem,
      itemToDelete,
      printItemToDelete,

      formatCurrency,
      capitalize,
      isEmptyObj,
      windowWidth,
      handleSearch,
      handleClearSearch,

      FORMA_PAGO,
      BREAKPOINT,

      rowValues,
      selectedRows,
      // getFilteredItems,
    };
  },

  props: {
    title: String,
    subtitle: String,
    rightText: {
      type: [String, Object], 
      default: '',
    },
    toolbarActions: {
      type: Boolean,
      default: false,
    },
    toolbarTitle: {
      type: Boolean,
      default: true,
    },
    editarIcon: {
      type: String,
      default: 'mdi-lead-pencil',
    },
    editarText: {
      type: String,
      default: 'Editar',
    },
    editarTextSubtitle: {
      type: String,
      default: '',
    },
    tooltipEditarText: {
      type: String,
      default: 'Editar',
    },
    searchLabel: {
      type: String,
      default: 'Búsqueda',
    },
    sortBy: {
      type: Array,
      default: () => ['poliza' ,'estatus', 'amortizacion', 'fecha_abono', 'fecha_inicial', 'num_cliente']
    },
    sortDesc: {
      type: Array,
      default: () => [true, false]
    },
    parentDialogState: {
      type: Boolean,
      default: false,
    },
    // customizeTableRowsOn: {
    //   type: String,
    //   default: '',
    // },
    extraEliminarInfoText: {
      type: String,
      default: '',
    },
    showItemPropToDelete: {
      type: String,
      default: 'id',
    },
    showItemDescrToDelete: {
      type: String,
      default: '',
    },
    highlightEditBtnWithColor: {
      type: String,
      default: '',
    },
    forceItemsPerPage: {
      type: Number,
      default: 10
    },
    showPlanAction: {
      type: Boolean,
      default: false,
    },
    showEditarAction: {
      type: Boolean,
      default: true,
    },
    showDeleteAction: {
      type: Boolean,
      default: true,
    },
    showCobroAction: {
      type: Boolean,
      default: false,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    searchEnabled: {
      type: Boolean,
      default: true,
    },
    defaultSearchText: {
      type: String,
      default: "",
    },
    actions: {
      type: Boolean,
      default: true,
    },
    actionsAlign: {
      type: String,
      default: "left",
    },
    // showExportPDF: {
    //   type: Boolean,
    //   default: false,
    // },
    showCancelMultipleAbonos: {
      type: Boolean,
      default: false,
    },
    showExportExcel: {
      type: Boolean,
      default: false,
    },
    showUpdateTableOption: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    unselectAllCheckboxes: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
