// Namespaces (actions y getters), y state del store
export const STORE_NAMESPACES = {
  // Utilizados en actions y getters
  APP: "app",
  CLIENTES: "clientes",
  USERS: "users",
  PLANES: "planes",
  PLANES_CLIENTE: "planes_cliente",
  PLAN_PAGOS: "plan_pagos",
  ABONOS: "abonos",
  DEV_ADMIN: "dev_admin",

  /* Almacenados en store, no se utilizan directamente, sino que se llama 
  desde planes_clientes.js al insertar, actualizar o eliminar */
  CLIENTE_PLAN_Y_PAGOS: "cliente_plan_y_pagos",
};