import { capitalize, normalizeStr } from "@/utils/Functions";
import packageJSON from "../../package.json";

const DEVELOPER_ENV  = Boolean(process.env.NODE_ENV == "development");
const APP_DEBUG_MODE = DEVELOPER_ENV;

const APP_VERSION    = `${packageJSON.version}`;
const LAST_UPDATED   = `Actualizado: ${packageJSON.last_updated}`;
const CHANGELOG      = `
  <b>[ ${APP_VERSION} ]</b>
  <br/>
  * Poder exportar los planes de pago a Excel.
  <br/>
  * Correcciones generales menores.
`;

const TURNO_PAGO = {
  MATUTINO: "Matutino",
  VESPERTINO: "Vespertino",
};

const { MATUTINO, VESPERTINO } = TURNO_PAGO;
const TURNO_PAGO_SELECT = [
  { text: MATUTINO, value: MATUTINO },
  { text: VESPERTINO, value: VESPERTINO },
];

const TIPOS_SALDO = {
  ACREEDOR: "acreedor",
  DEUDOR: "deudor",
};

const CONTRATOS_VIGENCIA = {
  VIGENTES: "vigentes",
  PROX_EN_4_MESES: "prox_en_4_meses",
  VENCIDOS: "vencidos",
};

const TABLAS_BD = {
  ABONOS:          'abonos',
  CLIENTES:        'clientes',
  PLAN_PAGOS:      'plan_pagos',
  PLANES:          'planes',
  PLANES_CLIENTES: 'planes_clientes',
  USER_LOGS:       'user_logs',
  USERS:           'users',
};

// De momento en minusculas para hacer comparaciones directas con $dayjs
const DIAS_SEMANA = {
  LUN: "lunes",
  MAR: "martes",
  MIE: "miércoles",
  JUE: "jueves",
  VIE: "viernes",
  SAB: "sábado",
  DOM: "domingo",
};

const MAX_NUM_DIAS_AL_MES = [...Array(28).keys()]
  .map((diaNum, idx) => {
    const text = idx == 0 ? 'Fin de mes (default)' : `Día ${diaNum}`;

    return { text, value: diaNum };
  });

const { LUN, MAR, MIE, JUE, VIE, SAB, DOM } = DIAS_SEMANA;
const DIAS_SEMANA_SELECT = [
  { text: capitalize(LUN), value: normalizeStr(LUN) },
  { text: capitalize(MAR), value: normalizeStr(MAR) },
  { text: capitalize(MIE), value: normalizeStr(MIE) },
  { text: capitalize(JUE), value: normalizeStr(JUE) },
  { text: capitalize(VIE), value: normalizeStr(VIE) },
  { text: capitalize(SAB), value: normalizeStr(SAB) },
  // { text: capitalize(DOM), value: normalizeStr(DOM) },
];

const MONEDAS = {
  MXN: "MXN",
  USD: "USD",
  UDIS: "UDIS",
};

const { MXN, USD, UDIS } = MONEDAS;
const MONEDAS_SELECT = [
  { text: MXN, value: MXN },
  { text: USD, value: USD },
  { text: UDIS, value: UDIS },
];

const FORMA_PAGO = {
  DIARIO: 'Diario',
  SEMANAL: 'Semanal',
  QUINCENAL: 'Quincenal',
  MENSUAL: 'Mensual',
  TRIMESTRAL: 'Trimestral',
  SEMESTRAL: 'Semestral',
  ANUAL: 'Anual',
};

const { DIARIO, SEMANAL, QUINCENAL, MENSUAL, TRIMESTRAL, SEMESTRAL, ANUAL } = FORMA_PAGO;
const FORMA_PAGO_SELECT = [
  { text: DIARIO, value: DIARIO },
  { text: SEMANAL, value: SEMANAL },
  { text: QUINCENAL, value: QUINCENAL },
  { text: MENSUAL, value: MENSUAL },
  { text: TRIMESTRAL, value: TRIMESTRAL },
  { text: SEMESTRAL, value: SEMESTRAL },
  { text: ANUAL, value: ANUAL },
];

// Agrupados para exportar 1 solo objeto a la plantilla Vue
const PLAN_DATA = {
  TURNO_PAGO_SELECT,
  DIAS_SEMANA_SELECT,
  FORMA_PAGO_SELECT,
  MONEDAS_SELECT,
  MAX_NUM_DIAS_AL_MES,
};

const DIAS_APLAZADOS_MAX = 6;

// Debe coincidir con la funcion en el backend
const PASSWORD_MIN = 4;

const BREAKPOINT = {
  SM: 600,
  MD: 960,
  LG: 1264,
  XL: 1904,
};

export {
  DEVELOPER_ENV,
  APP_DEBUG_MODE,
  LAST_UPDATED,
  CHANGELOG,
  APP_VERSION,
  TURNO_PAGO,
  TURNO_PAGO_SELECT,
  DIAS_SEMANA,
  DIAS_SEMANA_SELECT,
  MONEDAS,
  MONEDAS_SELECT,
  FORMA_PAGO,
  FORMA_PAGO_SELECT,
  PLAN_DATA,
  DIAS_APLAZADOS_MAX,
  PASSWORD_MIN,
  BREAKPOINT,
  TIPOS_SALDO,
  CONTRATOS_VIGENCIA,
  TABLAS_BD,
};