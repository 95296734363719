import RegExp from "@/utils/RegExps";

/** VALIDACIONES PARA LOS CAMPOS DE FORMULARIO DE VUETIFY 
 * Si se regresa true, pasa la validacion y por tanto el dato introducido en el campo de 
 * texto es correcto
*/
function required(v, text = 'Requerido') {
  return !!v || text;
}

function requiredArray(v, text = 'Requerido') {
  return !!v?.length || text;
}

function nonZero(v, text = 'El valor no puede quedar en 0') {
  const num = Number(v) || 0;
  return num != 0 || text;
}

function email(v, text = 'Formato de email inválido') {
  // !!v.match... es equivalente a escribir Boolean(v.match...)
  return !!v && !!v.match(RegExp.email) || text;
}

function onlyInteger(v, text = 'Sólo se permiten números enteros') {
  const num = v + "";
  return !!num.match(RegExp.integer) || text;
}

// Numeros en general, incluyendo decimales
function onlyRealNumber(v, text = 'Sólo se permiten números enteros y decimales') {
  const num = v + "";
  return !!num.match(RegExp.realNum) || text;
}

/** Validaciones no generales */
  function planIsSelected(planSelected, text = 'Necesita seleccionar un plan') {
    return !!planSelected.min_anios || text;
  }

function planValidYearRange(v, planSelected) {
  return v && (v >= planSelected.min_anios && v <= (planSelected.max_anios || Infinity)) || `Fuera de rango ${planSelected.min_anios} - ${planSelected.max_anios || '∞'}`;
}
/** Validaciones no generales */

const FormValidations = {
  required,
  requiredArray,
  nonZero,
  email,
  onlyInteger,
  onlyRealNumber,

  // Validaciones no generales
  planIsSelected,
  planValidYearRange,
}

export default FormValidations;