import Vue from "vue";
import VueRouter from "vue-router";
// import VueCompositionApi from "@vue/composition-api";
import Home from "@/views/Home.vue";
import Cobranza from "@/views/Cobranza.vue";
import Reporte_PlanPagos from "@/views/Reporte_PlanPagos.vue";
import { getStorageItem } from "@/utils/Functions";
import { APP_DEBUG_MODE } from "@/utils/Constants";
import { $toast } from "@/main";
import { state, STORE_NAMESPACES } from "@/store";
const { APP } = STORE_NAMESPACES;

Vue.use(VueRouter);
// Vue.use(VueCompositionApi);

// let store    = null;
// let loggedIn = null;
// let isAdmin   = null;

// function setStore(storeObj, callback = null) {
//   store = storeObj;
//   callback && callback();
// }

const adminRoutes = [
  "clientes",
  "users",
  "planes",
  "acciones_admin",
  "consulta_plan_pagos",
  "reporte_contratos",
  "reporte_corte",
  "reporte_saldos",
]

const devRoutes = [
  "reporte_bd",
]

async function authBeforeEntering(to, from, next) {
  let loggedIn = getStorageItem("auth-token") || state[APP].idToken;
  const toPathWithoutSlash = to.path.split("/")[1];

  /* Si la ruta es diferente a "/login" y no se ha iniciado sesión, redirigir a "/login" */
  if (to.path != "/login" && !loggedIn) {
    APP_DEBUG_MODE && console.log(`[Vue Router]: Se estableció la ruta "${to.path}" pero no se ha iniciado sesión todavía, redirigiendo a /login...`);
    return next("/login");
  }

  /* Si no se ha cargado el store completamente, esperar a que se cargue primero antes de continuar con el resto de código. 
  Esta función no se ejecutará en la ruta de "/login" puesto que no se carga nada del store en esa vista.
  Este chequeo se realiza principalmente para que el router funcione correctamente al recargar página (F5) */
  if (!state[APP].appFullyLoaded && to.path != "/login") {
    const loadingStore = () => {
      return new Promise(resolve => {
        let intervalTicks = 0;

        const checkIfStoreIsLoaded = setInterval(() => {
          ++intervalTicks;
          APP_DEBUG_MODE && console.log(`[Vue Router Store - setInterval tick]: `, intervalTicks);

          if (intervalTicks >= 50) {
            APP_DEBUG_MODE && console.error(`└> Error al tratar de cargar el store en "router/index.js" (es posible que setInterval esté ciclado)`);

            if (!state[APP].appFullyLoaded || state[APP].serverError) {
              $toast.clear();
              $toast.error(
                `No se pudo establecer la conexión con el servidor, compruebe su conexión a internet y recargue la página. Si la conexión a internet es correcta y aún así no se soluciona el problema, contacte a soporte.`
                , { timeout: 10000 }
              );
            }

            clearInterval(checkIfStoreIsLoaded);
            resolve();
          }

          if (state[APP].appFullyLoaded) {
            APP_DEBUG_MODE && console.log(`[Vue Router Store]: Store cargado, continuando con la función "authBeforeEntering()"`);
            
            clearInterval(checkIfStoreIsLoaded);
            resolve();
          }
        }, 300);
      })
    };

    await loadingStore();
  }

  /* Se reasigna el mismo valor a loggedIn, pues ahora "auth-token" e "idToken" tendrán valores actualizados
  después de cargar el store, esto para manejar correctamente las siguientes rutas dentro de la aplicación */
  loggedIn      = getStorageItem("auth-token") || state[APP].idToken;
  const isAdmin = state[APP].isAdmin;
  const isDevUser = state[APP].isDevUser;

  /* Si el usuario no está autenticado (o el token ha expirado) y se encuentra en una ruta que requiere autenticación, 
  redirigirlo al inicio de sesión */
  if (to.matched.some(route => route.meta.requiresAuth) && !loggedIn) {
    APP_DEBUG_MODE && console.log(`[Vue Router]: No se ha iniciado sesión, redirigiendo a la ruta "/login"...`);
    return next("/login");
  }

  /* Si el usuario ya habia iniciado sesión e intenta dirigirse a "/login" o a "/", redirigirlo a la app. 
  Sólo se podrá acceder de nuevo a la vista de inicio de sesión si se decide Salir de la app */
  if (loggedIn && (to.path == "/login" || to.path == "/")) {
    APP_DEBUG_MODE && console.log(`[Vue Router]: Se intentó ir a "/login" o "/" estando iniciada sesión, redirigiendo a la ruta "/cobranza"...`);
    if (isDevUser)
      return next("/cuenta_usuario");
    else 
      return next("/cobranza");
  }

  /* Si el usuario ya habia iniciado sesión, pero NO es admin e intenta dirigirse a las rutas de admin, no hacer nada */
  if (loggedIn && !isAdmin && adminRoutes.includes(toPathWithoutSlash)) {
    APP_DEBUG_MODE && console.log(`[Vue Router]: Ruta no autorizada para este tipo de usuario, redirigiendo a la ruta "${from.path}"...`);
    return next(from.path);
  }

  /* Si el usuario ya habia iniciado sesión, pero NO es dev e intenta dirigirse a las rutas de dev, no hacer nada */
  if (loggedIn && !isDevUser && devRoutes.includes(toPathWithoutSlash)) {
    APP_DEBUG_MODE && console.log(`[Vue Router]: Ruta no autorizada para este tipo de usuario, redirigiendo a la ruta "${from.path}"...`);
    return next(from.path);
  }

  next();
}

const routeOptions = [
  { path: "/login", name: "Login", beforeEnter: authBeforeEntering },
  
  { path: "/", component: Home, 
    children: [
      { path: "/", component: Cobranza },
      { path: "planes", name: "Planes" },
      { path: "users", name: "Users" },
      { path: "cuenta_usuario", name: "CuentaUsuario" },
      { path: "acciones_admin", name: "AccionesAdmin" },
      { path: "consulta_plan_pagos", component: Reporte_PlanPagos },
      { path: "reporte_contratos", name: "Reporte_Contratos" },
      { path: "reporte_corte", name: "Reporte_Corte" },
      { path: "reporte_saldos", name: "Reporte_Saldos" },
      { path: "reporte_bd", name: "Reporte_BD" },
      { path: "clientes", name: "Clientes" },
      { path: "clientes/:idCliente/planes", name: "PlanesClientes" },
      { path: "cobranza", component: Cobranza },
    ] },
    
  { path: "*", name: "NotFound" }
];

const withAuth = {
  meta: {
    requiresAuth: true,
  },
  beforeEnter: authBeforeEntering,
}

const importComponent = (route) => {
  return route.component
    ? route.component
    : async () => await import(/* webpackChunkName: "[request]" */ `../views/${route.name}.vue`)
}

const routes = routeOptions.map(route => {
  let childRoutes = [];

  // Si una ruta tiene sub-rutas, mapearlas
  if (route?.children?.length) {
    childRoutes = route.children.map(childRoute => {
      return {
        ...childRoute,
        ...withAuth,
        component: importComponent(childRoute)
      }
    })
  }

  // Mapear las rutas tal cual y agregar las sub-rutas si existen
  return {
    ...route,
    children: childRoutes,
    component: importComponent(route)
  }
})

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: "hash", // "history",
  base: process.env.BASE_URL,
  routes
});

export { router, /*setStore*/ };